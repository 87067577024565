import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../style/user_management/general.css";
import "../../css/login.css";
import "../../";
import FormText from "../../components/formtext";
import { Formik } from "formik";
import {
  getAllCompanySettings,
  login,
} from "../../redux/features/UserManagementSlice";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [companyAliens, setCompanyAliens] = useState(null);
  const initialValues = {
    email: "",
    password: "",
  };

  const companyAliasData = useSelector((state) => state.Users.companyAlias);
  useEffect(() => {
    // const str = window.location.host;
    // const regex = /\/\/(.+?)\.q-one\.tech/;
    // const match = str.match(regex);
    // const middleData = match && match[1];
    const urlHost = window.location.host;
    var data = { url: urlHost };
    dispatch(getAllCompanySettings(data));
  }, []);

  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid Email";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password too short";
    }

    return errors;
  };

  const submitForm = (values) => {
    // const companyIdData = companyAliasData.find(
    //   (elem) => elem.alies === companyAliens
    // );
    // var aliasData = {};
    // if (companyIdData) {
    //   aliasData = companyIdData;
    // } else {
    //   aliasData = companyAliasData[0];
    // }
    var loginData = { ...values, companyId: companyAliasData?.company };
    dispatch(login(loginData)).then((res) => {
      if (res.payload.isSuccess) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...res?.payload?.data?.user,
            password: null,
          })
        );
        localStorage.setItem("userEmail ", res?.payload?.data?.user.email);
        window.location.reload();
      } else {
        return alert(res.payload.error);
      }
    });
  };

  return (
    <div className="login__page">
      <div className="test_class">
        <FormText />
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={submitForm}
        >
          {(formik) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              isValid,
              dirty,
            } = formik;
            return (
              <div className="login__page-form">
                <h4>Log in to your account</h4>
                <form onSubmit={handleSubmit}>
                  <input
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="email"
                    id="email"
                    required={true}
                    type="text"
                    placeholder="Enter your email"
                    className={
                      errors.email && touched.email ? "input-error" : null
                    }
                  />
                  {errors.email && touched.email && (
                    <span className="errorText">{errors.email}</span>
                  )}
                  {/* <span className='fa-icon'><FontAwesomeIcon icon={faLock} /></span> */}
                  <input
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    id="password"
                    required={true}
                    type="password"
                    className={
                      errors.password && touched.password ? "input-error" : null
                    }
                    placeholder="Enter your password"
                  />
                  {errors.password && touched.password && (
                    <span className="errorText">{errors.password}</span>
                  )}
                  <button
                    type="submit"
                    // className={
                    //   !(dirty && isValid) ? "disabled-btn" : "sb-button"
                    // }
                    disabled={!(dirty && isValid)}
                    id="b-login"
                  >
                    Log in
                  </button>
                </form>
                {/* <ul>
                  <li>
                    <Link to="/forgot-password">
                      <p className="td_underline">Can't log in?</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/create-account">
                      <p className="td_underline">Sign up for an account</p>
                    </Link>
                  </li>
                </ul> */}
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
