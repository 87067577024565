import React from "react";
import styled from "styled-components";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.35);
  background: #ffffff70;
  z-index: 111;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalMain = styled.div`
  height: auto;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
`;

const ModalBody = styled.main``;

const Modal = (props) => {
  const { show, children } = props;
  return (
    <>
      {show ? (
        <ModalWrapper>
          <ModalMain>
            <ModalBody>{children}</ModalBody>
          </ModalMain>
        </ModalWrapper>
      ) : null}
    </>
  );
};

export default Modal;
