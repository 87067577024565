import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserManagementService from "../services/userManagement.service";

const initialState = {
    allUserData: [],
    invitedUsers: [],
    activeUser: {},
    userData: [],
    companyAlias: {},
    isFetching: false,
    isSuccess: false,
    error: false,
    permissions: null,
};

export const fetchAllUsers = createAsyncThunk(
    "userRole/fetchAllUsers",
    async (_thunkAPI) => {
        const response = await UserManagementService.fetchAllUsers();
        return response;
    }
);

export const getAllCompanySettings = createAsyncThunk(
    "userRole/getAllCompanySettings",
    async (data, _thunkAPI) => {
        const response = await UserManagementService.getAllCompanySettings(
            data
        );
        return response;
    }
);

export const fetchAllInvitedUsers = createAsyncThunk(
    "users/fetchAllInvitedUsers",
    async (_thunkAPI) => {
        const response = await UserManagementService.fetchAllInvitedUsers();
        return response;
    }
);

export const fetchUser = createAsyncThunk(
    "userManagement/fetchUser",
    async (id, _thunkAPI) => {
        const response = await UserManagementService.fetchUser(id);
        return response;
    }
);

export const createUser = createAsyncThunk(
    "userManagement/createUser",
    async (Data, _thunkAPI) => {
        await UserManagementService.createUser(Data);
        const response1 = await UserManagementService.fetchAllUsers();
        const response2 = await UserManagementService.fetchAllInvitedUsers();

        return { response1, response2 };
    }
);

export const editUser = createAsyncThunk(
    "userManagement/editUser",
    async (editData, _thunkAPI) => {
        const response = await UserManagementService.editUser(editData);
        if (response.isSuccess) {
            localStorage.setItem("token", response?.data?.token);
        }
        return response;
    }
);

export const updateUser = createAsyncThunk(
    "userManagement/updateUser",
    async (editData, _thunkAPI) => {
        await UserManagementService.updateUser(editData);
        const response = await UserManagementService.fetchAllUsers();
        return response;
    }
);

export const updateProfile = createAsyncThunk(
    "userManagement/updateProfile",
    async (editData, _thunkAPI) => {
        const response = await UserManagementService.updateProfile(editData);
        if (response.data) {
            localStorage.setItem(
                "user",
                JSON.stringify({ ...response?.data?.data, password: null })
            );
        }
        const res = await UserManagementService.fetchAllUsers();
        return { response, res };
    }
);

export const userRestPassword = createAsyncThunk(
    "userManagement/userRestPassword",
    async (editData, _thunkAPI) => {
        const response = await UserManagementService.userRestPassword(editData);
        return response;
    }
);

export const registerUser = createAsyncThunk(
    "registerUser/user",
    async (editData, _thunkAPI) => {
        const response = await UserManagementService.registerUser(editData);
        if (response?.isSuccess) {
            // localStorage.setItem("token", response.data.token);
        }
        return response;
    }
);

export const deleteUser = createAsyncThunk(
    "userManagement/deleteUser",
    async (id, _thunkAPI) => {
        await UserManagementService.deleteUser(id);
        const response1 = await UserManagementService.fetchAllUsers();
        const response2 = await UserManagementService.fetchAllInvitedUsers();

        return { response1, response2 };
    }
);
export const deleteInviteUser = createAsyncThunk(
    "userManagement/deleteInviteUser",
    async (id, _thunkAPI) => {
        await UserManagementService.deleteInviteUser(id);
        const response1 = await UserManagementService.fetchAllUsers();
        const response2 = await UserManagementService.fetchAllInvitedUsers();

        return { response1, response2 };
    }
);

export const login = createAsyncThunk(
    "userManagement/login",
    async (loginData, _thunkAPI) => {
        const response = await UserManagementService.login(loginData);
        if (response?.isSuccess) {
            console.log("R", JSON.stringify(response.data));
            localStorage.setItem("token", response?.data?.token);
            localStorage.setItem(
                "permissions",
                JSON.stringify(response?.data?.permissions)
            );
        }
        return response;
    }
);

export const fetchAllCompanies = createAsyncThunk(
    "CompaniesManager/fetchAllCompanies",
    async (_thunkAPI) => {
        const response = await UserManagementService.fetchAllCompanies();
        return response;
    }
);

const UserManagementSlice = createSlice({
    name: "userManagement",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
            const { data } = action.payload;
            const email = localStorage.getItem("userEmail");
            state.activeUser = data.find((elem) => elem.email === email);
            state.allUserData = data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(fetchAllUsers.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(fetchAllUsers.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(getAllCompanySettings.fulfilled, (state, action) => {
            state.companyAlias = action.payload.data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });

        builder.addCase(getAllCompanySettings.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(getAllCompanySettings.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(updateUser.fulfilled, (state, action) => {
            const { data } = action.payload;
            const email = localStorage.getItem("userEmail");
            state.activeUser = data.find((elem) => elem.email === email);
            state.allUserData = data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });

        builder.addCase(updateProfile.fulfilled, (state, action) => {
            const { data } = action.payload.res;
            state.activeUser = action.payload.response.data.data.email;
            state.allUserData = data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(updateProfile.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(updateProfile.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(fetchAllInvitedUsers.fulfilled, (state, action) => {
            const { data } = action.payload;
            state.invitedUsers = data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(fetchAllInvitedUsers.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(fetchAllInvitedUsers.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(fetchUser.fulfilled, (state, action) => {
            const { data } = action.payload;
            state.userData = data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(fetchUser.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(fetchUser.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(editUser.fulfilled, (state, action) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
            state.userData = state.userData.map((user) =>
                user._id === action.payload?._id ? action.payload : user
            );
        });
        builder.addCase(editUser.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(editUser.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(createUser.fulfilled, (state, action) => {
            const { response1, response2 } = action.payload;
            const email = localStorage.getItem("userEmail");
            state.activeUser = response1.data.find(
                (elem) => elem.email === email
            );
            state.allUserData = response1.data;
            state.invitedUsers = response2.data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(createUser.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(createUser.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            const { response1, response2 } = action.payload;
            const email = localStorage.getItem("userEmail");
            state.activeUser = response1.data.find(
                (elem) => elem.email === email
            );
            state.allUserData = response1.data;
            state.invitedUsers = response2.data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(deleteUser.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(deleteUser.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });
        builder.addCase(deleteInviteUser.fulfilled, (state, action) => {
            const { response1, response2 } = action.payload;

            const email = localStorage.getItem("userEmail");
            state.activeUser = response1.data.find(
                (elem) => elem.email === email
            );
            state.allUserData = response1.data;
            state.invitedUsers = response2.data;
            //////////////////////

            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(deleteInviteUser.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(deleteInviteUser.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(fetchAllCompanies.fulfilled, (state, action) => {
            const { data } = action.payload;
            state.companyData = data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });

        builder.addCase(fetchAllCompanies.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(fetchAllCompanies.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            // state.permissions = ["Dafuk"];
        });
    },
});

export default UserManagementSlice;
