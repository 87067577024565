import { cloneElement } from "react";

export const AddIf = (
  valueToTest,
  currentValue,
  valueToAdd,
  replace = false
) => {
  if (valueToTest !== undefined && valueToTest !== false) {
    if (replace) {
      return valueToAdd;
    } else {
      return `${currentValue} ${valueToAdd}`;
    }
  }

  return currentValue;
};

export const injectProps = (children, props, passOnlyToFunctions = false) => {
  switch (typeof children) {
    case "function":
      return children(props);
    case "object":
      if (passOnlyToFunctions === false) {
        return cloneElement(children, props);
      }
      break;
    default:
  }

  return children;
};
