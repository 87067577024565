import React from "react";
import { closeModal } from "../../assets/js/modal";
import Modal from "../../layouts/Modal";
import '../../style/layout/modal.css';

export const ModalSendPreviewSMS = () => {
    return(
        <Modal id='sp-sms' title='Send Preview SMS'>
            {
                <form className="modal_form">
                    <div className="modal_input_group">
                        <label className="modal_label">Enter Mobile Number</label>
                        <input className="modal_input" placeholder="Enter Mobile Number" type='email' />
                    </div>
                    <div className="modal_button_group">
                        <button type="button" onClick={() => closeModal('sp-sms')} className="modal_button modal_button_strip">Close</button>
                        <button className="modal_button modal_button_solid">Send</button>
                    </div>
                </form>
            }
        </Modal>
    )
}