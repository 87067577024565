import React, { useEffect } from 'react';
import { Link, useNavigate, useRoutes } from 'react-router-dom';
import '../css/dashboard.css'
import '../css/defaults-and-settings.css'

export const CompanySettingsAttribute = (props) => {

    const navigate = useNavigate();

    return(
                <div className={props.class + ' company-attribute-container'}>
                    <div className={props.kw ? props.kw + ' company-attribute-key-container': 'company-attribute-key-container'}>
                        {props.ky + ':'}
                    </div>
                    <div className={props.ws ? props.ws + ' company-attribute-value-container': 'company-attribute-value-container'}>
                        {props.value}
                    </div>
                </div>
    )
}