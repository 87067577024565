import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux';
// import { useForm } from 'react-hook-form';
// import { faBuilding, faEnvelope } from '@fortawesome/free-solid-svg-icons';
// import { faSignature } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import "../../css/login.css";
import FormText from "../../components/formtext";

const CreateAccount = () => {
  const navigate = useNavigate();
  // const firstname = useSelector(state => state.firstname);
  // const lastname = useSelector(state => state.lastname);
  // const email = useSelector(state => state.email);
  // const username = useSelector(state => state.username);
  // const password = useSelector(state => state.password);
  // const passrep = useSelector(state => state.passrep);
  // const company = useSelector(state => state.company_name)
  // const id = useSelector(state => state.id)
  // const { register, handleSubmit} = useForm({defaultValues:{firstname, company, lastname, email, username, passrep, password}})

  // const onSubmit = (data) => {

  //     const body = {
  //         firstName: data.firstname,
  //         lastName: data.lastname,
  //         email: data.email,
  //         name: data.company_name,
  //         role: 'administrator',
  //         vat: '',
  //         invoice_address: '',
  //         contact_person: '',
  //         invoice_address: '',
  //         creator_id: id || null
  //     }

  //     axios.post(`${process.env.REACT_APP_URL}/companies`, body, { headers: {
  //         'Authorization': `Bearer ${localStorage.getItem('token')}`,
  //         'Content-Type': 'application/json'
  //     }})
  //     .then(res => {
  //         if(res.data.message == 'Session expired') {
  //             localStorage.clear()
  //             alert('Your session was expired')
  //             navigate('/')
  //         } else {
  //             alert(res.data.message)
  //         }

  //     })
  // }

  return (
    <div className="login__page">
      <div className="test_class">
        <FormText />
        <div className="login__page-form">
          <h4>Create an account</h4>
          <input
            required={true}
            type="text"
            placeholder="Enter your first name"
          />
          <input
            required={true}
            type="text"
            placeholder="Enter your last name"
          />
          <input
            required={true}
            type="text"
            placeholder="Enter your email"
          />
          <input
            required={true}
            type="text"
            placeholder="Enter your company name"
          />
          <button className="sb-button" type="submit">
            Create account
          </button>
          <ul className="">
            <li>
              <Link to="/login">
                <p className="td_underline">Log in</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
