import Input from "../ui/Input";
import styled from "styled-components";
import Button from "../ui/Button";

const PageInnerWrapper = styled.div`
  text-align: left;
  padding: 20px;
`;

const PageWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 10px;
`;

const ImportModalCardButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;

  button {
    font-size: 14px;
    color: #fff;
    background: #ed8154;
    border: 1px solid #ed8154;
    border-radius: 8px;
    overflow: hidden;
    padding: 8px 20px;
    min-width: 95px;
    margin-left: 12px;
    font-weight: 600;
    cursor: pointer;
    &:first-of-type {
      margin-left: 0;
      background: #fff;
      color: #ed8154;
    }
  }
`;

const FormCard = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 0 20px;
  input {
    width: 100%;
    border: 1px solid #bdbdbd;
    min-height: 40px;
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 8px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  label {
    width: 100%;
    font-size: 14px !important;
    color: #828282 !important;
    margin: 0;
    text-align: left;
    margin-bottom: 8px;
    font-weight: 400;
    display: block;
  }
`;

const InputBox = styled.div`
  margin-bottom: 20px;
  &.largeInput {
    grid-column-start: 2;
    grid-column-end: 5;
  }
`;

const Heading = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  color: #4f4f4f;
  padding: 0 0 18px;
  font-weight: 600;
`;

const EditCommunication = ({ close }) => {
  return (
    <PageWrapper>
      <PageInnerWrapper>
        <Heading>Sendgrid</Heading>
        <FormCard>
          <InputBox>
            <label>Name</label>
            <Input />
          </InputBox>
          <InputBox className="largeInput">
            <label>Description</label>
            <Input />
          </InputBox>
          <InputBox>
            <label>SMTP Host</label>
            <Input />
          </InputBox>
          <InputBox>
            <label>SMTP Port</label>
            <Input />
          </InputBox>
          <InputBox>
            <label>SMTP User Name</label>
            <Input />
          </InputBox>
          <InputBox>
            <label>SMTP Password</label>
            <Input />
          </InputBox>
        </FormCard>
        <ImportModalCardButtons>
          <Button onClick={() => close(false)}>Cancel</Button>
          <Button>Save</Button>
        </ImportModalCardButtons>
      </PageInnerWrapper>
    </PageWrapper>
  );
};

export default EditCommunication;
