/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { ReactComponent as Close } from "../../ui/styles/Icon_Close.svg";
// import { SearchDropDown } from "../../ui/Dropdown";
import styled from "styled-components";
// import { AssignPermissionGroup } from "../../../constant/usermanagement";
// import { useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { createPermissionGroup } from "../../../redux/features/PermissionSlice";
// import { useDispatch } from "react-redux";
// import { createUser } from "../../../redux/features/UserManagementSlice";

const ExportModalCard = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
  // overflow: hidden;
  width: 750px;
`;
const ModalCardBody = styled.div`
  padding: 22px;
  margin: 0;
  list-style-type: none;
  background: #fff;
  ul {
    padding: 0;
    list-style-type: none;
    text-align: left;
    margin: 10px 0;
    li {
      font-size: 14px;
      font-weight: 400;
      color: #999;
      line-height: 20px;
      span {
        font-weight: 700;
      }
    }
  }
`;

const ModalCardHead = styled.div`
  border-bottom: 1px solid #ed8154;
  background: #ed8154;
  padding: 8px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h6 {
    font-size: 16px !important;
    color: #fff !important;
    margin: 0;
    font-weight: 600;
  }
  button {
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ExportModalCardForm = styled.div`
  width: 100%;
  input {
    width: 100%;
    border: 1px solid #ddd;
    min-height: 40px;
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 8px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  label {
    font-size: 14px !important;
    color: #666 !important;
    margin: 0;
    width: 100%;
    text-align: left;
    display: block;
    margin-bottom: 6px;
    font-weight: 700;
  }
`;

const InputBox = styled.div`
  margin-bottom: 20px;
  & > div > div {
    border-color: #ddd;
  }
`;

const ExportModalCardButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 22px;

  button {
    font-size: 14px;
    color: #fff;
    background: #ed8154;
    border: 1px solid #ed8154;
    border-radius: 8px;
    overflow: hidden;
    padding: 8px 20px;
    min-width: 95px;
    margin-left: 12px;
    font-weight: 600;
    &:first-of-type {
      margin-left: 0;
      background: #fff;
      color: #ed8154;
    }
  }
`;

const CreateNewPermissionGroupModal = (props) => {
  const { setCreateNewPermission } = props;
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    description: "",
  };

  const validate = (values) => {
    let errors = {};

    if (!values.description) {
      errors.description = "Description is required";
    } else if (values.description.length < 20) {
      errors.description = "Description too short please write some many word";
    }

    if (!values.name) {
      errors.name = "Name is required";
    }

    return errors;
  };
  const submitForm = (values) => {
    setCreateNewPermission(false);
    dispatch(createPermissionGroup(values));
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={submitForm}
      >
        {(formik) => {
          const {
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            isValid,
            dirty,
          } = formik;
          return (
            <ExportModalCard>
              <ModalCardHead>
                <h6>Create New Permission Group</h6>
                <Button>
                  <Close onClick={() => setCreateNewPermission(false)} />
                </Button>
              </ModalCardHead>
              <form onSubmit={handleSubmit}>
                <ModalCardBody>
                  <ExportModalCardForm>
                    <InputBox>
                      <label>Name</label>
                      <Input
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="name"
                        id="name"
                        required={true}
                        type="name"
                        className={
                          errors.name && touched.name ? "input-error" : null
                        }
                        placeholder="Enter your name"
                      />
                      {errors.name && touched.name && (
                        <span className="errorText">{errors.name}</span>
                      )}
                    </InputBox>
                    <InputBox>
                      <label>Description</label>
                      <Input
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="description"
                        id="description"
                        required={true}
                        type="text"
                        placeholder="Enter your description"
                        className={
                          errors.description && touched.description
                            ? "input-error"
                            : null
                        }
                      />
                      {errors.description && touched.description && (
                        <span className="errorText">{errors.description}</span>
                      )}
                    </InputBox>
                  </ExportModalCardForm>
                  <ExportModalCardButtons>
                    <Button onClick={() => setCreateNewPermission(false)}>
                      Close
                    </Button>
                    <Button
                      type="submit"
                      // className={
                      //   !(dirty && isValid) ? "disabled-btn" : "sb-button"
                      // }
                      disabled={!(dirty && isValid)}
                      id="b-login"
                    >
                      Save
                    </Button>
                  </ExportModalCardButtons>
                </ModalCardBody>
              </form>
            </ExportModalCard>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default CreateNewPermissionGroupModal;
