import React, { useEffect, useState } from 'react';
import '../css/dashboard.css'
import '../css/languages.css'
import '../css/language-item.css'
import '../css/language-modals.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showHide } from '../assets/js/showHide';
import { faHouse, faSliders } from '@fortawesome/free-solid-svg-icons'; 
// import { useSelector, useDispatch } from 'react-redux';
import { faMagnifyingGlass, faPen } from '@fortawesome/free-solid-svg-icons';
import { enterPage } from '../rootSlice';
import toggler_arrow from '../assets/Path 79.png';
import { returnArrows } from '../assets/js/returnArrows';
import { rotate } from '../assets/js/rotateArrow';
import { openCloseModal } from '../assets/js/modal';
import { useNavigate } from 'react-router-dom';
import Layout from '../layouts/Defaults-Layout';
import Select from 'react-select'
import { UploadModal } from './languages/ModalUploadTranslation';
import { ModalDefaultLanguages } from './languages/ModalDefaultLnaguages';
import styled from 'styled-components';
const Wrapper = styled.section`
  min-height: calc(100vh - 58px);
  margin-left: 250px;
  padding: 30px;
  box-sizing: border-box;
  background-color:#e4e4e4;
`;
export const DefaultsTranslations = () => {
    
    var [status, setStatus] = useState('');
    const [type, setType] = useState(localStorage.getItem('defaults_main_tab') || 'email');
    var [sort, setSort] = useState('');
    var sort_title = localStorage.getItem('defaults_sort_title') || 'Date Ascending';
    // var lang_from = useSelector(state => state.lang_from)
    // var lang_to = useSelector(state => state.lang_to)
    var navigate = useNavigate();
    var defaults = [
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
        {id: 'ids', name: 'Naziv Artikla', description: 'Opis artikla', status: 'live'},
    ]
    // const dispatch = useDispatch();
    // dispatch(enterPage('languages'));

    const options = [
        { value: 'en', label: 'English' },
        { value: 'de', label: 'German' },
        { value: 'fr', label: 'Franch' }
      ]
    
    // useEffect(() => {
    //     if(localStorage.getItem('token') === null || !localStorage.getItem('token')) {
    //         navigate('/')
    //     }
    // }, [status, sort, lang_from, lang_to])

    function toggleTab(tab) {
        setType(tab)
        localStorage.setItem('defaults_main_tab', tab)
    }

    function goToEdit(link) {
        navigate('/' + link)
    }

    function chooseStatus(value) {
        document.getElementById('filter-first').style.display = 'none';
        rotate('tsa')
        setStatus(value)
        localStorage.setItem('def_status', value)
        if(value == 'Show all statuses') {
            setStatus('');
        } else {
            setStatus(value);
        }
        
    }

    function chooseSort(value, title) {
        document.getElementById('filter-third').style.display = 'none';
        localStorage.setItem('defaults_sort', value)
        localStorage.setItem('defaults_sort_title', title)
        rotate('ssa')
        setSort(value)
    }

    function toggleSelect(id, aid) {
        if(document.getElementById(id).style.display == 'flex') {
            document.getElementById(id).style.display = 'none'
            returnArrows();
        } else {
            var elements = document.getElementsByClassName('options-container');
            for(var i = 0; i < elements.length; i++) {
                elements[i].style.display = 'none'
            }
            returnArrows();
            rotate(aid)
            document.getElementById(id).style.display = 'flex'
        }
    }

    return(
        <Wrapper>
            <div className='navbar_plus_filters'>
                <div className='dashboard_navbar'>
                    <div className='dashboard_navigation'>
                        <div className='navigation-arrow-outside'>
                            <div className='navigation-arrow navigation-arrow-start navigation-arrow-parents'>
                                <FontAwesomeIcon className='navigation-arrow-icon' icon={faHouse} />
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/settings-and-defaults')} className='navigation-icon-after'>
                                Settings
                            </div>
                        </div>
                        <div className='navigation-icon-after third-arrow'>
                            <p className='nav-text'>Languages</p>
                        </div>
                    </div>
                    <div className='on-top-buttons-container'>
                        <Select options={options} styles={{
                            control: (baseStyles) => ({
                            ...baseStyles,
                            width: '280px',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            color:'#4f4f4f',
                            fontWeight: '',
                            border: '0 !important',
                            zIndex: '5000',
                            borderColor: 'unset !important',
                            cursor: 'pointer'
                            })
                        }}
                        isSearchable={false}
                        placeholder={options[0].label}
                        />
                        <button  onClick={() => openCloseModal('dl-modal')} className='on-top-button'>Default languages</button>
                        <button onClick={() => openCloseModal('upload-language-modal')} className='on-top-button'>Upload translations</button>
                        <button onClick={() => showHide('ft-cnt')} className='settings-button'><FontAwesomeIcon className='settings-icon' icon={faSliders} /></button>
                    </div>
                </div>
                <div id='ft-cnt' style={{visibility: 'visible'}} className='filter-container'>
                    <div className='filter-select-container'>
                        <div className='css-select-container'>
                            <div onClick={() => toggleSelect('filter-first', 'tsa')} className='filter_select'>{status === '' ? 'Show All Statuses' : status}
                                <img className='transition' style={{transform: 'rotate(0)'}} id='tsa' alt='' src={toggler_arrow} />
                            </div>
                            <div id='filter-first' style={{display: 'none'}} className='options-container'>
                                <div onClick={() => chooseStatus('')} className='option option-top'>Show all statuses</div>
                                <div onClick={() => chooseStatus('Live')} className='option'>Live</div>
                                <div onClick={() => chooseStatus('Disabled')} className='option option-bottom'>Disabled</div>
                            </div>
                        </div>
                        <div className='css-select-container'>
                            <div onClick={() => toggleSelect('filter-third', 'ssa')} className='filter_select'>{sort_title}
                                <img className='transition' id='ssa' style={{transform: 'rotate(0)'}} alt='' src={toggler_arrow} />
                            </div>
                            <div id='filter-third' style={{display: 'none'}} className='options-container'>
                                <div onClick={() => chooseSort('date_asc', 'Date Ascending')} className='option option-top'>Date ascending</div>
                                <div onClick={() => chooseSort('date_desc', 'Date Descending')} className='option'>Date descending</div>
                                <div onClick={() => chooseSort('name_asc', 'Name Ascending')} className='option'>Name ascending</div>
                                <div onClick={() => chooseSort('name_desc', 'Name Decending')} className='option'>Name descending</div>
                            </div>
                        </div>
                    </div>
                    <div className='search-container filter-search-bar'>
                        <input className='search_bar filter-search search-w-custom' placeholder='Enter search term' />
                        <div className='search-button'>
                            <FontAwesomeIcon className='search-button-icon' icon={faMagnifyingGlass} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="dt_container">
                <div className="dt_tab_bar">
                    <div onClick={() => toggleTab('email')} className={type === 'email' ? 'dt_tab dt_tab_active' : 'dt_tab'}>Email</div>
                    {/* <div onClick={() => toggleTab('sms')} className={dt_tab === 'sms' ? 'dt_tab dt_tab_active' : 'dt_tab'}>SMS</div> */}
                    <div onClick={() => toggleTab('page')} className={type === 'page' ? 'dt_tab dt_tab_active' : 'dt_tab'}>Pages</div>
                    {/* <div onClick={() => toggleTab('buttons')} className={dt_tab === 'buttons' ? 'dt_tab dt_tab_active' : 'dt_tab'}>Buttons</div> */}
                </div>
                <div className="dt_head">
                    <div className="dt_head_title dt-col-1"> Name </div>
                    <div className="dt_head_title dt-col-2"> Description </div>
                    {/* <div className="dt_head_title dt-col-3"> Module </div> */}
                    <div className="dt_head_title dt-col-4"> Status </div>
                    <div className="dt_head_title dt-col-5"> Actions </div>
                </div>
                <div className="dt_body">
                    {
                        defaults.map((item) => (
                            <div key={item._id} className="dt_item">
                                <div className="dt-col-1 bold">
                                    <div className="dt_item_text dt_item_text_name">
                                        {item.name}
                                    </div>
                                </div>
                                <div className="dt-col-2">
                                    <div className="dt_item_text dt_item_text_desc">
                                        {item.description}
                                    </div>
                                </div>
                                {/* <div className="dt-col-3">{item.module}</div> */}
                                <div className={item.status === 'live' ? "dt-col-4 bold live": "dt-col-4 bold draft"}>{item.status == 'live' ? 'Live' : 'Draft'}</div>
                                <div className="dt-col-5 dt_actions_container">
                                    <div className="dt_action dt_action_regular" onClick={
                                        type === 'email' ? () => goToEdit(`translations-editor/${'email-' + item.id}`) :
                                        // type === 'sms' ? () => goToEdit('', 'SMS', 'edit-content-sms') :
                                        type === 'page' ? () => goToEdit(`translations-editor/${'page-' + item.id}`) :
                                        () => console.log('No such route')
                                    }>
                                        <FontAwesomeIcon icon={faPen} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <UploadModal />
            <ModalDefaultLanguages />
        </Wrapper>
    )
}