import React from "react";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { ReactComponent as Close } from "../../ui/styles/Icon_Close.svg";
import styled from "styled-components";
import { useState } from "react";
import {
  createUserRole,
  editUserRole,
  fetchAllUserRole,
} from "../../../redux/features/UserRoleSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const ExportModalCard = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
  // overflow: hidden;
  width: 750px;
`;
const ModalCardBody = styled.div`
  padding: 22px;
  margin: 0;
  list-style-type: none;
  background: #fff;
  ul {
    padding: 0;
    list-style-type: none;
    text-align: left;
    margin: 10px 0;
    li {
      font-size: 14px;
      font-weight: 400;
      color: #999;
      line-height: 20px;
      span {
        font-weight: 700;
      }
    }
  }
`;

const ModalCardHead = styled.div`
  border-bottom: 1px solid #ed8154;
  background: #ed8154;
  padding: 8px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h6 {
    font-size: 16px !important;
    color: #fff !important;
    margin: 0;
    font-weight: 600;
  }
  button {
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ExportModalCardForm = styled.div`
  width: 100%;
  input {
    width: 100%;
    border: 1px solid #ddd;
    min-height: 40px;
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 8px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  label {
    font-size: 14px !important;
    color: #666 !important;
    margin: 0;
    width: 100%;
    text-align: left;
    display: block;
    margin-bottom: 6px;
    font-weight: 700;
  }
`;

const InputBox = styled.div`
  margin-bottom: 20px;
  & > div > div {
    border-color: #ddd;
  }
`;

const ExportModalCardButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 22px;

  button {
    font-size: 14px;
    color: #fff;
    background: #ed8154;
    border: 1px solid #ed8154;
    border-radius: 8px;
    overflow: hidden;
    padding: 8px 20px;
    min-width: 95px;
    margin-left: 12px;
    font-weight: 600;
    &:first-of-type {
      margin-left: 0;
      background: #fff;
      color: #ed8154;
    }
  }
`;

const AddNewRoles = (props) => {
  const { setRoleDialog, isEditId, userRoleData, setIsEditId } = props;
  const [userRole, setUserRole] = useState({ description: "", role: "" });
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditId) {
      var newData = userRoleData.find((elem) => elem._id === isEditId);
      setUserRole({ role: newData.role, description: newData.description });
    }
  }, []);
  const handleOnChange = (e) => {
    setUserRole({
      ...userRole,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditId) {
      var editableData = {};
      userRoleData.map((elem) => {
        if (elem._id === isEditId) {
          editableData = {
            ...elem,
            description: userRole.description,
            role: userRole.role,
          };
        }
        return null;
      });
      dispatch(editUserRole(editableData));
    } else {
      var addData = {
        description: userRole.description,
        role: userRole.role,
      };
      dispatch(createUserRole(addData));
    }
    setIsEditId(null);
    setUserRole({ role: "", description: "" });
    setRoleDialog(false);
    dispatch(fetchAllUserRole());
  };
  return (
    <React.Fragment>
      <ExportModalCard>
        <ModalCardHead>
          <h6>Create New</h6>
          <Button>
            <Close onClick={() => setRoleDialog(false)} />
          </Button>
        </ModalCardHead>
        <form onSubmit={handleSubmit}>
          <ModalCardBody>
            <ExportModalCardForm>
              <InputBox>
                <label>Name</label>
                <Input
                  type="text"
                  name="role"
                  value={userRole.role}
                  onChange={handleOnChange}
                />
              </InputBox>
              <InputBox>
                <label>Description</label>
                <Input
                  type="text"
                  name="description"
                  value={userRole.description}
                  onChange={handleOnChange}
                />
              </InputBox>
            </ExportModalCardForm>
            <ExportModalCardButtons>
              <Button onClick={() => setRoleDialog(false)}>Close</Button>
              <Button type="submit">
                {isEditId == "" ? "Create" : "Update"}
              </Button>
            </ExportModalCardButtons>
          </ModalCardBody>
        </form>
      </ExportModalCard>
    </React.Fragment>
  );
};

export default AddNewRoles;
