import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faDesktop, faMobileScreenButton, faTabletScreenButton, faEye } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../layouts/Defaults-Layout";
import Editor from "../../layouts/Editor";
import { enterPreviewId } from "../../rootSlice";
import editicon from '../../assets/images/editicon.png'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Editor as TAE } from "@tinymce/tinymce-react";
import { ModalSendPreviewEmail } from "./ModalSendPreviewEmail";
import { useState } from "react";
import { success } from "../../auxilliary";
import { unlockInput } from "../../assets/js/unlockInput";
import { useEffect } from "react";
import { openModal } from "../../assets/js/modal";
import { smooth } from "../../assets/js/smooth";

export const ContentEmailEditor = () => {
    
    useEffect(() => {
        if(localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            navigate('/')
        }
    }, []);

    var item = useSelector(state => state.item);
    var bc_title = useSelector(state => state.bc_title)
    var [status, setStatus] = useState('');
    var [html, setHtml] = useState(item.html);
    var defHtml = item.html;
    var [device, setDevice] = useState('Desktop');
    var [tab, setTab] = useState('Design');
    var url = window.location.href;
    var urlArray = url.split('/');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleSubmit, register } = useForm({
        defaultValues:{
            name: item.name,
            desc: item.desc,
            status: status,
            html: html
        }
    });

    function setStatusLive() {
        setStatus('live');
    }

    function setStatusDraft() {
        setStatus('draft');
    }

    const updateDefault = (data) => {

        setHtml(defHtml)

        const submitData = {
            name: data.name,
            description: data.desc,
            status: status,
            html: html
        }

        axios.put(`${process.env.REACT_APP_URL}/defaults/default/${urlArray.slice(-1)}`, submitData, {headers: {
            'Content-type': 'application/json'
        }})
        .then((res) => {
            success(res.data.message);
        })

        navigate('/default-content')
    }

    const oec = (content) => {
        setHtml(content)
    }

    function openPreviewModal(modal_id, item_id) {
        openModal(modal_id)
        dispatch(enterPreviewId(item_id));
    }

    function adjustResolution(device) {
        setDevice(device)
        smooth();
    }

    return(
        <Layout>
            <div className='navbar_plus_filters'>
                <div className='dashboard_navbar'>
                    <div className='dashboard_navigation'>
                        <div className='navigation-arrow-outside'>
                            <div className='navigation-arrow navigation-arrow-start navigation-arrow-parents'>
                                <FontAwesomeIcon className='navigation-arrow-icon' icon={faHouse} />
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/settings-and-defaults')} className='navigation-icon-after'>
                                Settings
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/default-content')} className='navigation-icon-after'>
                                Templates
                            </div>
                        </div>
                        <div className='navigation-icon-after third-arrow'>
                            <p className='nav-text'>{bc_title}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={tab === 'Design' && device === 'Mobile' ? 'de_preview_container depc_mobile' : tab === 'Design' && device === 'Tablet' ? 'de_preview_container depc_tablet' : tab === 'Design' && device === 'Desktop' ? 'de_preview_container depc_desktop' : 'de_preview_container'}>
                <div className={tab === 'Design' && device === 'Mobile' ? 'dt_tab_bar depc_mobile' : tab === 'Design' && device === 'Tablet' ? 'dt_tab_bar depc_tablet' : tab === 'Design' && device === 'Desktop' ? 'dt_tab_bar depc_desktop' : 'dt_tab_bar'}>
                    <div onClick={() => setTab('Design')} className={tab === 'Design' ? 'dt_tab dt_tab_active' : 'dt_tab'}>Design</div>
                    {/* <div className='dt_tab'>JSON</div> */}
                    <div onClick={() => setTab('Html')} className={tab === 'Html' ? 'dt_tab dt_tab_active' : 'dt_tab'}>HTML</div>
                    {/* <div className='dt_tab'>Plain Text</div> */}
                </div>
                {
                    tab === 'Design' ? 
                    <div className={tab === 'Design' && device === 'Mobile' ? 'html_container depc_mobile' : tab === 'Design' && device === 'Tablet' ? 'html_container depc_tablet' : tab === 'Design' && device === 'Desktop' ? 'html_container depc_desktop' : 'html_container'} dangerouslySetInnerHTML={{__html: html}}>

                    </div> : tab === 'Html' ?
                                        <form style={{textAlign: 'center'}}>
                                        <TAE apiKey="1271nwtz2rc95x7x8ufeo4sjcu7t47t656e8l2dmrmdcow56" initialValue={defHtml} onEditorChange={oec}
                                            init={{
                                                selector: 'textarea',
                                                plugins: ['code', 'save'],
                                                toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                                                directionality: 'ltr',
                                                valid_children : '+body[style]'
                                            }}
                                        />
                                        </form> : <></>
                }
            </div>
            <Editor>
                <form onSubmit={handleSubmit(updateDefault)} className="de_form">
                    <div className="de_design_tab">
                        <div className="de_form_group">
                            <label className="de_label">Name</label>
                            <div className="de_input_container">
                                <input {...register('name')} name='name' id="de_email_input_name" className="de_input de_input_name" defaultValue={item.name} />
                                <img onClick={() => unlockInput('de_email_input_name')} className="e_icon" src={editicon} />
                            </div>
                        </div>
                        <div className="de_form_group">
                            <label className="de_label">Description</label>
                            <div className="de_input_container">
                                <textarea {...register('desc')} name='desc' spellCheck="false" id="de_email_input_desc" className="de_input de_input_desc" defaultValue={item.description} />
                                <img onClick={() => unlockInput('de_email_input_desc')} className="e_icon" src={editicon} />
                            </div>
                        </div> 
                    </div>
                    <div className='de_bd_container'>
                        <div className="de_devices_container dep_mt">
                            <div className={"defaults_device de_device"}
                                onClick={() => openPreviewModal('sp-email', item._id)}>
                                <FontAwesomeIcon icon={faEye} />
                            </div>
                            <div className={device === 'Desktop' ? "defaults_device de_device de_device_active" : "defaults_device de_device"}
                                onClick={() => adjustResolution('Desktop')}>
                                <FontAwesomeIcon icon={faDesktop} />
                            </div>
                            <div className={device === 'Mobile' ? "defaults_device de_device de_device_active" : "defaults_device de_device"}
                                onClick={() => adjustResolution('Mobile')}
                            >
                                <FontAwesomeIcon icon={faMobileScreenButton} />
                            </div>
                            <div className={device === 'Tablet' ? "defaults_device de_device de_device_active" : "defaults_device de_device"}
                                onClick={() => adjustResolution('Tablet')}>
                                <FontAwesomeIcon icon={faTabletScreenButton} />
                            </div>
                        </div>
                        <div className="de_form_button_container">
                            <button onClick={() => setStatusDraft()} type="submit" className="de_form_button de_form_button_left">Save as Draft</button>
                            <button onClick={() => setStatusLive()} type="submit" className="de_form_button de_form_button_right">Publish</button>
                        </div>
                    </div>
                </form>
            </Editor>
            <ModalSendPreviewEmail />
        </Layout>
    )
}