import React from "react";
import logo from "../../assets/panel_logo.svg";
import "../../css/login.css";

const FormText = () => {
  return (
    <div className="login__page-text">
      <img src={logo} alt="error" />
      <h4>Welcome to the Q One System</h4>
      <p>
        Our system is designed to make the process of collecting and managing
        data as simple and efficient as possible. The Q One System provides
        real-time reporting and analytics, giving you valuable insights into
        your data as it is collected.
      </p>
    </div>
  );
};

export default FormText;
