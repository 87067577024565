import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faEye, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../layouts/Defaults-Layout";
import Editor from "../../layouts/Editor";
import editicon from '../../assets/images/editicon.png'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Editor as TAE } from "@tinymce/tinymce-react";
import { useState } from "react";
import { success } from "../../auxilliary";
import { unlockInput } from "../../assets/js/unlockInput";
import { useEffect } from "react";
import { slugify } from "../../assets/js/slugify";
import { ModalAddTrackingCode } from "./ModalAddTrackingCode";
import { openModal } from "../../assets/js/modal";
import { enterPageId, enterTrackingCodeId, enterItem } from "../../rootSlice";
import { ModalDeleteTrackingCode } from "./ModalDeleteTrackingCode";

export const ContentPageEditor = () => {

    useEffect(() => {
        if(localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            navigate('/')
        }
    }, [])

    var item = useSelector(state => state.item);
    var page_id = useSelector(state => state.page_id)
    var bc_title = useSelector(state => state.bc_title)
    var [html, setHtml] = useState(item.html);
    var [status, setStatus] = useState('');
    var [tab, setTab] = useState('Design');
    var [e_tab, setETab] = useState('Design');
    var defHtml = item.html
    const navigate = useNavigate();
    var url = window.location.href;
    var urlArray = url.split('/');
    const dispatch = useDispatch();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL}/defaults/default/${item._id}`)
        .then((res) => {
            if(res.data.data) {
                dispatch(enterItem({...item, ...res.data.data}))
                reset({...item, ...res.data.data})
            } else {
                alert('An error occured while fetching');
            }
        })
    }, [page_id])

    const { register, handleSubmit, reset } = useForm({
        defaultValues: {
            name: item.name,
            desc: item.description,
            slug: item.slug,
            html: html
        }
    })

    function setStatusLive() {
        setStatus('live');
    }

    function setStatusDraft() {
        setStatus('draft');
    }

    const updateDefault = (data) => {

        var slugged = slugify(data.slug);

        if(slugged) {
            const submitData = {
                name: data.name,
                description: data.desc,
                slug: slugged,
                status: status,
                html: html
            }

            axios.put(`${process.env.REACT_APP_URL}/defaults/default/${urlArray.slice(-1)}`, submitData, {headers: {
                'Content-type': 'application/json'
            }})
                .then((res) => {
                    success(res.data.message);
                }
            )
            navigate('/default-content')
        }
    }

    const oec = (content) => {
        setHtml(content)
    }

    function openAddTrackingCodeModal(modal_id, code_id) {
        openModal(modal_id);
        dispatch(enterTrackingCodeId(code_id));
        dispatch(enterPageId(urlArray.slice(-1)))
    }

    function openDeleteCodeModal(code_id) {
        openModal('tc-del-modal');
        dispatch(enterTrackingCodeId(code_id));
        dispatch(enterPageId(urlArray.slice(-1)))
    }

    return(
        <Layout>
            <div className='navbar_plus_filters'>
                <div className='dashboard_navbar'>
                    <div className='dashboard_navigation'>
                        <div className='navigation-arrow-outside'>
                            <div className='navigation-arrow navigation-arrow-start navigation-arrow-parents'>
                                <FontAwesomeIcon className='navigation-arrow-icon' icon={faHouse} />
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/settings-and-defaults')} className='navigation-icon-after'>
                                Settings
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/default-content')} className='navigation-icon-after'>
                                Templates
                            </div>
                        </div>
                        <div className='navigation-icon-after third-arrow'>
                            <p className='nav-text'>{bc_title}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="de_preview_container">
                <div className="dt_tab_bar">
                    <div onClick={() => setTab('Design')} className={tab === 'Design' ? 'dt_tab dt_tab_active' : 'dt_tab'}>Design</div>
                    {/* <div className='dt_tab'>JSON</div> */}
                    <div onClick={() => setTab('Html')} className={tab === 'Html' ? 'dt_tab dt_tab_active' : 'dt_tab'}>HTML</div>
                    {/* <div className='dt_tab'>Plain Text</div> */}
                </div>
                {
                    tab === 'Design' ?

                    <div className="html_container" dangerouslySetInnerHTML={{__html: html}}>

                    </div> : tab === 'Html' ?

                    <form style={{textAlign: 'center'}}>
                        <TAE apiKey="1271nwtz2rc95x7x8ufeo4sjcu7t47t656e8l2dmrmdcow56" name='html' {...register('html')} initialValue={defHtml} onEditorChange={oec}
                            init={{
                                selector: 'textarea',
                                plugins: ['code', 'save'],
                                toolbar: ['code | undo redo | formats fontsize | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image ' ],
                                directionality : 'ltr',
                                valid_children : '+body[style]'
                            }}
                        />
                    </form> : <></>
                }
            </div>
            <Editor>
                <div className="de_tab_bar">
                    <div className={e_tab === 'Design' ? 'de_tab de_tab_active': 'de_tab'}
                            onClick={() => setETab('Design')}>
                        Design
                    </div>
                    <div className={e_tab === 'Settings' ? 'de_tab de_tab_active': 'de_tab'}
                            onClick={() => setETab('Settings')}>
                        Settings
                    </div>
                </div>
                <form onSubmit={handleSubmit(updateDefault)} className="de_form">
                { e_tab === 'Design' ?
                <div className="de_design_tab">
                    
                        <div className="de_form_group">
                            <label className="de_label">Name</label>
                            <div className="de_input_container">
                                <input id="de_page_input_name" {...register('name')} name='name'  className="de_input de_input_name" />
                                <img onClick={() => unlockInput('de_page_input_name')} className="e_icon" src={editicon} />
                            </div>
                        </div>
                        <div className="de_form_group">
                            <label className="de_label">Description</label>
                            <div className="de_input_container">
                                <textarea {...register('desc')} name='desc' spellCheck="false" id="de_page_input_desc" className="de_input de_input_desc" />
                                <img onClick={() => unlockInput('de_page_input_desc')} className="e_icon" src={editicon} />
                            </div>
                        </div>
                        <div className="de_form_group">
                            <label className="de_label">URL Slug</label>
                            <div className="de_input_container">
                                <input {...register('slug')} name='slug' spellCheck="false" id="de_page_input_url" className="de_input de_input_url" />
                                <img onClick={() => unlockInput('de_page_input_url')} className="e_icon" src={editicon} />
                            </div>
                        </div>
                    
                </div> :
                <div className="de_settings_tab">
                    <div className="tc_container">
                        <div className="tc_title">
                            Add Tracking Codes
                        </div>
                        { item.tracking_codes !== null ? item.tracking_codes.map((code) => (
                            <div className="tc">
                                <div style={{width: 111, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{code.name}</div>
                                <FontAwesomeIcon onClick={() => openAddTrackingCodeModal('add-tc-modal', code._id)} icon={faPen} className='tc_edit' />
                                <FontAwesomeIcon onClick={() => openDeleteCodeModal(code._id)} icon={faTrash} className='tc_danger' />
                            </div>
                        )) : <div className="tc">This item have not get tracking codes yet.</div>

                        }
                        <div className="tc_add_link" onClick={() => openAddTrackingCodeModal('add-tc-modal', null)}>
                            +Add new
                        </div>
                    </div>
                </div>
                }
                        <div className='de_bd_container'>
                        <div className="de_devices_container dep_mt">
                            <Link className="mh_inj" to={`/preview-web-page/${item.slug}`} target="_blank" rel="noopener noreferrer">
                                <div className={"defaults_device de_device de_device_active"}>
                                    <FontAwesomeIcon icon={faEye} />
                                </div>
                            </Link>
                        </div>
                        <div className="de_form_button_container">
                            <button type="submit" onClick={() => setStatusDraft()} className="de_form_button de_form_button_left">Save as Draft</button>
                            <button type="submit" onClick={() => setStatusLive()} className="de_form_button de_form_button_right">Publish</button>
                        </div>
                    </div>
                </form>
            </Editor>
            <ModalAddTrackingCode />
            <ModalDeleteTrackingCode />
        </Layout>
    )
}