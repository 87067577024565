import { faAudioDescription } from '@fortawesome/free-solid-svg-icons';
import { createSlice } from '@reduxjs/toolkit';

const rootSlice = createSlice({
    name: 'root',
    initialState: {
        username: "",
        firstname: "",
        lastname: "",
        company: "",
        companies: [],
        users: [],
        selectedUsers: [],
        email: "",
        password: "",
        passrep: "",
        company_name: "",
        newpass: "",
        newpassrep: "",
        role: "",
        selected: '',
        curCompany: '',
        me: {},
        br: 0,
        defaults: [],
        item: {},
        action: '',
        id: '',
        filter_s1: "Show all statuses",
        filter_s2: "Show all modules",
        filter_s3: "Date ascending",
        f_status: '',
        f_type: '',
        lang_from: "English",
        lang_to: "English",
        page: '',
        sidebar: 1,
        dt_tab: 'emails',
        toBeEdited: '',

        /* Editor */
        /* e ~ editor */

        e_active_tab: 'Design',
        e_active_inner_tab: 'Design',
        e_active_device: 'Desktop',
        new_name: '',
        new_desc: '',
        new_type: 'Select type',
        preview_id: '',
        bc_title: '',
        delete_id: '',
        copy_id: '',
        c_item: '',
        tc_id: null,
        page_id: '',


            /* Emails */
            e_email_linked_module: 'Select Linked Module',
            e_email_link_module_option: 'Linked Module Option',
            e_email_not_rate: '',
            e_email_not_measure: 'Hours',
            e_email_name: '',
            e_email_description: '',
            e_email_status: '',
            e_email_html: '',

            /* Pages */
            e_pages_linked_module: 'Select Linked Module',
            e_pages_link_module_option: 'Linked Module Option',
            e_pages_not_rate: '',
            e_pages_not_measure: 'Hours',
            e_pages_name: '',
            e_pages_description: '',
            e_pages_slug: '',
            e_pages_status: '',
            e_pages_html: '',

            /* Buttons */
            e_button_linked_module: 'Select Linked Module',
            e_button_link_module_option: 'Linked Module Option',
            btn_status: 'Update Status To',
            btn_action: 'Select On Click Action',
            btn_routing: 'Select Routing',
            btn_window: 'Open In Window',
            btn_send_email: true,
            btn_send_sms: false,
            btn_email_template: 'Select Email Template',
            btn_sms_template: 'Select SMS Template',

            /* SMS */
            e_sms_length: 0,
            e_sms_text: 'scelerisque viverra mauris in aliquam sem fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque massa placerat duis ultricies lacus sed turpis tincidunt id aliquet risus feugiat in ante metus dictum at tempor commodo ullamcorper a lacus vestibulum sed arcu non odio euismod lacinia at quis risus sed vulputate',

    },

    reducers: {
        enterId: (state, action) => {state.id = action.payload},
        enterUsername: (state, action) => {state.username = action.payload},
        enterEmail: (state, action) => {state.email = action.payload},
        enterPassword: (state, action) => {state.password = action.payload},
        enterNewPass: (state, action) => {state.newpass = action.payload},
        enterNewPassRep: (state, action) => {state.newpassrep = action.payload},
        enterFirstname: (state, action) => {state.firstname = action.payload},
        enterSelectedUsers: (state, action) => {state.selectedUsers = action.payload},
        enterUsers: (state, action) => {state.users = action.payload},
        enterCompanies: (state, action) => {state.companies= action.payload},
        enterLastname: (state, action) => {state.lastname = action.payload},
        enterCompany: (state, action) => {state.company = action.payload},
        enterRole: (state, action) => {state.role = action.payload},
        enterMe: (state, action) => {state.me = action.payload},
        enterSelected: (state, action) => {state.selected = action.payload},
        enterBr: (state, action) => {state.br = action.payload},
        enterAction: (state, action) => {state.action = action.payload},
        enterDefaults: (state, action) => {state.defaults = action.payload},
        enterItem: (state, action) => {state.item = action.payload},
        enterfs1: (state, action) => {state.filter_s1 = action.payload}, 
        enterfs2: (state, action) => {state.filter_s2 = action.payload}, 
        enterfs3: (state, action) => {state.filter_s3 = action.payload},
        enterfstatus: (state, action) => {state.f_status = action.payload},
        enterftype: (state, action) => {state.f_type = action.payload},
        enterLangFrom: (state, action) => {state.lang_from = action.payload},
        enterLangTo: (state, action) => {state.lang_to = action.payload},
        enterCurCompany: (state, action) => {state.curCompany = action.payload},
        enterPage: (state, action) => {state.page = action.payload},
        enterSidebar: (state, action) => {state.sidebar = action.payload},
        enterDtTab: (state, action) => {state.dt_tab = action.payload},
        enterToBeEdited: (state, action) => {state.toBeEdited = action.payload},
        enterNName: (state, action) => {state.new_name = action.payload},
        enterNDesc: (state, action) => {state.new_desc = action.payload},
        enterNType: (state, action) => {state.new_type = action.payload},


        /* Editor */

        enterEActiveTab: (state, action) => {state.e_active_tab = action.payload},
        enterEActiveInnerTab: (state, action) => {state.e_active_inner_tab = action.payload},
        enterEActiveDevice: (state, action) => {state.e_active_device = action.payload},
        enterPreviewId: (state, action) => {state.preview_id = action.payload},
        enterBcTitle: (state, action) => {state.bc_title = action.payload},
        enterDeleteId: (state, action) => {state.delete_id = action.payload},
        enterCopyId: (state, action) => {state.copy_id = action.payload},
        enterCItem: (state, action) => {state.c_item = action.payload},
        enterTrackingCodeId: (state, action) => {state.tc_id = action.payload},
        enterPageId: (state, action) => {state.page_id = action.payload},

            /* Emails */
            enterEEmailLinkedModule: (state, action) => {state.e_email_linked_module = action.payload},
            enterEEmailLinkedModuleOption: (state, action) => {state.e_email_link_module_option = action.payload},
            enterEEmailNotRate: (state, action) => {state.e_email_not_rate = action.payload},
            enterEEmailNotMeasure: (state, action) => {state.e_email_not_measure = action.payload},
            enterEEmailName: (state, action) => {state.e_email_name = action.payload},
            enterEEmailDescription: (state, action) => {state.e_email_description = action.payload},
            enterEEmailStatus: (state, action) => {state.e_email_status = action.payload},
            enterEEmailHtml: (state, action) => {state.e_email_html = action.payload},

            /* Pages */
            enterEPagesLinkedModule: (state, action) => {state.e_pages_linked_module = action.payload},
            enterEPagesLinkedModuleOption: (state, action) => {state.e_pages_link_module_option = action.payload},
            enterEPagesNotRate: (state, action) => {state.e_pages_not_rate = action.payload},
            enterEPagesNotMeasure: (state, action) => {state.e_pages_not_measure = action.payload},
            enterEPagesName: (state, action) => {state.e_pages_name = action.payload},
            enterEPagesDescription: (state, action) => {state.e_pages_description = action.payload},
            enterEPagesSlug: (state, action) => {state.e_pages_slug = action.payload},
            enterEPagesStatus: (state, action) => {state.e_pages_status = action.payload},
            enterEPagesHtml: (state, action) => {state.e_pages_html = action.payload},

            /* SMS */
            enterESmsLength: (state, action) => {state.e_sms_length = action.payload},
            enterESmsText: (state, action) => {state.e_sms_text = action.payload},

            /* Buttons */
            enterEButtonLinkedModule: (state, action) => {state.e_button_linked_module = action.payload},
            enterEButtonLinkedModuleOption: (state, action) => {state.e_button_link_module_option = action.payload},
            enterBtnStatus: (state, action) => {state.btn_status = action.payload},
            enterBtnAction: (state, action) => {state.btn_action = action.payload},
            enterBtnWindow: (state, action) => {state.btn_window = action.payload},
            enterBtnRouting: (state, action) => {state.btn_routing = action.payload},
            enterBtnSendEmail: (state, action) => {state.btn_send_email = action.payload},
            enterBtnSendSms: (state, action) => {state.btn_send_sms = action.payload},
            enterBtnEmailTemplate: (state, action) => {state.btn_email_template = action.payload},
            enterBtnSmsTemplate: (state, action) => {state.btn_sms_template = action.payload},

    }
});

export const reducer = rootSlice.reducer;

export const {enterUsername, enterSelected, enterEmail, enterPassword, enterNewPass, enterNewPassRep,
                enterRole, enterMe, enterCompany, enterCompanies, enterUsers, enterSelectedUsers,
                    enterBr, enterAction, enterId, enterfs1, enterfs2, enterfs3, enterLangFrom,
                    enterLangTo, enterCurCompany, enterPage, enterSidebar, enterDtTab,
                    enterToBeEdited, enterEActiveTab, enterEActiveDevice, enterEEmailLinkedModule,
                    enterEEmailLinkedModuleOption, enterEEmailNotMeasure, enterEEmailNotRate,
                enterEPagesLinkedModule, enterEPagesLinkedModuleOption, enterEPagesNotMeasure, enterEPagesNotRate,
                 enterESmsLength, enterESmsText, enterEButtonLinkedModule, enterEButtonLinkedModuleOption,
                enterBtnAction, enterBtnEmailTemplate, enterBtnRouting, enterBtnSendEmail, enterBtnSendSms, enterBtnSmsTemplate,
            enterBtnStatus, enterBtnWindow, enterfstatus, enterftype, enterDefaults, enterItem,
            enterEEmailDescription, enterEEmailName, enterEPagesDescription, enterEPagesName, enterEPagesSlug,
            enterEPagesStatus, enterEEmailStatus, enterEEmailHtml, enterEPagesHtml, enterEActiveInnerTab,
            enterNName, enterNType, enterNDesc, enterPreviewId, enterBcTitle, enterDeleteId, enterCopyId,
            enterCItem, enterTrackingCodeId, enterPageId} = rootSlice.actions