import React, { useEffect } from 'react';
import { Link, useNavigate, useRoutes } from 'react-router-dom';
import '../css/dashboard.css'
import '../css/defaults-and-settings.css'

export const CompanySettingsDataItem = (props) => {

    const navigate = useNavigate();

    return(
        <div style={props.big == '' && props.small == '' ? {display: 'none'}: {}} className='settings-card-data-item'>
            <div className='settings-card-data-big' style={props.style}>
                {props.big}
            </div>
            <div className='settings-card-data-small'>
                {props.small}
            </div>
        </div>
    )
}