import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;

const BearerToken = localStorage.getItem("token");
const config = {
    headers: {
        Authorization: `Bearer ${BearerToken}`,
        // Permission: `Company Settings`,
    },
};

export async function fetchCompanySettings(id) {
    try {
        const { data } = await axios.get(`${baseURL}/companies/${id}`, config);
        return { isSuccess: true, data };
    } catch (e) {
        return {
            isSuccess: false,
            error:
                e.response.data.Error ||
                e.response.data.error ||
                e.response.data.err.message,
        };
    }
}

export async function updateCompanySettings(editData) {
    try {
        const { data } = await axios.put(
            `${baseURL}/companies/642d8e87314ba91929c36ba8`,
            {
                ...editData,
            },
            config
        );
        return { isSuccess: true, data };
    } catch (e) {
        return {
            isSuccess: false,
            error:
                e.response.data.Error ||
                e.response.data.error ||
                e.response.data.err.message,
        };
    }
}

const CompanySettingService = {
    updateCompanySettings,
    fetchCompanySettings,
};

export default CompanySettingService;
