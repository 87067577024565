import React from 'react';
import { makeItSmooth } from '../auxilliary';
import { useNavigate } from 'react-router-dom';
import '../css/dashboard.css'
import '../css/defaults-and-settings.css'
import { CompanySettingsDataItem } from './CompanySettingsDataItem';

export const SettingsCard = (props) => {

    const navigate = useNavigate();

    const nav = (link) => {
        makeItSmooth();
        navigate(link);
    }

    return(
        
        <div className='settings-card-container'>
            <div className='settings-card-header'>
                {props.title}
            </div>
            <div className='settings-card-body'>
                <div className='settings-card-text'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>
                <div className='settings-card-data-container'>
                    <CompanySettingsDataItem big={props.big1} small={props.small1} style={props.spec}/>
                    <CompanySettingsDataItem big={props.big2} small={props.small2} />
                </div>
            </div>
            <div onClick={() => nav(props.link)} className='settings-card-footer'>
                {props.destination}
            </div>
        </div>
    )
}