import React from 'react';
import '../style/layout/defaults_layout.css';
import { Topbar } from '../templates/Topbar';
import { Sidebar } from '../templates/Sidebar';

const Layout = ({children}) => {
    return(
        <div className='defaults_layout_container'>
            <Topbar />
            <div className='defaults_sidebar_plus_main'>
                <Sidebar />
                <div className='defaults_main_container'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout;