import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../layouts/Defaults-Layout";
import Editor from "../../layouts/Editor";
import { rotate } from "../../assets/js/rotateArrow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { returnArrows } from "../../assets/js/returnArrows";
import select_arrow from '../../assets/images/select_arrow.png';
import { enterBtnSendEmail, enterBtnSendSms } from "../../rootSlice";
import { useNavigate } from "react-router-dom";

export const ContentButtonEditor = () => {

    var e_button_linked_module = useSelector(state => state.e_button_linked_module);
    var e_button_link_module_option = useSelector(state => state.e_button_link_module_option);
    var e_btn_status = useSelector(state => state.btn_status);
    var e_btn_routing = useSelector(state => state.btn_routing);
    var e_btn_action = useSelector(state => state.btn_action);
    var e_btn_window = useSelector(state => state.btn_window);
    var e_btn_email_template = useSelector(state => state.btn_email_template);
    var e_btn_sms_template = useSelector(state => state.btn_sms_template);
    var btn_send_email = useSelector(state => state.btn_send_email);
    var btn_send_sms = useSelector(state => state.btn_send_sms);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function toggleSelect(id, aid, scid) {
        if(document.getElementById(id).style.opacity == '1') {
            document.getElementById(scid).classList.remove('de_s_z_active');
            document.getElementById(id).style.opacity = '0'
            returnArrows();
        } else {
            var elements = document.getElementsByClassName('de_opts');
            var consts = document.getElementsByClassName('de_s_z_normal');
            for(var i = 0; i < elements.length; i++) {
                elements[i].style.opacity = '0';
            }
            for(var i = 0; i < consts.length; i++) {
                consts[i].classList.remove('de_s_z_active');
            }
            document.getElementById(scid).classList.add('de_s_z_active');
            returnArrows();
            rotate(aid)
            document.getElementById(id).style.opacity = '1'
        }
    }

    function checkSendSms() {
        btn_send_sms == true ? dispatch(enterBtnSendSms(false)) : dispatch(enterBtnSendSms(true))
    }

    function checkSendEmail() {
        btn_send_email == true ? dispatch(enterBtnSendEmail(false)) : dispatch(enterBtnSendEmail(true))
    }

    useEffect(() => {
        if(localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            navigate('/')
        }
    })

    return(
        <Layout>
            <div className='navbar_plus_filters'>
                <div className='dashboard_navbar'>
                    <div className='dashboard_navigation'>
                        <div className='navigation-arrow-outside'>
                            <div className='navigation-arrow navigation-arrow-start navigation-arrow-parents'>
                                <FontAwesomeIcon className='navigation-arrow-icon' icon={faHouse} />
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/settings-and-defaults')} className='navigation-icon-after'>
                                Settings
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/default-content')} className='navigation-icon-after'>
                                Templates
                            </div>
                        </div>
                        <div className='navigation-icon-after third-arrow'>
                            <p className='nav-text'>[NAME]</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="de_btn_preview mt-54">
                <div className="de_btn_prev_ds_title">
                    Button Preview
                </div>
                <div className="btn_prev">[BUTTON TEXT]</div>
            </div>
            {/* <div className="de_btn_design mt-20">
                <div className="de_btn_prev_ds_title">
                    Button Design
                </div>    
            </div> */}
            <Editor>
                <form className='de_form'>
                    <div className="de_design_tab">
                        <div className="de_form_group">
                            <label className="de_label">Name</label>
                            <div className="de_input_container">
                                <input id="de_email_input_name" className="de_input de_input_name" defaultValue='[NAME]' />
                            </div>
                        </div>
                        <div className="de_form_group">
                            <label className="de_label">Description</label>
                            <div className="de_input_container">
                                <textarea spellCheck="false" id="de_page_input_desc" className="de_input de_input_desc" defaultValue={'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores.'} />
                            </div>
                        </div>
                        <div className="de_form_group">
                            <label className="de_label">Button Text</label>
                            <div className="de_input_container">
                                <input spellCheck="false" id="de_page_input_url" className="de_input de_input_btn_text" defaultValue='[BUTTON TEXT]' />
                            </div>
                        </div> 
                    </div>
                    <div className="de_settings_menu">
                        <div className="de_title">
                            Connect Item to Module
                        </div>
                        <div id='de_btn_sc_1' className="de_select_container_fit de_s_z_normal">
                            <div onClick={() => toggleSelect('de_btn_o1_arr', 'de_btn_s1_arr', 'de_btn_sc_1')} className="de_select_fit">{e_button_linked_module}<img id="de_btn_s1_arr" className="select_arrow" src={select_arrow} /></div>
                            <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                <div style={{opacity: '0'}} id='de_btn_o1_arr' className="de_options_fit_container de_opts">
                                        
                                </div>
                            </div>
                        </div>
                        <div id='de_btn_sc_2' className="de_select_container_fit de_s_z_normal">
                            <div onClick={() => toggleSelect('de_btn_o2_arr', 'de_btn_s2_arr', 'de_btn_sc_2')} className="de_select_fit">{e_button_link_module_option}<img id="de_btn_s2_arr" className="select_arrow" src={select_arrow} /></div>
                            <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                <div style={{opacity: '0'}} id='de_btn_o2_arr' className="de_options_fit_container de_opts">
                                        
                                </div>
                            </div>
                        </div>
                        <div id='de_btn_sc_3' className="de_select_container_fit de_s_z_normal">
                            <div onClick={() => toggleSelect('de_btn_o3_arr', 'de_btn_s3_arr', 'de_btn_sc_3')} className="de_select_fit">{e_btn_status}<img id="de_btn_s3_arr" className="select_arrow" src={select_arrow} /></div>
                            <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                <div style={{opacity: '0'}} id='de_btn_o3_arr' className="de_options_fit_container de_opts">
                                        
                                </div>
                            </div>
                        </div>
                        <div id='de_btn_sc_4' className="de_select_container_fit de_s_z_normal">
                            <div onClick={() => toggleSelect('de_btn_o4_arr', 'de_btn_s4_arr', 'de_btn_sc_4')} className="de_select_fit">{e_btn_action}<img id="de_btn_s4_arr" className="select_arrow" src={select_arrow} /></div>
                            <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                <div style={{opacity: '0'}} id='de_btn_o4_arr' className="de_options_fit_container de_opts">
                                        
                                </div>
                            </div>
                        </div>
                        <div id='de_btn_sc_5' className="de_select_container_fit de_s_z_normal">
                            <div onClick={() => toggleSelect('de_btn_o5_arr', 'de_btn_s5_arr', 'de_btn_sc_5')} className="de_select_fit">{e_btn_window}<img id="de_btn_s5_arr" className="select_arrow" src={select_arrow} /></div>
                            <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                <div style={{opacity: '0'}} id='de_btn_o5_arr' className="de_options_fit_container de_opts">
                                        
                                </div>
                            </div>
                        </div>
                        <div id='de_btn_sc_6' className="de_select_container_fit de_s_z_normal">
                            <div onClick={() => toggleSelect('de_btn_o6_arr', 'de_btn_s6_arr', 'de_btn_sc_6')} className="de_select_fit">{e_btn_routing}<img id="de_btn_s6_arr" className="select_arrow" src={select_arrow} /></div>
                            <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                <div style={{opacity: '0'}} id='de_btn_o6_arr' className="de_options_fit_container de_opts">
                                        
                                </div>
                            </div>
                        </div>
                        <div className="de_btn_checkmark_container">
                        <div className='select-all-container w-auto'>
                                <div>
                                    <label htmlFor='check-all' className='container pl-30'>
                                    {
                                        btn_send_email === true ? 
                                        <input onChange={checkSendEmail} id='check-send-email' type="checkbox" checked/> :
                                        <input onChange={checkSendEmail} id='check-send-email' type="checkbox" />
                                    }
                                    
                                    <span className='checkmark'></span>
                                    </label>
                                </div>
                                <label className='label de_cm_label' htmlFor='check-send-email'>Send Email</label>
                            </div>
                            <div className='select-all-container w-auto'>
                                <div>
                                    <label htmlFor='check-all' className='container pl-30'>
                                    {
                                        btn_send_sms == true ? 
                                        <input onChange={checkSendSms} id='check-send-sms' type="checkbox" checked/> :
                                        <input onChange={checkSendSms} id='check-send-sms' type="checkbox" />
                                    }
                                    
                                    <span className='checkmark'></span>
                                    </label>
                                </div>
                                <label className='label de_cm_label' htmlFor='check-send-sms'>Send SMS</label>
                            </div>
                        </div>
                        <div id='de_btn_sc_7' className="de_select_container_fit de_s_z_normal">
                            <div onClick={() => toggleSelect('de_btn_o7_arr', 'de_btn_s7_arr', 'de_btn_sc_7')} className="de_select_fit">{e_btn_email_template}<img id="de_btn_s7_arr" className="select_arrow" src={select_arrow} /></div>
                            <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                <div style={{opacity: '0'}} id='de_btn_o7_arr' className="de_options_fit_container de_opts">
                                        
                                </div>
                            </div>
                        </div>
                        <div id='de_btn_sc_8' className="de_select_container_fit de_s_z_normal">
                            <div onClick={() => toggleSelect('de_btn_o8_arr', 'de_btn_s8_arr', 'de_btn_sc_8')} className="de_select_fit">{e_btn_sms_template}<img id="de_btn_s8_arr" className="select_arrow" src={select_arrow} /></div>
                            <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                <div style={{opacity: '0'}} id='de_btn_o8_arr' className="de_options_fit_container de_opts">
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='de_bd_container'>
                        <div className="de_form_button_container">
                            <button type="submit" className="de_form_button de_form_button_left">Save as Draft</button>
                            <button type="submit" className="de_form_button de_form_button_right">Publish</button>
                        </div>
                    </div>
                </form>
            </Editor>
        </Layout>
    )
}