import React from "react";
import { useDispatch } from "react-redux";
import Layout from "../../layouts/Defaults-Layout";
import { enterPage } from "../../rootSlice";

export const Tzn = () => {

    const dispatch = useDispatch();
    dispatch(enterPage('timezones'));

    return(
        <Layout>
            TIME ZONES
        </Layout>
    )
}