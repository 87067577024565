import Modal from "../../layouts/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";

export const ModalDefaultLanguages = () => {
    
    const arr = [
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
        {a: 'Language', b: 'Language', c: 'Language', d: 'Language', e: 'D',},
    ]

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        console.log(selected)
    }, [selected])

    const selectAll = (e) => {
        if(e.target.checked) {
            var temp = [];
            arr.map((item) => {
                temp.push(arr.indexOf(item))
                return null
            })
            setSelected(temp);
            temp = [];
        }
    }

    const selectOne = (id) => {
        var temp = []
        if(!selected.includes(id)) {
            setSelected([...selected, id])
        } else {
            temp = selected.filter(item => item !== id)
            setSelected(temp)
            temp = []
        }
    }

    return(
        <Modal id='dl-modal' title='Available Languages' style={{padding: ' 0 0 20px '}}>
            <div className="dl_search">
                <input  type='text' className="dl_search_input" placeholder="Enter search term"/>
                <div className="dl_mg_container">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
            </div>
            <div className="dl_table_container">
                <div className="dl_sa_container">
                    <label for='dl-select-all' className="dl_table_label">Select All</label>
                    <Checkbox
                      onChange={selectAll}
                      sx={{
                        color: '#BDBDBD',
                        '&.Mui-checked': {
                          color: '#ed8154',
                          borderColor: '#ed8154'
                        },
                        '&.MuiCheckbox-root': {
                            cursor: 'pointer'
                        },
                        '&.MuiSvgIcon-root': { fontSize: 48 },
                        '&:hover': {
                            backgroundColor: 'transparent',
                            cursor: 'pointer'
                        },
                      }} />
                </div>
                <table className="dl_table">
                    <tr>
                        <th className="dl_table_label" style={{width: '20%'}}>Default</th>
                        <th className="dl_table_label" style={{width: '23%'}}>Languages</th>
                        <th className="dl_table_label" style={{width: '28%'}}>ISO 639-2</th>
                        <th className="dl_table_label" style={{width: '23%'}}>Custom Code</th>
                        <th className="dl_table_label" style={{width: '4%'}}>Enable</th>
                    </tr>
                    
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                defaultValue="0"
                            >
                        {
                        arr.map((item) => (
                            <tr style={arr.indexOf(item) % 2 === 0 ? {backgroundColor: '#F2F2F2'} : {backgroundColor: '#fff'}}>
                                <td style={{width: '20%'}}>
                                    <FormControlLabel value={arr.indexOf(item)} control={<Radio
                                          sx={{
                                            color: '#BDBDBD',
                                            '&.Mui-checked': {
                                              color: '#ED8154',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                                cursor: 'pointer'
                                            },
                                          }}
                                    />} />
                                </td>
                                <td style={{width: '23%'}}>[LANGUAGE]</td>
                                <td style={{width: '28%'}}>[LANGUAGE CODE]</td>
                                <td style={{width: '23%'}}>
                                    <input type='text' className='dl_cc_input' />
                                </td>
                                <td style={{width: '4%'}}>
                                    <Checkbox
                                    onClick={() => selectOne(arr.indexOf(item))}
                                    sx={{
                                        color: '#BDBDBD',
                                        cursor: 'pointer',
                                        '&.Mui-checked': {
                                        color: '#ed8154',
                                        borderColor: '#ed8154'
                                        },
                                        '&.MuiCheckbox-root': {
                                            cursor: 'pointer'
                                        },
                                        '&.MuiSvgIcon-root': { fontSize: 48 },
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            cursor: 'pointer'
                                        },
                                    }} checked={selected.includes(arr.indexOf(item)) || selected.length === arr.length ? true : false} />
                                </td>
                            </tr>)) 
                        }
                            </RadioGroup>
                        </FormControl>
                        
                </table>
            </div>
            <div className="modal_button_group" style={{paddingRight: '30px'}}>
                <button type="button" onClick={() => document.getElementById('dl-modal').style.display = 'none'} className="modal_button modal_button_strip">Close</button>
                <button type="button" className="modal_button modal_button_solid">Save</button>
            </div>
        </Modal>
    )
}