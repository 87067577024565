import React from "react";
import '../style/layout/modal.css';
import x from '../assets/images/x.png';
import { closeModal } from "../assets/js/modal";

const Modal = ({children, title, id, style}) => {
    
    return(
        <div id={id} style={{display: 'none'}}>
            <div onClick={() => closeModal()} className="modal_background"></div>
            <div className='modal_container'>
                <div className="modal_header">
                    {title}
                    <img onClick={() => closeModal(id)} alt='' src={x} className="modal_close_icon" />
                </div>
                <div className="modal_body" style={style ? style : {}}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal;