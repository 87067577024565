import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PermissionService from "../services/permission.service";

const initialState = {
  permissionGroupData: [],
  permissionData: [],
  isFetching: false,
  isSuccess: false,
  error: false,
};

export const fetchPermissionGroup = createAsyncThunk(
  "permission/fetchPermissiongroup",
  async (_thunkAPI) => {
    const response = await PermissionService.fetchPermissionGroup();
    return response;
  }
);

export const createPermissionGroup = createAsyncThunk(
  "permission/createPermissionGroup",
  async (permissionGroupData, _thunkAPI) => {
    await PermissionService.createPermissionGroup(permissionGroupData);
    const response = await PermissionService.fetchPermissionGroup();
    return response;
  }
);

export const editPermissionGroup = createAsyncThunk(
  "permission/editPermissionGroup",
  async (editData, _thunkAPI) => {
    await PermissionService.editPermissionGroup(editData);
    const response = await PermissionService.fetchPermissionGroup();
    return response;
  }
);

export const fetchAllPermission = createAsyncThunk(
  "permission/fetchAllPermission",
  async (_thunkAPI) => {
    const response = await PermissionService.fetchAllPermission();
    return response;
  }
);

export const deletePermissionGroup = createAsyncThunk(
  "permission/deletePermissionGroup",
  async (id, _thunkAPI) => {
    await PermissionService.deletePermissionGroup(id);
    const response = await PermissionService.fetchPermissionGroup();
    return response;
  }
);

const PermissionSlice = createSlice({
  name: "permission",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPermissionGroup.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.permissionGroupData = data;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = false;
    });

    builder.addCase(fetchPermissionGroup.pending, (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(fetchPermissionGroup.rejected, (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.error = true;
    });
    builder.addCase(createPermissionGroup.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.permissionGroupData = data;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = false;
    });

    builder.addCase(createPermissionGroup.pending, (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(createPermissionGroup.rejected, (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.error = true;
    });
    builder.addCase(editPermissionGroup.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.permissionGroupData = data;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = false;
    });

    builder.addCase(editPermissionGroup.pending, (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(editPermissionGroup.rejected, (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.error = true;
    });
    builder.addCase(deletePermissionGroup.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.permissionGroupData = data;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = false;
    });

    builder.addCase(deletePermissionGroup.pending, (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(deletePermissionGroup.rejected, (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.error = true;
    });
    builder.addCase(fetchAllPermission.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.permissionData = data;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = false;
    });

    builder.addCase(fetchAllPermission.pending, (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(fetchAllPermission.rejected, (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.error = true;
    });
  },
});

export default PermissionSlice;
