import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { useForm } from "react-hook-form";
// import axios from "axios";
// import { useEffect } from "react";
import "../../css/login.css";
import FormText from "../../components/formtext";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  editUser,
  fetchAllUsers,
} from "../../redux/features/UserManagementSlice";
import { Formik } from "formik";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLock } from "@fortawesome/free-solid-svg-icons";
// import { enterMe } from "../rootSlice";

const ResetPassword = () => {
  //   const navigate = useNavigate();
  //   const dispatch = useDispatch();
  //   const password = useSelector((state) => state.password);
  //   const passrep = useSelector((state) => state.passrep);
  //   const { register, handleSubmit } = useForm({
  //     defaultValues: { password, passrep },
  //   });

  //   const onSubmit = (data) => {
  //     if (data.passrep == data.password) {
  //       const body = {
  //         password: data.password,
  //       };

  //       document.getElementById("b-reset").disabled = true;

  //       var url = window.location.href;
  //       var urlArray = url.split("/");

  //       axios
  //         .put(
  //           `${process.env.REACT_APP_URL}/users/reset/${urlArray.slice(-1)}`,
  //           body
  //         )
  //         .then((res) => {
  //           document.getElementById("b-reset").disabled = true;
  //           navigate("/");
  //         })
  //         .catch(() => {
  //           alert("Error");
  //           document.getElementById("b-reset").disabled = true;
  //         });
  //     } else {
  //       alert("Passwords must match");
  //     }
  //   };

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { resetToken } = useParams();

  const allUserData = useSelector((state) => state.Users.allUserData);

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);
  const initialValues = {
    old_password: "",
    password: "",
    confirm_password: "",
  };

  const validate = (values) => {
    let errors = {};

    if (!values.old_password) {
      errors.old_password = "Old password is required";
    } else if (values.old_password.length < 4) {
      errors.old_password = "Old password too short";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password too short";
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Confirm password is required";
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = "Confirm Password Does Not Match";
    }

    return errors;
  };

  const submitForm = (values) => {
    const userData = allUserData.find((elem) => elem._id === resetToken);
    if (values.password === values.confirm_password) {
      const data = {
        ...userData,
        password: values.password,
        oldPassword: values.old_password,
      };
      dispatch(editUser(data)).then((res) => {
        if (res.payload.isSuccess) {
          localStorage.setItem("userEmail", userData.email);
          navigate("/");
        } else {
          return alert(res.payload.error);
        }
      });
    }
  };

  return (
    <div className="login__page">
      <div className="test_class">
        <FormText />
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={submitForm}
        >
          {(formik) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              // isValid,
              // dirty,
            } = formik;
            return (
              <div className="login__page-form">
                <h4>Reset password</h4>
                <form onSubmit={handleSubmit}>
                  <input
                    name="old_password"
                    required={true}
                    type="password"
                    placeholder="Old Password"
                    id="old_password"
                    value={values.old_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.old_password && touched.old_password
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.old_password && (
                    <span className="errorText">{errors.old_password}</span>
                  )}
                  {/* <span className="fa-icon"></span> */}
                  <input
                    name="password"
                    required={true}
                    type="password"
                    placeholder="New Password"
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.password && touched.password ? "input-error" : null
                    }
                  />
                  {errors.password && (
                    <span className="errorText">{errors.password}</span>
                  )}
                  {/* <span className="fa-icon"></span> */}
                  <input
                    name="confirm_password"
                    required={true}
                    type="password"
                    placeholder="Confirm Password"
                    id="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.confirm_password && touched.confirm_password
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.confirm_password && (
                    <span className="errorText">{errors.confirm_password}</span>
                  )}
                  <button type="submit" id="b-reset" className="sb-button">
                    Reset password
                  </button>
                  <ul>
                    <li>
                      <Link to="/create-account">
                        <a>Sign up for an account</a>
                      </Link>
                    </li>
                  </ul>
                </form>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
