import React from "react";
import {  Navigate } from "react-router-dom";

const PublicRoute = ({ children, ...rest }) => {
  const isAuthenticated = localStorage.getItem("token");

  return isAuthenticated ? <Navigate to="/home" /> : children;
};

export default PublicRoute;
