import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as HomeIcon } from "../../components/ui/styles/home.svg";
import BreadCrumb, {
    BreadcrumbItem,
    BreadcrumbLink,
} from "../../components/ui/Breadcrumb";
import { ReactComponent as EditIcon } from "../../components/ui/styles/Icon_Edit.svg";
import { ReactComponent as PlusIcon } from "../../components/ui/styles/Icon awesome-plus.svg";
import { communication_data } from "../../constant/communication";
import { ReactComponent as Trash } from "../../components/ui/styles/icon_Trash.svg";
import Modal from "../../components/ui/Modal";
import AddNewIntegrationModal from "../../components/model/addNewIntegrationModal";
import EditCommunication from "../../components/editCommunication";
import Button from "../../components/ui/Button";

const DataTable = styled.div`
    overflow: auto;
    background: #fff;
    max-height: 76vh;
    table {
        width: 100%;
        border-collapse: collapse;

        tr {
            border-bottom: 1px solid #ed8154;
        }
        tbody {
            tr:last-child {
                border-bottom: none;
            }
            td:last-child {
                color: #333;
            }
        }
        td,
        th {
            border-right: 1px solid #ed8154;
            padding: 8px 15px;
            font-size: 14px;
            color: #999;
            line-height: 20px;
            text-align: left;
            white-space: nowrap;
            svg,
            img {
                height: 22px;
                width: 22px;
            }
            &:last-child {
                border-right: none;
            }
            span {
                display: flex;
            }
            .dropDown-Link {
                display: flex;
                align-items: center;
                display: flex;
                align-items: center;
                text-decoration: underline;
                color: #417ac7;
                font-weight: 500;
                cursor: pointer;
                svg {
                    height: 15px;
                    width: 15px;
                    margin-right: 15px;
                    cursor: pointer;
                    path {
                        color: #417ac7;
                        fill: #417ac7;
                    }
                }
            }
        }
    }
`;
const Wrapper = styled.section`
    min-height: calc(100vh - 58px);
    margin-left: 250px;
    padding: 30px;
    box-sizing: border-box;
`;

const WrapperHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
`;

const WrapperHeadBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        background: #ed8154;
        border: 1px solid #ed8154;
        font-size: 14px !important;
        color: #fff;
        text-transform: capitalize;
        min-width: 225px;
        height: 42px;
        font-weight: 700;
        border-radius: 12px;

        &:hover {
            background: #fff;
            color: #ed8154;
        }
    }

    .iconButton {
        min-width: 42px;
        margin-right: 15px;

        svg {
            height: 18px;
            width: 18px;
            path {
                fill: #fff;
            }
        }
        &:hover {
            svg {
                color: #ed8154;
                fill: #ed8154;
                path {
                    fill: #ed8154;
                }
            }
        }
    }
`;

const TableActionBtn = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    span {
        background: #fff;
        height: 20px;
        width: 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
            height: 16px !important;
            width: 16px !important;
            path {
                fill: #ed8154;
            }
        }
        &:last-child {
            svg path {
                fill: #982727;
            }
        }
        &.large-btn {
            height: 40px;
            width: 40px;
            svg {
                height: 20px !important;
                width: 20px !important;
            }
        }
    }
`;

const CheckBox = styled.div`
    text-align: left;
    input {
        display: none;
    }
    label {
        font-size: 14px !important;
        color: #666 !important;
        margin: 0;
        width: 100%;
        text-align: left;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:before {
            content: "";
            height: 15px;
            width: 15px;
            border: 1px solid #bdbdbd;
            border-radius: 3px;
            background: #fff;
            display: inline-block;
            margin-right: 12px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 15px;
        }
        &:after {
            content: "";
            height: 5px;
            width: 9px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            position: absolute;
            left: 4px;
            transform: rotate(-45deg);
            margin-bottom: 4px;
        }
    }
    input:checked + label:before {
        background: #ed8154;
        border: 2px solid #ed8154;
    }
`;

const Communication = () => {
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [items] = useState([
        { id: 1, to: "/home", Icon: HomeIcon },
        { id: 2, to: "/company-settings/overview", label: "Settings" },
        {
            id: 3,
            to: "/company-settings/communication",
            label: "Communication",
        },
    ]);

    return (
        <React.Fragment>
            <Wrapper>
                <WrapperHead>
                    <BreadCrumb primary>
                        {items?.map(({ to, label, Icon, id }, index) => (
                            <BreadcrumbLink key={index} to={to}>
                                <BreadcrumbItem
                                    children={Icon ? <Icon /> : label}
                                />
                            </BreadcrumbLink>
                        ))}
                    </BreadCrumb>
                    {!isEditing && (
                        <WrapperHeadBtn>
                            <Button
                                onClick={() => setShowModal(true)}
                                className="iconButton"
                            >
                                <PlusIcon className="icon-white" />
                            </Button>
                        </WrapperHeadBtn>
                    )}
                </WrapperHead>
                {isEditing ? (
                    <EditCommunication close={setIsEditing} />
                ) : (
                    <DataTable>
                        <table
                            style={{
                                border: "1px solid #ed8154",
                                width: "100%",
                            }}
                        >
                            <thead>
                                <tr>
                                    <th>Module Name</th>
                                    <th>Provider</th>
                                    <th>Description</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Enable</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {communication_data?.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{data.module_name}</td>
                                            <td>{data.provider}</td>
                                            <td>{data.description}</td>
                                            <td>{data.type}</td>
                                            <td>{data.status}</td>
                                            <td>
                                                <CheckBox>
                                                    <input
                                                        type="checkbox"
                                                        value="Invite Set Up"
                                                        id={data.module_name}
                                                        defaultChecked
                                                    />
                                                    <label
                                                        htmlFor={
                                                            data.module_name
                                                        }
                                                    >
                                                        {/* {variableName.variableName} */}
                                                    </label>
                                                </CheckBox>
                                            </td>
                                            <td>
                                                <TableActionBtn>
                                                    <span
                                                        onClick={() =>
                                                            setIsEditing(true)
                                                        }
                                                    >
                                                        <EditIcon />
                                                    </span>
                                                    <span>
                                                        <Trash />
                                                    </span>
                                                </TableActionBtn>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </DataTable>
                )}
            </Wrapper>

            <Modal show={showModal}>
                <AddNewIntegrationModal close={setShowModal} />
            </Modal>
        </React.Fragment>
    );
};

export default Communication;
