import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;
const BearerToken = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${BearerToken}`,
    // Permission: `Company Settings`,
  },
};

export async function fetchAllUsers() {
  try {
    const { data } = await axios.get(`${baseURL}/users/all`, config);
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}
export async function getAllCompanySettings(webUrl) {
  try {
    const { data } = await axios.post(`${baseURL}/company-setting`, {
      ...webUrl,
    });
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function fetchAllInvitedUsers() {
  try {
    const { data } = await axios.get(`${baseURL}/users/invited-users`, config);
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function fetchUser(id) {
  try {
    const { data } = await axios.get(`${baseURL}/users/${id}`, config);
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function createUser(addUser) {
  try {
    const { data } = await axios.post(
      `${baseURL}/users`,
      { ...addUser },
      config
    );
    // if (data) {
    //   await axios.post(`https://q-one.tech/com/sendings/email`, {});
    // }
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function editUser(editData) {
  try {
    const { data } = await axios.put(
      `${baseURL}/users/${editData?.email}`,
      {
        ...editData,
      },
      config
    );
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.message ||
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function updateUser(editData) {
  try {
    const { data } = await axios.put(
      `${baseURL}/users`,
      {
        ...editData,
      },
      config
    );
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.message ||
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function updateProfile(editData) {
  try {
    const { data } = await axios.put(
      `${baseURL}/users/update-userprofile/${editData._id}`,
      {
        ...editData,
      },
      config
    );
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.message ||
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function registerUser(editData) {
  try {
    const { data } = await axios.post(`${baseURL}/users/register`, {
      ...editData,   
    });
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function deleteUser(id) {
  try {
    const { data } = await axios.delete(`${baseURL}/users/${id}`, config);
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}
export async function deleteInviteUser(id) {
  try {
    const { data } = await axios.delete(`${baseURL}/users/invite/${id}`, config);
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function login(loginData) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}/users/login`,
      { ...loginData },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.message ||
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function userRestPassword(editData) {
  try {
    const { data } = await axios.put(
      `${baseURL}/users/reset-password/${editData?._id}`,
      {
        ...editData,
      },
      config
    );
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.message ||
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function fetchAllCompanies() {
  try {
    const { data } = await axios.get(
      `${baseURL}/contact-users/contact-companies`,
      config
    );
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

const UserManagementService = {
  createUser,
  fetchUser,
  editUser,
  deleteUser,
  deleteInviteUser,
  login,
  fetchAllUsers,
  registerUser,
  fetchAllInvitedUsers,
  userRestPassword,
  updateUser,
  updateProfile,
  getAllCompanySettings,
};

export default UserManagementService;
