import React, { useEffect } from "react";
import Layout from "../../layouts/Defaults-Layout";
import Editor from "../../layouts/Editor";
import { useSelector, useDispatch } from "react-redux";
import { enterEActiveDevice, enterESmsLength, enterESmsText } from "../../rootSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faEye } from "@fortawesome/free-solid-svg-icons";
import { rotate } from "../../assets/js/rotateArrow";
import { returnArrows } from "../../assets/js/returnArrows";
import select_arrow from '../../assets/images/select_arrow.png';
import { useNavigate } from "react-router-dom";

export const ContentSMSEditor = () => {

    useEffect(() => {
        if(localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            navigate('/')
        }
    })

    function toggleSelect(id, aid, scid) {
        if(document.getElementById(id).style.opacity == '1') {
            document.getElementById(scid).classList.remove('de_s_z_active');
            document.getElementById(id).style.opacity = '0'
            returnArrows();
        } else {
            var elements = document.getElementsByClassName('de_opts');
            var consts = document.getElementsByClassName('de_s_z_normal');
            for(var i = 0; i < elements.length; i++) {
                elements[i].style.opacity = '0';
            }
            for(var i = 0; i < consts.length; i++) {
                consts[i].classList.remove('de_s_z_active');
            }
            document.getElementById(scid).classList.add('de_s_z_active');
            returnArrows();
            rotate(aid)
            document.getElementById(id).style.opacity = '1'
        }
    }

    var e_active_device = useSelector(state => state.e_active_device);

    var e_email_linked_module = useSelector(state => state.e_email_linked_module);
    var e_email_link_module_option = useSelector(state => state.e_email_link_module_option);
    var e_email_not_rate = useSelector(state => state.e_email_not_rate);
    var e_email_not_measure = useSelector(state => state.e_email_not_measure);
    var e_sms_length = useSelector(state => state.e_sms_length);
    var e_sms_text = useSelector(state => state.e_sms_text)
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    dispatch(enterEActiveDevice('Preview'))

    const countSmsLength = (e) => {
        dispatch(enterESmsText(e.target.value))
    }

    return(
        <Layout>
            <div className='navbar_plus_filters'>
                <div className='dashboard_navbar'>
                    <div className='dashboard_navigation'>
                        <div className='navigation-arrow-outside'>
                            <div className='navigation-arrow navigation-arrow-start navigation-arrow-parents'>
                                <FontAwesomeIcon className='navigation-arrow-icon' icon={faHouse} />
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/settings-and-defaults')} className='navigation-icon-after'>
                                Settings
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/default-content')} className='navigation-icon-after'>
                                Templates
                            </div>
                        </div>
                        <div className='navigation-icon-after third-arrow'>
                            <p className='nav-text'>[NAME]</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="de_preview_container">
                <div className="dt_tab_bar">
                    <div className='dt_tab dt_tab_active'>Plain Text</div>
                </div>
                <div className="sms_input_container">
                    <textarea id='sms_text' onChange={countSmsLength} spellCheck='false' className="sms_input" value={e_sms_text}></textarea>
                </div>
                <div className="char_counter_container">
                    { e_sms_text.length + '/160 Characters' }
                </div>
            </div>
            <Editor>
                <form className="de_form">
                    <div className="de_form_group">
                        <label className="de_label">Name</label>
                        <div className="de_input_container">
                            <input id="de_sms_input_name" className="de_input de_input_name" defaultValue='[NAME]' />
                        </div> 
                    </div>
                    <div className="de_form_group">
                        <label className="de_label">Description</label>
                        <div className="de_input_container">
                            <textarea spellcheck="false" id="de_sms_input_desc" className="de_input de_input_desc" defaultValue={'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores.'} />
                        </div>
                    </div>
                    <div className="de_settings_menu">
                        <div className="de_title">
                            Connect Item to Module
                        </div>
                        <div id='de_page_sc_1' className="de_select_container_fit de_s_z_normal">
                            <div onClick={() => toggleSelect('de_page_o1_arr', 'de_page_s1_arr', 'de_page_sc_1')} className="de_select_fit">{e_email_linked_module}<img id="de_page_s1_arr" className="select_arrow" src={select_arrow} /></div>
                            <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                <div style={{opacity: '0'}} id='de_page_o1_arr' className="de_options_fit_container de_opts">
                                        
                                </div>
                            </div>
                        </div>
                        <div id='de_page_sc_2' className="de_select_container_fit de_s_z_normal">
                            <div onClick={() => toggleSelect('de_page_o2_arr', 'de_page_s2_arr', 'de_page_sc_2')} className="de_select_fit">{e_email_link_module_option}<img id="de_page_s2_arr" className="select_arrow" src={select_arrow} /></div>
                            <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                <div style={{opacity: '0'}} id='de_page_o2_arr' className="de_options_fit_container de_opts">
                                        
                                </div>
                            </div>
                        </div>
                        <div className="de_hor_border"></div>
                        <div className="de_title">
                            Notification Frequency
                        </div>
                        <div className="de_settings_form_group de_s_z_normal">
                            <label className="de_setting_label">Increment</label>
                            <div className="de_select_container_slim">
                                <input defaultValue={e_email_not_rate} type='number' className="de_select_slim" />
                            </div>
                        </div>
                        <div id='de_page_sc_3' className="de_settings_form_group de_s_z_normal">
                            <label className="de_setting_label">Unit</label>
                            <div className="de_select_container_slim">
                                <div onClick={() => toggleSelect('de_page_o3_arr', 'de_page_s3_arr', 'de_page_sc_3')} className="de_select_slim">{e_email_not_measure}<img id="de_page_s3_arr" className="select_arrow" src={select_arrow} /></div>
                                <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                    <div style={{opacity: '0'}} id='de_page_o3_arr' className="de_options_slim_container de_opts">
                                                
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="de_hor_border"></div>
                        <div className="de_settings_form_group">
                            <label className="de_setting_label">Increment</label>
                            <div className="de_select_container_slim">
                                <input defaultValue={e_email_not_rate} type='number' className="de_select_slim" />
                            </div>
                        </div>
                        <div id='de_page_sc_4' className="de_settings_form_group de_s_z_normal">
                            <label className="de_setting_label">Unit</label>
                            <div className="de_select_container_slim">
                                <div onClick={() => toggleSelect('de_page_o4_arr', 'de_page_s4_arr', 'de_page_sc_4')} className="de_select_slim">{e_email_not_measure}<img id="de_page_s4_arr" className="select_arrow" src={select_arrow} /></div>
                                <div style={{position: 'relative', width: '0px', height: '0px'}}>
                                    <div style={{opacity: '0'}} id='de_page_o4_arr' className="de_options_slim_container de_opts">
                                                
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="de_freq_indecrementer">
                            <div className="de_freq_inc">+ Add Frequency</div>
                            <div className="de_freq_dec">- Delete Frequency</div>
                        </div>
                    </div>
                    <div className='de_bd_container'>
                        <div className="de_devices_container dep_mt">
                            <div className="defaults_device de_device de_device_active">
                                <FontAwesomeIcon icon={faEye} />
                            </div>
                        </div>
                        <div className="de_form_button_container">
                            <button disabled={e_sms_text.length > 160 ? true : false} type="submit" className="de_form_button de_form_button_left">Save as Draft</button>
                            <button disabled={e_sms_text.length > 160 ? true : false} type="submit" className="de_form_button de_form_button_right">Publish</button>
                        </div>
                    </div>
                </form>
            </Editor>
        </Layout>
    )
}