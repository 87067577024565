import React, { forwardRef } from "react";
import { AddIf } from "./util";

const Drawer = forwardRef((props, ref) => {
  const {
    isVisible,
    children,
    className,
    left,
    right,
    top,
    bottom,
    childrenClasses,
  } = props;
  let classes = "ui-drawer sidebar";
  classes = AddIf(className, classes, className);
  classes = AddIf(left, classes, "ui-drawer--left");
  classes = AddIf(right, classes, "ui-drawer--right");
  classes = AddIf(top, classes, "ui-drawer--top");
  classes = AddIf(bottom, classes, "ui-drawer--bottom");
  classes = AddIf(isVisible, classes, "sidebar--open");

  return (
    <React.Fragment>
      <div className={classes} style={props.style}>
        <div className={childrenClasses}>{children}</div>
      </div>
    </React.Fragment>
  );
});
export default Drawer;
