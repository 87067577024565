import { useDispatch, useSelector } from "react-redux";
import Modal from "../../layouts/Modal";
import { smooth } from "../../assets/js/smooth";
import { enterPageId, enterTrackingCodeId } from "../../rootSlice";
import { useEffect } from "react";
import axios from "axios";

export const ModalDeleteTrackingCode = () => {

    const code_id = useSelector(state => state.tc_id);
    const dispatch = useDispatch();
    const page_id = useSelector(state => state.page_id);

    useEffect(() => {

    }, [page_id, code_id])

    const deleteCode = () => {
        axios.delete(`${process.env.REACT_APP_URL}/defaults/default/${page_id}/code/${code_id}`)
        .then((res) => {
            dispatch(enterPageId(null));
            dispatch(enterTrackingCodeId(null));
            document.getElementById('tc-del-modal').style.display = 'none';
            alert(res.data.message);
            smooth();
        })
    }

    return(
        <Modal id='tc-del-modal' title='Delete Tracking Code'>
            <h5>Are you sure you want to delete this item?</h5>
            <div className="modal_button_group">
                <button type="button" onClick={() => document.getElementById('tc-del-modal').style.display = 'none'} className="modal_button modal_button_strip">No</button>
                <button type="button" onClick={() => deleteCode()} className="modal_button modal_button_solid">Yes</button>
            </div>
        </Modal>
    )
}