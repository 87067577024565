import { forwardRef } from "react";

import { AddIf } from "./util";

const Button = forwardRef((props, ref) => {
  const {
    className,
    children,
    primary,
    outline,
    onClick,
    style,
    block,
    disabled,
    icon,
    iconTransparent,
    type
  } = props;
  let classes = "ui-button";
  classes = AddIf(className, classes, className);
  classes = AddIf(primary, classes, "ui-button--primary");
  classes = AddIf(outline, classes, "ui-button--outline");
  classes = AddIf(block, classes, "ui-button--block");
  classes = AddIf(disabled, classes, "ui-button--disabled");
  classes = AddIf(icon, classes, "ui-button--icon");
  classes = AddIf(iconTransparent, classes, "ui-button--iconTransparent");

  return (
    <button
      className={classes}
      onClick={true?onClick:undefined}
      style={style}
      disabled={disabled}
      ref={ref}
      type={type}
    >
      {children}
      {icon}
      {iconTransparent}
    </button>
  );
});

export default Button;
