import React from 'react'
import styled from "styled-components";

const ContentModuleTitle = styled.h6`
  margin: 0 0 20px;
  font-size: 20px;
  color: #666;
  font-weight: bold;
  margin: 0 0 20px;
`;
const ContentModuleText = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-size: 14px;
  line-height: 24px;
  color: #666;
`;

const CardContent = (props) => {
  const {title,description} =props
  return (
    <React.Fragment>
      <ContentModuleTitle>{title}</ContentModuleTitle>
      <ContentModuleText>{description}</ContentModuleText>
    </React.Fragment>
  )
}

export default CardContent