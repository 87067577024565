function testSpecialChars(string) {
    const specialChars = /[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(string);
}

export function slugify(slug) {
    if(testSpecialChars(slug)) {
        alert('Slug can not contain special characters. Please avoid it');
    } else {
        while(slug.includes(' ')) {
            slug = slug.replace(' ', '-')
        }
        while(slug.includes('--')) {
            slug = slug.replace('--', '-')
        }
        return slug;
    }
}