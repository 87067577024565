import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CompanySettingService from "../services/companySetting.service";

const initialState = {
  companySettings: {},
  isFetching: false,
  isSuccess: false,
  error: false,
};

export const updateCompanySettings = createAsyncThunk(
  "CompaniesManager/updateCompanySettings",
  async (editData, _thunkAPI) => {
    const response = await CompanySettingService.updateCompanySettings(
      editData
    );
    return response;
  }
);

export const fetchCompanySettings = createAsyncThunk(
  "CompaniesManager/fetchCompanySettings",
  async (id,_thunkAPI) => {
    const response = await CompanySettingService.fetchCompanySettings(id);
    return response;
  }
);
const CompanySettingSlice = createSlice({
  name: "CompaniesManager",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanySettings.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.companySettings = data;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = false;
    });

    builder.addCase(fetchCompanySettings.pending, (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(fetchCompanySettings.rejected, (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.error = true;
    });
  },
});

export default CompanySettingSlice;
