import { configureStore } from "@reduxjs/toolkit";
import PermissionSlice from "./features/PermissionSlice";
import UserManagementSlice from "./features/UserManagementSlice";
import UserRoleSlice from "./features/UserRoleSlice";
import CompanySettingSlice from "./features/CompanySettingSlice";

// INITIALIZED STORE
const store = configureStore({
  reducer: {
    Users: UserManagementSlice.reducer,
    Permissions: PermissionSlice.reducer,
    UserRole: UserRoleSlice.reducer,
    CompanySetting : CompanySettingSlice.reducer
  },
  devTools: true,
});

export const RootState = store.getState;
export const AppDispatch = store.dispatch;
export default store;
