import React from "react";
import { useEffect } from "react";
import '../style/defaults/editor.css';

const Editor = ({children}) => {

    useEffect(() => {

    }, [children])
    
    return(
        <div className="de_container">
            {children}
        </div>
    )
}

export default Editor;