import React from "react";
import styled from "styled-components";

const CardMain = styled.div`
  background: #fcf6ef;
  border: 1px solid #ed8154;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardHead = styled.div`
  border-bottom: 1px solid #ed8154;
  background: #fff;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardName = styled.h6`
  font-size: 16px !important;
  color: #ed8154 !important;
  margin: 0;
`;

const CardBody = styled.div`
  padding: 15px;
  margin: 0;
  list-style-type: none;
  background: #fcf6ef;
  padding-bottom: 32px;
`;

const CardText = styled.p`
  font-size: 14px !important;
  color: #ed8154 !important;
  margin: 0 0 10px;
  text-align: left;
`;

const CardMainInner = styled.div``;

const CardInfo = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  min-height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  li {
    font-size: 14px !important;
    color: #ed8154 !important;
    margin: 0 0 10px;
    text-align: left;
    span {
      font-weight: 600;
      width: 125px;
      display: inline-block;
    }
  }
`;

const CardFooter = styled.div`
  display: flex;
  button {
    background: #fff;
    border: none;
    border-top: 1px solid #ed8154;
    width: 100%;
    height: 40px;
    box-shadow: none;
    border-radius: 0;
    cursor: pointer;
    font-size: 15px;
    color: #ed8154;
    font-weight: 600;
    text-transform: capitalize;
    &:hover {
      background: #fff;
      color: #ed8154 !important;
      box-shadow: none;
    }
    &:first-of-type {
      background: #ed8154;
      color: #fff !important;
      &:hover {
        background: #ed8154;
        color: #fff !important;
        box-shadow: none;
      }
    }
  }
`;

const HomeCard = (props) => {
  const {
    title,
    className,
    paragraph,
    children,
    onClick,
    buttonName,
    secondButtonName,
    onClickSecond,
    pathname,
  } = props;
  return (
    <React.Fragment>
      <CardMain className={className}>
        <CardMainInner>
          {title ? (
            <CardHead>
              <CardName>{title}</CardName>
            </CardHead>
          ) : null}
          <CardBody>
            {paragraph ? <CardText>{paragraph}</CardText> : ""}
            {children ? <CardInfo>{children}</CardInfo> : ""}
          </CardBody>
        </CardMainInner>
        {pathname && buttonName ? (
          <CardFooter>
            <a
              href={pathname}
              style={{
                width: "100%",
                textDecoration: "none",
                color: "inherit",
                whiteSpace: "nowrap",
              }}
            >
              <button onClick={onClick} className="settings__footer-button">
                {buttonName}
              </button>
            </a>
            {secondButtonName ? (
              <button
                onClick={true ? onClickSecond : undefined}
                className="settings__footer-button"
              >
                {secondButtonName}
              </button>
            ) : null}
          </CardFooter>
        ) : null}
      </CardMain>
    </React.Fragment>
  );
};

export default HomeCard;
