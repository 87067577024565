export const showHide = (id) => {
    var el = document.getElementById(id);
    if(el.style.visibility == 'visible') {
        el.style.visibility = 'hidden'
        document.getElementById('psa').style.display = 'none';
        document.getElementById('ssa').style.display = 'none';
        document.getElementById('tsa').style.display = 'none';
    } else {
        el.style.visibility = 'visible'
        document.getElementById('psa').style.display = 'block';
        document.getElementById('ssa').style.display = 'block';
        document.getElementById('tsa').style.display = 'block';
    }
}