import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserRoleService from "../services/userRole.service";

const initialState = {
  userRole: [],
  isFetching: false,
  isSuccess: false,
  error: false,
};

export const fetchUserRole = createAsyncThunk(
  "userRole/fetchUserRole",
  async (id, _thunkAPI) => {
    const response = await UserRoleService.fetchUserRole(id);
    return response;
  }
);
export const fetchAllUserRole = createAsyncThunk(
  "userRole/fetchAllUserRole",
  async (_thunkAPI) => {
    const response = await UserRoleService.fetchAllUserRole();
    return response;
  }
);

export const createUserRole = createAsyncThunk(
  "userRole/createUserRole",
  async (Data, _thunkAPI) => {
   await UserRoleService.createUserRole(Data);
   const response = await UserRoleService.fetchAllUserRole();
    return response;
  }
);

export const editUserRole = createAsyncThunk(
  "userRole/editUserRole",
  async (editData, _thunkAPI) => {
    await UserRoleService.editUserRole(editData);
    const response = await UserRoleService.fetchAllUserRole();
    return response;
  }
);

export const deleteUserRole = createAsyncThunk(
  "userRole/deleteUserRole",
  async (id, _thunkAPI) => {
    await UserRoleService.deleteUserRole(id);
    const response = await UserRoleService.fetchAllUserRole();
    return response;
  }
);

const UserRoleSlice = createSlice({
  name: "userRole",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllUserRole.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.userRole = data;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = false;
    });

    builder.addCase(fetchAllUserRole.pending, (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(fetchAllUserRole.rejected, (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.error = true;
    });
    builder.addCase(createUserRole.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.userRole = data;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = false;
    });

    builder.addCase(createUserRole.pending, (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(createUserRole.rejected, (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.error = true;
    });
    builder.addCase(fetchUserRole.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.userRole = data;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = false;
    });

    builder.addCase(fetchUserRole.pending, (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(fetchUserRole.rejected, (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.error = true;
    });
    builder.addCase(editUserRole.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.userRole = data;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = false;
    });
    builder.addCase(editUserRole.pending, (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(editUserRole.rejected, (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.error = true;
    });
    builder.addCase(deleteUserRole.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.userRole = data;
      state.isFetching = false;
      state.isSuccess = true;
      state.error = false;
    });

    builder.addCase(deleteUserRole.pending, (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(deleteUserRole.rejected, (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.error = true;
    });
  },
});

export default UserRoleSlice;
