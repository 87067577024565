export const communication_data = [
  {
    _id: "1",
    module_name: "Company Admin",
    provider: "Sendgrid",
    description: "Mailer for marketing bulk emails to customers",
    type: "Email",
    status: "Ready",
    enable: true,
    editable: true,
  },
  {
    _id: "2",
    module_name: "Project Invites",
    provider: "Exact Target",
    description: "Main mailer for invite bulk emails,",
    type: "Email",
    status: "Data missing",
    enable: false,
    editable: false,
  },
  {
    _id: "3",
    module_name: "Project Invites (Fallback)",
    provider: "Amazon AES",
    description: "Fallback mailer for invite bulk emails.",
    type: "Email",
    status: "Ready",
    enable: true,
    editable: true,
  },
  {
    _id: "4",
    module_name: "Project Invites (SMS)",
    provider: "Twilio",
    description: "SMS server for SMS invites.",
    type: "SMS",
    status: "Ready",
    enable: false,
    editable: true,
  },
  {
    _id: "5",
    module_name: "System Emails",
    provider: "Socketlabs",
    description: "Single system emails to managers and members.",
    type: "Email",
    status: "Data Missing",
    enable: false,
    editable: false,
  },
];

export const communication_type = [{ name: "Email" }, { name: "SMS" }];

export const provider = [
  { name: "Socketlabs" },
  { name: "Amazon AES" },
  { name: "Exact Target" },
  { name: "Sendgrid" },
];
