import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as HomeIcon } from "../../components/ui/styles/home.svg";
import { useLocation } from "react-router-dom";
import Breadcrumb, {
  BreadcrumbLink,
  BreadcrumbItem,
} from "../../components/ui/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  editPermissionGroup,
  fetchAllPermission,
  fetchPermissionGroup,
} from "../../redux/features/PermissionSlice";
import { AccessPermissionArray } from "../../constant/permissiongroup";
import { SearchDropDown } from "../../components/ui/Dropdown";
import { useParams } from "react-router";

const Wrapper = styled.section`
  min-height: calc(100vh - 58px);
  margin-left: 250px;
  padding: 30px;
  box-sizing: border-box;
`;
const WrapperHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const PermissionTableBody = styled.div`
  padding: 0;
  margin: 0;
  list-style-type: none;
  background: #fff;
  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid #ed8154;
    }
    tbody tr:last-child {
      border-bottom: none;
    }

    td,
    th {
      border-right: 1px solid #ed8154;
      padding: 8px 15px;
      font-size: 14px;
      color: #999;
      line-height: 22px;
      text-align: left;
      svg,
      img {
        height: 22px;
        width: 22px;
      }
      }
    }
  }
`;
const PermissionTable = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
  max-height: calc(100vh - 195px);
  overflow: auto;
`;

const UserBlockList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 0;
  padding: 20px;
  list-style-type: none;
  li {
    color: #828282;
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    text-align: left;
    span {
      font-weight: 500;
      color: #417ac7;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
`;

const DataTable = styled.div`
  overflow: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    tr {
      border-bottom: 1px solid #ed8154;
    }
    tbody {
      tr:last-child {
        border-bottom: none;
      }
      td:last-child {
        color: #333;
      }
    }
    td,
    th {
      border-right: 1px solid #ed8154;
      padding: 4px 15px;
      font-size: 14px;
      color: #999;
      line-height: 22px;
      text-align: left;
      white-space: nowrap;
      width: 100%;
      svg,
      img {
        height: 22px;
        width: 22px;
      }
      &:last-child {
        border-right: none;
      }
      .dropDown-Link {
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        text-decoration: underline;
        color: #417ac7;
        font-weight: 500;
        cursor: pointer;
        svg {
          height: 15px;
          width: 15px;
          margin-right: 15px;
          cursor: pointer;
          path {
            color: #417ac7;
            fill: #417ac7;
          }
        }
      }
    }
  }
`;
const GrayDropdown = styled.div`
  width: 350px;
  div {
    box-sizing: border-box;
  }
  & > div > div:first-child {
    border: 1px solid #ddd;
    padding: 6px 15px;
    width: 100%;
    height: 36px;
    font-size: 14px;
    line-height: 24px;
    svg {
      width: 13px;
      width: 13px;
      top: auto;
    }
  }
`;
const Permission = () => {
  const location = useLocation();
  const { id } = useParams();
  const [path] = useState([
    { id: 1, to: "/home", Icon: HomeIcon },
    { id: 2, to: "/company-settings/overview", label: "Setting" },
    {
      id: 3,
      to: `/company-settings/permission-group`,
      label: "Permission Group",
    },
    {
      id: 4,
      to: `/company-settings/permission-group/permission/${id}`,
      label: "Permission",
    },
  ]);

  const PermissionData = useSelector(
    (state) => state.Permissions.permissionData
  );
  const permissionGroupData = useSelector(
    (state) => state.Permissions.permissionGroupData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllPermission());
    dispatch(fetchPermissionGroup());
  }, []);

  const handleGivePermission = (name, permissionId) => {
    var data = permissionGroupData.filter((elem) => elem._id === id);

    if (name === "No Access") {
      var newPermissionRemoveData = [...data[0]?.permissions];
      const newData = newPermissionRemoveData?.filter(
        (elem) => elem !== permissionId
      );
      var PermissionRemoveData = data?.map((elem) => {
        return {
          ...elem,
          permissions: newData,
        };
      });
      dispatch(editPermissionGroup(PermissionRemoveData[0]));
    }
    if (name === "Full Access") {
      var newPermissionAddData = [...data[0]?.permissions];
      if (!newPermissionAddData.includes(permissionId)) {
        newPermissionAddData.push(permissionId);
        var PermissionAddData = data?.map((elem) => {
          return {
            ...elem,
            permissions: newPermissionAddData,
          };
        });
        dispatch(editPermissionGroup(PermissionAddData[0]));
      }
    }
  };

  return (
    <Wrapper>
      <WrapperHead>
        <Breadcrumb primary>
          {path?.map(({ to, label, Icon }, index) => (
            <BreadcrumbLink key={index} to={to}>
              <BreadcrumbItem children={Icon ? <Icon /> : label} />
            </BreadcrumbLink>
          ))}
        </Breadcrumb>
      </WrapperHead>

      <PermissionTable>
        <UserBlockList>
          <li>
            Edit Access:
            <span>{ location.state.data.name ? location.state.data.name : "" }</span>
          </li>
        </UserBlockList>
        <PermissionTableBody>
          {/* <PermissionDataTable /> */}
          <DataTable>
            <table style={{ border: "1px solid #ed8154", width: "100%" }}>
              <thead>
                <tr>
                  <th>System</th>
                  <th>Access Level</th>
                </tr>
              </thead>
              <tbody>
                {PermissionData?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data.name}</td>
                      <td>
                        <GrayDropdown>
                          <SearchDropDown
                            name={AccessPermissionArray}
                            defaultValue={
                              permissionGroupData
                                .find((elem) => elem._id === id)
                                ?.permissions?.includes(data._id)
                                ? "Full Access"
                                : "No Access"
                            }
                            setStatusValue={(name) =>
                              handleGivePermission(name, data._id)
                            }
                          />
                        </GrayDropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </DataTable>
        </PermissionTableBody>
      </PermissionTable>
    </Wrapper>
  );
};

export default Permission;
