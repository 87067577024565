import { Route, Routes } from "react-router-dom";

import { DefaultsTranslations } from "./components/DefaultsTranslations";
import { SettingsAndDefaults } from "./components/SettingsAndDefaults";
import LegalSecurity from "./pages/legalsecurity";
import { CompanySettings } from "./components/CompanySettings";
import { DefaultContent } from "./components/defaults/Default-Content";
import { Tzn } from "./components/defaults/tzn";
import { PreviewWebPage } from "./components/defaults/PreviewWebPage";
import { ContentEmailEditor } from "./components/defaults/ContentEmailEditor";
import { ContentButtonEditor } from "./components/defaults/ContentButtonEditor";
import { ContentPageEditor } from "./components/defaults/ContentPageEditor";
import { ContentSMSEditor } from "./components/defaults/ContentSMSEditor";
import { TranslationsEmailAndPageEditor } from "./components/languages/TranslationsEmailAndPageEditor";
import UserManagementDashboard from "./pages/usermanagement/index";
import CommonHeader from "./components/commonheader";
import "./components/ui/styles/components.scss";
import "./App.css";
import Roles from "./pages/roles";
// import { LegalAndSecurity } from "./components/LegalAndSecurity";
import PermissionGroup from "./pages/permissiongroups";
import Permission from "./pages/permission";

import CreateAccount from "./pages/createaccount";
import Login from "./pages/login";
import Register from "./pages/register";
import ForgotPassword from "./pages/forgatepassword";
import ResetPassword from "./pages/resetpassword";
import Password from "./pages/password";
import Overview from "./pages/overview";
import Home from "./pages/home";
import PublicRoute from "./routes/publicroutes";
import PrivateRoute from "./routes/privateroutes";
import Communication from "./pages/communication";
export default function AppRoutes() {
  return (
    <div>
      <CommonHeader />
      <Routes>
        <Route
          exact
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/register/:registerToken"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/create-account"
          element={
            <PublicRoute>
              <CreateAccount />
            </PublicRoute>
          }
        />
        <Route
          exact
          path="/password"
          element={
            <PublicRoute>
              <Password />
            </PublicRoute>
          }
        />

        <Route
          exact
          path="/home"
          element={
            <PrivateRoute moduleName="Home">
              <Home />
            </PrivateRoute>
          }
        ></Route>
          <Route
          exact
          path="reset-password/:resetToken"
          element={
            <PrivateRoute moduleName="System Defaults">
              <ResetPassword />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/overview"
          element={
            <PrivateRoute moduleName="System Defaults">
              <Overview />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/permission-group"
          element={
            <PrivateRoute moduleName="System Defaults">
              <PermissionGroup />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/permission-group/permission/:id"
          element={
            <PrivateRoute moduleName="System Defaults">
              <Permission />
            </PrivateRoute>
          }
        />
        {/* <Route exact path="/company-settings/" element={<Login />} /> */}
        {/* <Route exact path="/company-settings/forgot-password" element={<ForgotPassword />} /> */}
        <Route
          exact
          path="/company-settings/user-management-dashboard"
          element={
            <PrivateRoute moduleName="System Defaults">
              <UserManagementDashboard />
            </PrivateRoute>
          }
        />
        <Route exact path="/company-settings/permission-group" element={PermissionGroup} />
        <Route exact path="/company-settings/permission-group/permission" element={Permission} />
      
        {/* <Route exact path="/company-settings/register/:registerToken" element={<Register />} /> */}
        <Route
          exact
          path="/company-settings/languages-and-translations"
          element={
            <PrivateRoute moduleName="System Defaults">
              <DefaultsTranslations />
            </PrivateRoute>
          }
        />
         <Route
          exact
          path="/company-settings/communication"
          element={
            <PrivateRoute moduleName="System Defaults">
              <Communication />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/translations-editor/:id"
          element={
            <PrivateRoute moduleName="System Defaults">
              <TranslationsEmailAndPageEditor />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/settings-and-defaults"
          element={
            <PrivateRoute moduleName="System Defaults">
              <SettingsAndDefaults />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/legal-and-security"
          element={
            <PrivateRoute moduleName="System Defaults">
              <LegalSecurity />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/settings"
          element={
            <PrivateRoute moduleName="System Defaults">
              <CompanySettings />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/default-content"
          element={
            <PrivateRoute moduleName="System Defaults">
              <DefaultContent />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/preview-web-page/:url"
          element={
            <PrivateRoute moduleName="System Defaults">
              <PreviewWebPage />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/edit-content-email/:id"
          element={
            <PrivateRoute moduleName="System Defaults">
              <ContentEmailEditor />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/edit-content-sms"
          element={
            <PrivateRoute moduleName="System Defaults">
              <ContentSMSEditor />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/edit-content-page/:id"
          element={
            <PrivateRoute moduleName="System Defaults">
              <ContentPageEditor />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/edit-content-button"
          element={
            <PrivateRoute moduleName="System Defaults">
              <ContentButtonEditor />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/user-management-dashboard/roles"
          element={
            <PrivateRoute moduleName="System Defaults">
              <Roles />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-settings/tzn"
          element={
            <PrivateRoute moduleName="System Defaults">
              <Tzn />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/"
          element={
            <PrivateRoute moduleName="Home">
              <Home />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}
