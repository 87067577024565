import React, { useEffect } from "react";
import '../../style/defaults/table.css'
// import { useDispatch, useSelector } from "react-redux";
import Layout from "../../layouts/Defaults-Layout";
import { enterPage, enterItem, enterPreviewId, enterBcTitle, enterDeleteId, enterCopyId, enterCName, enterCDesc, enterCItem, enterPageId } from "../../rootSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faSliders, faMagnifyingGlass, faPen, faCopy, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import toggler_arrow from '../../assets/Path 79.png';
import { returnArrows } from '../../assets/js/returnArrows';
import { rotate } from '../../assets/js/rotateArrow';
import { showHide } from '../../assets/js/showHide';
import { openCloseModal } from '../../assets/js/modal';
import { useNavigate } from "react-router-dom";
import { ModalSendPreviewEmail } from "./ModalSendPreviewEmail";
import { ModalSendPreviewSMS } from "./ModalSendPreviewSMS";
import axios from "axios";
import { ModalAddDefaultItem } from "./ModalAddDefaultItem";
import { useState } from "react";
import { openModal } from "../../assets/js/modal";
import { ModalDeleteDefaultItem } from "./ModalDeleteDefaultItem";
import { ModalCopyDefaultItem } from "./ModalCopyDefaultItem";
import styled from "styled-components";
const Wrapper = styled.section`
  min-height: calc(100vh - 58px);
  margin-left: 250px;
  padding: 30px;
  box-sizing: border-box;
  background-color:#e4e4e4;
`;
export const DefaultContent = () => {

    // const dispatch = useDispatch();
    const navigate = useNavigate();
    // dispatch(enterPage('defaults'));
    
    const [defaults, setDefaults] = useState([])
    const [sort, setSort] = useState(localStorage.getItem('defaults_sort') || 'date_asc')
    const [status, setStatus] = useState(localStorage.getItem('def_status') || '')
    const [search, setSearch] = useState('')
    const [type, setType] = useState(localStorage.getItem('defaults_main_tab') || 'email');
    // var item = useSelector(state => state.item);
    // var delete_id = useSelector(state => state.delete_id)
    // var i = useSelector(state => state.c_item)
    var sort_title = localStorage.getItem('defaults_sort_title') || 'Date Ascending';

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_URL}/defaults?status=${status !== '' ? status.toLowerCase() : ''}&type=${type}&search=${search}&sort=${sort}`)
        .then((res) => {
            if(res.data.data) {
                setDefaults(res.data.data)
            } else {
                alert('An error occured while fetching');
            }
        })
    }, [status, type, sort, search])

    // useEffect(() => {
    //     if(localStorage.getItem('token') === null || !localStorage.getItem('token')) {
    //         navigate('/')
    //     }
    // })

    async function goToEdit(id, bc, link) {

        await axios.get(`${process.env.REACT_APP_URL}/defaults/default/${id}`)
        .then((res) => {
            if(res.data.data) {
                // dispatch(enterItem({...item, ...res.data.data}))
                // dispatch(enterBcTitle(bc))
            } else {
                alert('An error occured while fetching');
            }
        })

        navigate('/' + link)

    }

    function toggleTab(tab) {
        setType(tab)
        localStorage.setItem('defaults_main_tab', tab)
    }

    function chooseStatus(value) {
        document.getElementById('filter-first').style.display = 'none';
        rotate('tsa')
        setStatus(value)
        localStorage.setItem('def_status', value)
        if(value == 'Show all statuses') {
            setStatus('');
        } else {
            setStatus(value);
        }
        
    }

    // function choosePanel(value) {
    //     document.getElementById('filter-second').style.display = 'none';
    //     rotate('psa')
    //     dispatch(enterfs2(value))
    // }

    function chooseSort(value, title) {
        document.getElementById('filter-third').style.display = 'none';
        localStorage.setItem('defaults_sort', value)
        localStorage.setItem('defaults_sort_title', title)
        rotate('ssa')
        setSort(value)
    }

    function toggleSelect(id, aid) {
        if(document.getElementById(id).style.display == 'flex') {
            document.getElementById(id).style.display = 'none'
            returnArrows();
        } else {
            var elements = document.getElementsByClassName('options-container');
            for(var i = 0; i < elements.length; i++) {
                elements[i].style.display = 'none'
            }
            returnArrows();
            rotate(aid)
            document.getElementById(id).style.display = 'flex'
        }
    }
    
    function openPreviewModal(modal_id, item_id) {
        openModal(modal_id);
        // dispatch(enterPreviewId(item_id));
    }

    function openDeleteModal(modal_id, item_id) {
        openModal(modal_id);
        // dispatch(enterDeleteId(item_id));
    }

    function openCopyModal(modal_id, item_id) {
        // dispatch(enterCopyId(item_id));
        openModal(modal_id);
    }

    const searchDefaults = (e) => {
        setSearch(e.target.value)
    }

    return(
        <Wrapper>
            <div className='navbar_plus_filters'>
                <div className='dashboard_navbar'>
                    <div className='dashboard_navigation'>
                        <div className='navigation-arrow-outside'>
                            <div className='navigation-arrow navigation-arrow-start navigation-arrow-parents'>
                                <FontAwesomeIcon className='navigation-arrow-icon' icon={faHouse} />
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/settings-and-defaults')} className='navigation-icon-after'>
                                Settings
                            </div>
                        </div>
                        <div className='navigation-icon-after third-arrow'>
                            <p className='nav-text'>Templates</p>
                        </div>
                    </div>
                    <div className='on-top-buttons-container'>
                        <button  onClick={() => openModal('sp-add-item')} className='on-top-button'>+ New</button>
                        <button onClick={() => openCloseModal('upload-language-modal')} className='on-top-button'>Translations</button>
                        <button onClick={() => showHide('ft-cnt')} className='settings-button'><FontAwesomeIcon className='settings-icon' icon={faSliders} /></button>
                    </div>
                </div>
                <div id='ft-cnt' style={{visibility: 'visible'}} className='filter-container'>
                    <div className='filter-select-container'>
                        <div className='css-select-container'>
                            <div onClick={() => toggleSelect('filter-first', 'tsa')} className='filter_select'>{status === '' ? 'Show All Statuses' : status}
                                <img className='transition' style={{transform: 'rotate(0)'}} id='tsa' alt='' src={toggler_arrow} />
                            </div>
                            <div id='filter-first' style={{display: 'none'}} className='options-container'>
                                <div onClick={() => chooseStatus('')} className='option option-top'>Show all statuses</div>
                                <div onClick={() => chooseStatus('Live')} className='option'>Live</div>
                                <div onClick={() => chooseStatus('Draft')} className='option option-bottom'>Draft</div>
                            </div>
                        </div>
                        {/* <div className='css-select-container'>
                            <div  onClick={() => toggleSelect('filter-second', 'psa')} className='filter_select'>{fs2}
                                <img className='transition' id='psa' style={{transform: 'rotate(0)'}} alt='' src={toggler_arrow} />
                            </div>
                            <div id='filter-second' style={{display: 'none'}} className='options-container'>
                            <div onClick={() => choosePanel('Show all modules')} className='option option-top'>Show all modules</div>
                            <div onClick={() => choosePanel('Users')} className='option'>Users</div>
                            <div onClick={() => choosePanel('Panels')} className='option'>Panels</div>
                            <div onClick={() => choosePanel('Projects')} className='option'>Projects</div>
                            <div onClick={() => choosePanel('Proposals')} className='option option-bottom'>Proposals</div>
                        </div>
                    </div> */}
                    <div className='css-select-container'>
                        <div onClick={() => toggleSelect('filter-third', 'ssa')} className='filter_select'>{sort_title}
                            <img className='transition' id='ssa' style={{transform: 'rotate(0)'}} alt='' src={toggler_arrow} />
                        </div>
                        <div id='filter-third' style={{display: 'none'}} className='options-container'>
                            <div onClick={() => chooseSort('date_asc', 'Date Ascending')} className='option option-top'>Date ascending</div>
                                <div onClick={() => chooseSort('date_desc', 'Date Descending')} className='option'>Date descending</div>
                                <div onClick={() => chooseSort('name_asc', 'Name Ascending')} className='option'>Name ascending</div>
                                <div onClick={() => chooseSort('name_desc', 'Name Decending')} className='option'>Name descending</div>
                                {/* <div onClick={() => chooseSort('type_asc', 'Type Ascending')} className='option'>Type ascending</div>
                                <div onClick={() => chooseSort('type_desc', 'Type Descending')} className='option option-bottom'>Type descending</div> */}
                            </div>
                        </div>
                        <div className='search-container filter-search-bar'>
                            <input className='search_bar filter-search' placeholder='Enter search term' onChange={searchDefaults} />
                            <div className='search-button'>
                                <FontAwesomeIcon className='search-button-icon' icon={faMagnifyingGlass} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            
            <div className="dt_container">
                <div className="dt_tab_bar">
                    <div onClick={() => toggleTab('email')} className={type === 'email' ? 'dt_tab dt_tab_active' : 'dt_tab'}>Email</div>
                    {/* <div onClick={() => toggleTab('sms')} className={dt_tab === 'sms' ? 'dt_tab dt_tab_active' : 'dt_tab'}>SMS</div> */}
                    <div onClick={() => toggleTab('page')} className={type === 'page' ? 'dt_tab dt_tab_active' : 'dt_tab'}>Pages</div>
                    {/* <div onClick={() => toggleTab('buttons')} className={dt_tab === 'buttons' ? 'dt_tab dt_tab_active' : 'dt_tab'}>Buttons</div> */}
                </div>
                <div className="dt_head">
                    <div className="dt_head_title dt-col-1"> Name </div>
                    <div className="dt_head_title dt-col-2"> Description </div>
                    {/* <div className="dt_head_title dt-col-3"> Module </div> */}
                    <div className="dt_head_title dt-col-4"> Status </div>
                    <div className="dt_head_title dt-col-5"> Actions </div>
                </div>
                <div className="dt_body">
                    {
                        defaults.map((item) => (
                            <div key={item._id} className="dt_item">
                                <div className="dt-col-1 bold">
                                    <div className="dt_item_text dt_item_text_name">
                                        {item.name}
                                    </div>
                                </div>
                                <div className="dt-col-2">
                                    <div className="dt_item_text dt_item_text_desc">
                                        {item.description}
                                    </div>
                                </div>
                                {/* <div className="dt-col-3">{item.module}</div> */}
                                <div className={item.status === 'live' ? "dt-col-4 bold live": "dt-col-4 bold draft"}>{item.status == 'live' ? 'Live' : 'Draft'}</div>
                                <div className="dt-col-5 dt_actions_container">
                                    <div className="dt_action dt_action_regular" onClick={
                                        type === 'email' ? () => goToEdit(item._id, 'EMAIL', `edit-content-email/${item._id}`) :
                                        type === 'sms' ? () => goToEdit('', 'SMS', 'edit-content-sms') :
                                        type === 'page' ? () => goToEdit(item._id, 'PAGE', `edit-content-page/${item._id}`) :
                                        type === 'button' ? () => goToEdit('', 'BUTTON', 'edit-content-button') :
                                        () => console.log('No such route')
                                    }>
                                        <FontAwesomeIcon icon={faPen} />
                                    </div>
                                    <div className="dt_action dt_action_regular" onClick={() => openCopyModal('sp-cp-item', item._id)}>
                                        <FontAwesomeIcon icon={faCopy} />
                                    </div>
                                    {
                                        type!== 'button' ?
                                            <div className="dt_action dt_action_regular" onClick={
                                                type === 'email' ? () => openPreviewModal('sp-email', item._id) :
                                                type === 'sms' ? () => openModal('sp-sms') :
                                                type === 'page' ? () => navigate(`/preview-web-page/${item.slug}`) :
                                                () => console.log('No such route')
                                             }>
                                                <FontAwesomeIcon icon={faEye} />
                                            </div> :
                                                <></>
                                    }
                                    <div className="dt_action dt_action_danger">
                                        <FontAwesomeIcon icon={faTrash} onClick={() => openDeleteModal('sp-del-item', item._id)} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {/* <ModalSendPreviewEmail /> */}
            <ModalSendPreviewSMS />
            {/* <ModalAddDefaultItem /> */}
            {/* <ModalDeleteDefaultItem /> */}
            {/* <ModalCopyDefaultItem /> */}
            
        </Wrapper>
    )
}