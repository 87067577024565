import React from "react";
import { Link } from "react-router-dom";
// import { useSelector } from 'react-redux';
// import { useForm } from 'react-hook-form';
// import axios from "axios";
import "../../css/login.css";
import FormText from "../../components/formtext";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ForgotPassword = () => {
  // const email = useSelector(state => state.email);

  // const { register, handleSubmit} = useForm({defaultValues:{email}})

  // const onSubmit = (data) => {

  //     const body = {
  //         email: data.email
  //     }

  //     document.getElementById('b-forgot').disabled = true

  //     axios.put(`${process.env.REACT_APP_URL}/users/forgot`, body)
  //     .then(res => {
  //         alert('Reset link sent by email')
  //         document.getElementById('b-forgot').disabled = false
  //     }).catch(() => {
  //         alert("Error")
  //         document.getElementById('b-forgot').disabled = false
  //     })
  // }

  return (
    <div className="login__page">
      <div className="test_class">
        <FormText />
        <div className="login__page-form">
          <h4>Enter recovery email</h4>
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
          {/* <span className='fa-icon'><FontAwesomeIcon icon={faEnvelope} /></span> */}
          <input
            // name='email' {...register('email')}
            required={true}
            type="text"
            placeholder="Enter your email"
          />

          <button id="b-forgot" className="sb-button bor" type="submit">
            Reset password
          </button>
          {/* </form> */}
          <ul className="end">
            <li>
              <Link to="/create-account">
                <p className="td_underline">Sign up for an account</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
