import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "./styles/search.svg";
import { ReactComponent as DownArray } from "./styles/downArray.svg";
// import { useHistory, useRouteMatch } from "react-router-dom";
import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { setSelectProjectId } from "../../redux/features/ProjectSlice";
const SelectBox = styled.select`
    border: none;
    cursor: pointer;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    color: #000;
    width: 250px;
    font-weight: bold;
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
`;
const DefaultOption = styled.option`
    color: #666;
`;
const Option = styled.option`
    color: #000;
`;

const Dropdown = (props) => {
    const { name, defaultValue, disableValue, setDropdownValue } = props;
    const [select, setSelect] = useState();
    const handleSelect = (e) => {
        setSelect(e.target.value);
        if (setDropdownValue !== undefined) {
            setDropdownValue(e.target.value);
        }
    };
    return (
        <SelectBox
            onChange={handleSelect}
            value={select}
            defaultValue={"DEFAULT"}
        >
            {defaultValue !== undefined ? (
                <DefaultOption value="DEFAULT" disabled={true} hidden>
                    {defaultValue}
                </DefaultOption>
            ) : (
                ""
            )}
            {disableValue !== undefined ? (
                <option disabled>{disableValue}</option>
            ) : (
                ""
            )}
            {name.map((option, index) => {
                return (
                    <Option key={index} value={option.name}>
                        {option.name}
                    </Option>
                );
            })}
        </SelectBox>
    );
};

export default Dropdown;

const DropDownContainer = styled.div`
    width: 100%;
    position: relative;
`;

const DropDownHeader = styled.div`
    background: #ffffff;
    border-radius: 10px;
    padding: 8px 15px;
    width: 100%;
    height: 38px;
    font-size: 15px;
    line-height: 23px;
    color: #666;
    font-weight: 400;
    border: 1px solid #ed8154;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-right: 34px;
    box-sizing: border-box;
    svg {
        width: 13px;
        width: 13px;
        position: absolute;
        right: 14px;
        top: 14px;
    }
`;

const DropDownListContainer = styled.div`
    background: #ffffff;
    border-radius: 10px;
    padding: 15px;
    font-size: 15px;
    color: #666;
    font-weight: 600;
    border: 1px solid #ed8154;
    text-align: left;
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    top: 100%;
    min-width: 142px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
`;

const DropDownList = styled.ul`
    padding: 0;
    margin: 0;
    box-sizing: border-box;
`;

const ListItem = styled("li")`
    list-style: none;
    margin-bottom: 8px;
    font-size: 14px;
    color: #888;
    cursor: pointer;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    &:hover {
        color: #000;
    }
`;

const ImportCardSearch = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    input {
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #bbb;
        border-radius: 10px 0 0 10px;
        height: 38px;
        font-size: 15px;
        color: #666;
        font-weight: 500;
        width: calc(100% - 38px);
        &:focus {
            border: 1px solid #bbb;
            box-shadow: none;
            outline: none;
        }
    }
    button {
        width: 38px;
        height: 38px;
        background: #ed8154;
        border: 1px solid #ed8154;
        border-radius: 0 10px 10px 0;
        padding: 10px;
        cursor: pointer;
    }
`;

const CheckBox = styled.div`
    text-align: left;
    input {
        display: none;
    }
    label {
        font-size: 14px !important;
        color: #666 !important;
        margin: 0;
        width: 100%;
        text-align: left;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:before {
            content: "";
            height: 15px;
            width: 15px;
            border: 2px solid #bdbdbd;
            border-radius: 3px;
            background: #fff;
            display: inline-block;
            margin-right: 12px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 15px;
        }
        &:after {
            content: "";
            height: 5px;
            width: 9px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            position: absolute;
            left: 4px;
            transform: rotate(-45deg);
            margin-bottom: 4px;
        }
    }
    input:checked + label:before {
        background: #ed8154;
        border: 2px solid #ed8154;
    }
`;

export const SearchDropDown = (props) => {
    const {
        name,
        SearchPlaceholder,
        defaultValue,
        // setProjectDrawer,
        setProjectSetUpModal,
        disableValue,
        getId,
        setNewValues,
        setStatusValue,
        // deleteProjectCard,
        // handleArchive,
        dropPlaceholder,
        setSelectedDataId,
        handleDropdownActions,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [dropDownValue, setDropDownValue] = useState(defaultValue);
    useEffect(() => {
        setDropDownValue(defaultValue);
    }, [defaultValue]);

    // const selectId = useSelector((state) => state.Project.selectProjectId);
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const toggling = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        if (setNewValues !== undefined) {
            setNewValues(dropDownValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropDownValue]);
    // const history = useHistory();
    // const { url } = useRouteMatch();
    // const dispatch = useDispatch();
    // const handleDropDown = (option) => {
    //   if (option.type === "Link") {
    //     history.push(`${option.path}/${selectId}`);
    //     localStorage.setItem("selectedProjectId", selectId);
    //   } else if (option.type === "drawer") {
    //     setProjectDrawer();
    //     if (option.name === "Project Notes") {
    //       getId();
    //     }
    //   } else if (option.type === "modal") {
    //     setProjectSetUpModal(true);
    //   } else if (option.type === "Select") {
    //     setDropDownValue(option.name);
    //     if (setStatusValue !== undefined) {
    //       setStatusValue(option.name);
    //     }
    //   } else if (option.type === "SelectBucketProject") {
    //     setDropDownValue(option.name);
    //   } else if (option.type === "Selected") {
    //     setDropDownValue(option.name);
    //     dispatch(setSelectProjectId(option.id));
    //     localStorage.setItem("selectedProjectId", option.id);
    //     history.replace(`${url}/${option.id}`);
    //   }

    //   if (deleteProjectCard !== undefined) {
    //     if (option.name === "Delete Project") {
    //       deleteProjectCard();
    //     }
    //   }
    //   if (option.name === "Archive Project") {
    //     handleArchive();
    //   }
    //   if (option.name === "UnArchived Project") {
    //     handleArchive();
    //   }

    //   // eslint-disable-next-line no-lone-blocks
    // };

    const handleDropDown = (option) => {
        if (option.type === "Select") {
            setDropDownValue(option.name);
            if (setStatusValue !== undefined) {
                setStatusValue(option.name);
            }
            if (setSelectedDataId !== undefined) {
                setSelectedDataId(option._id);
            }
        }
        if (option.type === "modal") {
            if (setProjectSetUpModal) {
                setProjectSetUpModal(true);
            }
        }
        if (handleDropdownActions) {
            handleDropdownActions(option);
        }
    };
    return (
        <DropDownContainer
            ref={wrapperRef}
            style={{ zIndex: isOpen ? "11" : "1" }}
            onClick={true ? getId : undefined}
        >
            <DropDownHeader onClick={toggling}>
                <span>
                    {dropPlaceholder !== undefined ? dropPlaceholder : ""}
                </span>
                {dropDownValue}{" "}
                <DownArray
                    style={{
                        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                />
            </DropDownHeader>
            {isOpen && (
                <DropDownListContainer>
                    <div>
                        {SearchPlaceholder !== undefined ? (
                            <ImportCardSearch>
                                <input
                                    type="text"
                                    value={searchValue}
                                    placeholder={SearchPlaceholder}
                                    onChange={(e) =>
                                        setSearchValue(e.target.value)
                                    }
                                />
                                <button>
                                    <SearchIcon />
                                </button>
                            </ImportCardSearch>
                        ) : null}
                        <DropDownList
                            onClick={
                                name[0]?.type !== "Checkbox" ? toggling : null
                            }
                        >
                            {disableValue !== undefined ? (
                                <ListItem>{disableValue}</ListItem>
                            ) : null}
                            {name
                                .filter((option) =>
                                    `${option.name}`
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                )
                                .map((option, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            value={option.name}
                                            onClick={() => {
                                                handleDropDown(option);
                                            }}
                                        >
                                            {option.type === "Checkbox" ? (
                                                <>
                                                    <CheckBox>
                                                        <input
                                                            type="checkbox"
                                                            name={option.name}
                                                            id={option.name}
                                                        />
                                                        <label
                                                            htmlFor={
                                                                option.name
                                                            }
                                                        >
                                                            {option.name}
                                                        </label>
                                                    </CheckBox>
                                                </>
                                            ) : (
                                                <label htmlFor={option.name}>
                                                    {option.name}
                                                </label>
                                            )}
                                        </ListItem>
                                    );
                                })}
                        </DropDownList>
                    </div>
                </DropDownListContainer>
            )}
        </DropDownContainer>
    );
};
