import { ReactComponent as HomeIcon } from "../../components/ui/styles/home.svg";
import { ReactComponent as Settings } from "../../components/ui/styles/settings.svg";
import { ReactComponent as Security } from "../../components/ui/styles/security.svg";
import { ReactComponent as Users } from "../../components/ui/styles/Icon_UserManagement.svg";
import { ReactComponent as Overview } from "../../components/ui/styles/Icon_OverviewPage.svg";
import { ReactComponent as Communication } from "../../components/ui/styles/Icon_Communications.svg";
// import { ReactComponent as Payment } from "../../components/ui/styles/Icon_PaymentIntegrations.svg";
import { ReactComponent as Permission } from "../../components/ui/styles/Icon_PermissionGroups.svg";
// import { ReactComponent as Research } from "../../components/ui/styles/Icon_ResearchDefender.svg";

export const SideMenu = [
  {
    svgIcons: HomeIcon,
    text: "Home",
    path: "/home",
    active: false,
  },
];

export const SideAccountSettingsMenu = [
  {
    svgIcons: Overview,
    text: "Overview",
    path: "/company-settings/overview",
    active: false,
  },
  {
    svgIcons: Users,
    text: "User Management",
    path: "/company-settings/user-management-dashboard",
    active: false,
  },
  {
    svgIcons: Permission,
    text: "Permission Groups",
    path: "/company-settings/permission-group",
    active: false,
  },
  {
    svgIcons: Settings,
    text: "Company Settings",
    path: "/company-settings/settings",
    active: false,
  },
  {
    svgIcons: Communication,
    text: "Communications",
    path: "/company-settings/communication",
    active: false,
  },
  // {
  //   svgIcons: Payment,
  //   text: "Payment Integration",
  //   path: "/payment-integration",
  //   active: false,
  // },
  // {
  //   svgIcons: Research,
  //   text: "Research Defender",
  //   path: "/research-defender",
  //   active: false,
  // },
  {
    svgIcons: Security,
    text: "Legal & Security",
    path: "/company-settings/legal-and-security",
    active: false,
  },
];


