import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/dashboard.css";
import "../css/defaults-and-settings.css";
import "../css/company-settings.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import logoPH from "../assets/Image 9.png";
// import { useDispatch } from 'react-redux';
// import { enterPage } from '../rootSlice';
// import Layout from '../layouts/Defaults-Layout';
import { useState } from "react";
import axios from "axios";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import {
    fetchCompanySettings,
    updateCompanySettings,
} from "../redux/features/CompanySettingSlice";
import { useDispatch } from "react-redux";

const Wrapper = styled.section`
    min-height: calc(100vh - 58px);
    margin-left: 250px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #e4e4e4;
`;
export const CompanySettings = () => {
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const [company, setCompanyData] = useState({});
    const [check, setCheck] = useState(null);
    const [logo, setLogo] = useState("");
    const [selectedLogo, setSelectedLogo] = useState(null);
    // dispatch(enterPage('cst'));
    const { register, handleSubmit, reset } = useForm();
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        dispatch(fetchCompanySettings(user._id)).then((res) => {
            setCompanyData(res.payload.data);
        });
    }, []);
    var url = window.location.href;
    var urlArray = url.split("/");

    //   async function getCompanyData() {
    //       await axios.get(`${process.env.REACT_APP_URL}/companies/${urlArray.slice(-1)}`, {headers: {
    //           'Authorization': `Bearer ${localStorage.getItem('token')}`
    //       }})
    //           .then((res) => {
    //               if(res.data.data) {
    //                  setCompanyData({...company, ...res.data.data})
    //                  setLogo(company.logo)
    //               } else {
    //                   alert(res.data.message)
    //               }
    //           })
    //   }

    //   useEffect(() => {
    //       getCompanyData()
    //       if(localStorage.getItem('token') === null || !localStorage.getItem('token')) {
    //           navigate('/')
    //       }
    //   }, [])

    useEffect(() => {
        reset(company);
    }, [company]);

    const preview = useMemo(() => {
        if (check != null) {
            return logo;
        } else {
            if (company.logo) {
                return `${process.env.REACT_APP_URL}/company_logos/${company.logo}`;
            }
            return undefined;
        }
    }, [logo]);

    const setNewLogo = (e) => {
        setCheck(1);
        setLogo(window.URL.createObjectURL(e.target.files[0]));
        setSelectedLogo(e.target.files[0]);
    };

    const deleteLogo = () => {
        setCheck(1);
        setLogo(null);
        setSelectedLogo(null);
    };

    const updateCompany = (data) => {
        var submitData = {
            ...company,
            name: data.name,
            invoice_email: data.invoice_email,
            invoice_address: data.invoice_address,
            vat: data.vat,
            contact_person: data.contact_person,
        };

        if (selectedLogo === null && check !== null) {
            submitData = { ...submitData, logo: "" };
        }

        const imageData = {
            logo: selectedLogo,
        };
        dispatch(updateCompanySettings(submitData));
        // axios
        //   .put(
        //     `${process.env.REACT_APP_URL}/companies/${urlArray.slice(-1)}`,
        //     submitData,
        //     {
        //       headers: {
        //         "Content-type": "application/json",
        //         Authorization: `Bearer ${localStorage.getItem("token")}`,
        //       },
        //     }
        //   )
        //   .then((res) => {
        //     if (selectedLogo && selectedLogo !== null) {
        //       axios
        //         .put(
        //           `${process.env.REACT_APP_URL}/companies/logo/${urlArray.slice(
        //             -1
        //           )}`,
        //           imageData,
        //           {
        //             headers: {
        //               "Content-type": "multipart/form-data",
        //               Authorization: `Bearer ${localStorage.getItem("token")}`,
        //             },
        //           }
        //         )
        //         .then((res) => {
        //           if (res.data.message) {
        //             setSelectedLogo(null);
        //             alert("Changes saved.");
        //           }
        //         });
        //     } else {
        //       if (res.data.message) {
        //         alert(res.data.message);
        //       } else {
        //         alert("Error during uploading");
        //       }
        //     }
        //   });
    };

    return (
        <Wrapper>
            <div className="dashboard_navigation">
                <div className="navigation-arrow-outside">
                    <div className="navigation-arrow navigation-arrow-start navigation-arrow-parents">
                        <FontAwesomeIcon
                            className="navigation-arrow-icon"
                            icon={faHouse}
                        />
                    </div>
                </div>
                <div className="navigation-icon-after-outside">
                    <div
                        onClick={() =>
                            navigate("/company-settings/settings-and-defaults")
                        }
                        className="navigation-icon-after"
                    >
                        Settings
                    </div>
                </div>
                <div className="navigation-icon-after third-arrow">
                    <p className="nav-text">Company Settings</p>
                </div>
            </div>
            <div className="cs-container">
                <div className="cs-form-container cs-form-container-padding">
                    <div className="ps-modal-title cs-mb-18">
                        Company Settings
                    </div>
                    <form
                        onSubmit={handleSubmit(updateCompany)}
                        className="cs-form-spaner"
                    >
                        <div className="cs-form-label">Organization Name</div>
                        <div className="cs-gap-handler">
                            <input
                                type="text"
                                className="cs-form-input"
                                name="name"
                                {...register("name")}
                            />
                            <div className="cs-form-label">Invoice Email</div>
                        </div>
                        <div className="cs-gap-handler">
                            <input
                                type="text"
                                className="cs-form-input"
                                name="invoice_email"
                                {...register("invoice_email")}
                            />
                            <div className="cs-form-label">
                                Name Of Contact Person
                            </div>
                        </div>
                        <div className="cs-gap-handler">
                            <input
                                type="text"
                                className="cs-form-input"
                                name="contact_person"
                                {...register("contact_person")}
                            />
                            <div className="cs-form-label">VAT Number</div>
                        </div>
                        <div className="cs-gap-handler">
                            <input
                                type="text"
                                className="cs-form-input"
                                name="vat"
                                {...register("vat")}
                            />
                            <div className="cs-form-label">Invoice Address</div>
                        </div>
                        <div className="cs-gap-handler">
                            <input
                                type="text"
                                className="cs-form-input cs-h-80"
                                {...register("invoice_address")}
                                name="invoice_address"
                            />
                            <div className="cs-form-label">Company Logo</div>
                        </div>
                        <img
                            className="cs-logo"
                            src={preview ? preview : logoPH}
                        />
                        <div className="pphoto-buttons-container cs-s-buttons-container">
                            <label
                                htmlFor="c-logo"
                                className="pphoto-button pphoto-change"
                            >
                                Change
                            </label>
                            <input
                                type="file"
                                onChange={setNewLogo}
                                id="c-logo"
                                style={{ width: 0, height: 0 }}
                            />
                            <div
                                onClick={deleteLogo}
                                className="pphoto-button pphoto-delete"
                            >
                                Delete
                            </div>
                        </div>
                        <button className="cs_submit_button" type="submit">
                            Save
                        </button>
                    </form>
                </div>
                {/* <div className='cs-payment-main-container'>
                            <div className='cs-payment-container cs-payment-top-container-padding'>
                                <div className='ps-modal-title cs-mb-8'>
                                    Your Current Plan
                                </div>
                                <div className='cs-payment-type-container'>
                                    <div className='cs-payment-type'>Platinum</div>
                                    <div className='pphoto-button pphoto-change cs-w-100-ml-80'>Change Plan</div>
                                </div>
                                <CompanySettingsAttribute kw='cs-w-100' class='cs-color-4f4f4f' ky='Since' value='DD/MM/YYYY' />
                                <CompanySettingsAttribute kw='cs-w-100' ws='cs-ws' class='cs-color-4f4f4f cs-mt-24' ky='Details' value='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.' />
                                <div className='cs-invoice-time-container'>
                                    <CompanySettingsAttribute kw='cs-w-100' class='cs-color-4f4f4f  cs-m-0' ky='Last Invoice' value='DD/MM/YYYY' />
                                    <CompanySettingsAttribute kw='cs-w-100' ws='cs-ws' class='cs-color-4f4f4f cs-m-0' ky='Next Invoice' value='DD/MM/YYYY' />
                                </div>
                                <div className='invoice-history-link-container'>Invoice History</div>
                            </div>
                            <div className='cs-payment-container cs-payment-top-container-padding'>
                                <div className='ps-modal-title cs-mb-18'>
                                    Payment Method Information
                                </div>
                                <div className='cs-form-label'>Name On Card</div>
                                <div className='cs-gap-handler'>
                                    <input type='text' className='cs-form-input' />
                                    <div className='cs-form-label'>Card Number</div>
                                </div>
                                <div className='cs-gap-handler'>
                                    <input type='text' className='cs-form-input' />
                                    <div className='cs-form-label'>Expiry</div>
                                </div>
                                <div className='cs-card-expiry-container'>
                                <div className='css-select-container'>
                                    <div onClick={() => toggleSelect('cs-so-1', 'tsa')} className='filter_select'>Month
                                        <img className='transition' style={{transform: 'rotate(0)'}} id='tsa' alt='' src={toggler_arrow} />
                                    </div>
                                    <div id='cs-so-1' style={{display: 'none', position: 'relative', bottom: '390px'}} className='options-container'>
                                        <div onClick={() => chooseType('Show all statuses')} className='option option-top'>01</div>
                                        <div onClick={() => chooseType('Live')} className='option'>02</div>
                                        <div onClick={() => chooseType('Disabled')} className='option'>03</div>
                                        <div onClick={() => chooseType('Show all statuses')} className='option'>04</div>
                                        <div onClick={() => chooseType('Live')} className='option'>05</div>
                                        <div onClick={() => chooseType('Disabled')} className='option'>06</div>
                                        <div onClick={() => chooseType('Show all statuses')} className='option'>07</div>
                                        <div onClick={() => chooseType('Live')} className='option'>08</div>
                                        <div onClick={() => chooseType('Disabled')} className='option'>09</div>
                                        <div onClick={() => chooseType('Show all statuses')} className='option'>10</div>
                                        <div onClick={() => chooseType('Live')} className='option'>11</div>
                                        <div onClick={() => chooseType('Disabled')} className='option option-bottom'>12</div>
                                    </div>
                                </div>
                                <div className='css-select-container'>
                                    <div  onClick={() => toggleSelect('cs-so-2', 'psa')} className='filter_select'>Year
                                        <img className='transition' id='psa' style={{transform: 'rotate(0)'}} alt='' src={toggler_arrow} />
                                    </div>
                                    <div id='cs-so-2' style={{display: 'none', position: 'relative', bottom: '180px'}} className='options-container'>
                                        <div onClick={() => choosePanel('Show all modules')} className='option option-top'>22</div>
                                        <div onClick={() => choosePanel('Users')} className='option'>23</div>
                                        <div onClick={() => choosePanel('Panels')} className='option'>24</div>
                                        <div onClick={() => choosePanel('Projects')} className='option'>25</div>
                                        <div onClick={() => choosePanel('Proposals')} className='option option-bottom'>26</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div> */}
            </div>
        </Wrapper>
    );
};
