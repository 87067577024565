/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as ProfileIcon } from "../ui/styles/profile.svg";
import { ReactComponent as MenuIcon } from "../ui/styles/menu.svg";
import Button from "../ui/Button";
import Drawer from "../ui/Drawer";
import { SideAccountSettingsMenu, SideMenu } from "../../constant/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import PersonalSetting from "../model/personalSetting";
import Modal from "../ui/Modal";
import { useEffect } from "react";
import { fetchAllUsers } from "../../redux/features/UserManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
const SiteHeader = styled.header`
    display: flex;
    flex-direction: revert;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    box-shadow: none;
    border-bottom: 1px solid #eee;
    padding: 12px 25px;
    min-height: 58px;
    margin: 0;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    z-index: 11;
`;
const SiteHeaderLogo = styled.div`
    display: flex;
    align-items: center;
`;
const SiteHeaderProfile = styled.div`
    display: flex;
    align-items: center;
`;
const SiteHeaderLogoText = styled.h6`
    color: #ed8154;
    margin: 0;
    margin-left: 25px;
    font-size: 20px !important;
    font-weight: 500 !important;
`;
const ProfileBox = styled.div`
    position: relative;
`;
const ProfileBoxDropDown = styled.div`
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    background: #fff;
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
    min-width: 200px;
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li span {
            padding: 8px 10px;
            display: block;
            background: #fff;
            transition: all 0.2s ease;
            font-size: 14px;
            font-weight: 500;
            color: #666;
            cursor: pointer;
            border-radius: 10px;
            &:hover {
                background: #eee;
                color: #ed8154;
            }
            a {
                text-decoration: none;
            }
        }
    }
`;

const CommonHeader = () => {
    const navigation = useNavigate();

    const [permissions, setPermissions] = useState(
        JSON.parse(localStorage.getItem("permissions"))
    );

    const [projectsPermissions, setProjectsPermissions] = useState(false);
    const [surveysPermissions, setSurveysPermissions] = useState(false);
    const [schedulerPermissions, setSchedulerPermissions] = useState(false);
    const [panelsPermissions, setPanelsPermissions] = useState(false);
    const [contactsPermissions, setContactsPermissions] = useState(false);
    const [defaultsPermissions, setDefaultsPermissions] = useState(false);
    const [settingsPermissions, setSettingsPermissions] = useState(false);

    useEffect(() => {
        setProjectsPermissions(
            permissions?.some((obj) => obj.groupName === "projects")
        );
        setSurveysPermissions(
            permissions?.some((obj) => obj.groupName === "survey_designer")
        );
        setSchedulerPermissions(
            permissions?.some((obj) => obj.groupName === "scheduler")
        );
        setPanelsPermissions(
            permissions?.some((obj) => obj.groupName === "panels")
        );
        setContactsPermissions(
            permissions?.some((obj) => obj.groupName === "contacts")
        );
        setDefaultsPermissions(
            permissions?.some((obj) => obj.groupName === "defaults")
        );
        setSettingsPermissions(
            permissions?.some((obj) => obj.groupName === "settings")
        );
    }, []);

    const location = useLocation();
    const dispatch = useDispatch();

    const activeUser = JSON.parse(localStorage.getItem("user"));
    // const allUserData = useSelector((state) => state.Users.allUserData);
    const [activeTab, setActiveTab] = useState(location.pathname);
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const [personalSettingModal, setPersonalSettingModal] = useState(false);
    const navigate = useNavigate();

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowProfileDropdown(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    const handleLogout = () => {
        localStorage.clear();
        navigation("/login");
    };
    const MenuList = () => {
        const handleSelectTab = (path) => {
            navigation(path);
            setActiveTab(path);
        };
        return (
            <>
                {SideMenu.map((data, index) => {
                    const SelectedTab = data.path === activeTab;
                    return (
                        <div
                            key={index}
                            className={
                                SelectedTab
                                    ? "sidebar__list-item active"
                                    : "sidebar__list-item"
                            }
                            onClick={() => handleSelectTab(data.path)}
                        >
                            <span className="sidebar__list-image">
                                <data.svgIcons />
                            </span>
                            <h6 className="sidebar__list-text">{data.text}</h6>
                        </div>
                    );
                })}
                <div className="sidebar__list-item-inner">
                    <h4 className="innerMenu-title">Account Settings</h4>
                </div>
                {SideAccountSettingsMenu.map((data, index) => {
                    const SelectedTab = data.path === activeTab;

                    return (
                        <div
                            key={index}
                            className={
                                SelectedTab
                                    ? "sidebar__list-item active"
                                    : "sidebar__list-item"
                            }
                            onClick={() => handleSelectTab(data.path)}
                        >
                            <span className="sidebar__list-image">
                                <data.svgIcons />
                            </span>
                            <h6 className="sidebar__list-text">{data.text}</h6>
                        </div>
                    );
                })}
            </>
        );
    };
    return (
        <React.Fragment>
            <SiteHeader>
                <SiteHeaderLogo>
                    {location.pathname !== "/home"
                        ? location.pathname !== "/" && (
                              <Button
                                  iconTransparent={
                                      <MenuIcon
                                          height={"24px"}
                                          width={"24px"}
                                      />
                                  }
                              />
                          )
                        : ""}
                    <SiteHeaderLogoText>
                        Q ONE <span style={{ color: "#666" }}>TECH</span>
                    </SiteHeaderLogoText>
                </SiteHeaderLogo>
                {location.pathname !== "/home"
                    ? location.pathname !== "/" && (
                          <Drawer
                              isVisible={true}
                              childrenClasses="sidebar__list"
                              left
                          >
                              <MenuList />
                          </Drawer>
                      )
                    : ""}
                <ProfileBox>
                    <SiteHeaderProfile
                        onClick={() =>
                            setShowProfileDropdown(!showProfileDropdown)
                        }
                    >
                        <ProfileIcon height={"30px"} width={"30px"} />
                    </SiteHeaderProfile>
                    {showProfileDropdown && (
                        <ProfileBoxDropDown
                            ref={showProfileDropdown ? wrapperRef : ""}
                        >
                            <ul>
                                <h5>{activeUser?.name}</h5>
                                <li>
                                    <span
                                        onClick={() =>
                                            setPersonalSettingModal(true)
                                        }
                                    >
                                        Personal Settings
                                    </span>
                                </li>
                                <h5>Modules</h5>
                                <li>
                                    <a href="/home">
                                        <span>Home</span>
                                    </a>
                                </li>
                                {projectsPermissions && (
                                    <li>
                                        <a href="/panel-manager/projects">
                                            <span>Projects</span>
                                        </a>
                                    </li>
                                )}
                                {surveysPermissions && (
                                    <li>
                                        <a href="/survey-designer">
                                            <span>Surveys</span>
                                        </a>
                                    </li>
                                )}
                                {panelsPermissions && (
                                    <li>
                                        <a href="/panel-manager/overview">
                                            <span>Panels</span>
                                        </a>
                                    </li>
                                )}
                                {contactsPermissions && (
                                    <li>
                                        <a href="/contact-manager">
                                            <span>Contacts</span>
                                        </a>
                                    </li>
                                )}
                                <h5>System</h5>
                                {defaultsPermissions && (
                                    <li>
                                        <a href="/content-manager">
                                            <span>Defaults</span>
                                        </a>
                                    </li>
                                )}
                                {settingsPermissions && (
                                    <li>
                                        <a href="/company-settings/overview">
                                            <span>Settings</span>
                                        </a>
                                    </li>
                                )}
                                <li>
                                    <span onClick={handleLogout}>Logout</span>
                                </li>
                            </ul>
                        </ProfileBoxDropDown>
                    )}
                </ProfileBox>
            </SiteHeader>
            <Modal show={personalSettingModal}>
                <PersonalSetting
                    setPersonalSettingModal={setPersonalSettingModal}
                />
            </Modal>
        </React.Fragment>
    );
};

export default CommonHeader;
