/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { ReactComponent as Close } from "../../ui/styles/Icon_Close.svg";
import { SearchDropDown } from "../../ui/Dropdown";
import styled from "styled-components";
import { AssignPermissionGroup } from "../../../constant/usermanagement";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllUsers,
  updateProfile,
} from "../../../redux/features/UserManagementSlice";

const ExportModalCard = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
  // overflow: hidden;
  width: 750px;
  padding: 30px;
  box-sizing: border-box;
  .modelTitle {
    font-size: 16px !important;
    color: #666 !important;
    padding: 0;
    text-align: left;
    margin: 0 0 35px;
    font-weight: 700 !important;
  }
  .orangeBtn {
    font-size: 14px;
    color: #fff;
    background: #ed8154;
    border: 1px solid #ed8154;
    border-radius: 8px;
    overflow: hidden;
    padding: 8px 20px;
    font-weight: 500;
    margin: 0;
    min-width: 220px;
  }
`;
const ModalCardBody = styled.div`
  margin: 0;
  list-style-type: none;
  background: #fff;
  ul {
    padding: 0;
    list-style-type: none;
    text-align: left;
    margin: 10px 0;
    li {
      font-size: 14px;
      font-weight: 400;
      color: #999;
      line-height: 20px;
      span {
        font-weight: 700;
      }
    }
  }
`;

const ModalCardHead = styled.div`
  border-bottom: 1px solid #ed8154;
  background: #ed8154;
  padding: 8px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ​ h6 {
    font-size: 16px !important;
    color: #fff !important;
    margin: 0;
    font-weight: 600;
  }
  button {
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ExportModalCardForm = styled.div`
  width: 100%;
  input {
    width: 100%;
    border: 1px solid #ddd;
    min-height: 40px;
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 8px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  label {
    font-size: 14px !important;
    color: #666 !important;
    margin: 0;
    width: 100%;
    text-align: left;
    display: block;
    margin-bottom: 6px;
    font-weight: 700;
  }
`;

const InputBox = styled.div`
  margin-bottom: 20px;
  & > div > div {
    border-color: #ddd;
  }
`;

const ProfileData = styled.div`
  display: grid;
  grid-template-columns: auto 170px;
  gap: 60px;
`;

const UploadProfile = styled.div`
  text-align: center;
  img {
    width: 165px;
    height: 165px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #eee;
    margin: 0 auto;
  }
  .cs-s-buttons-container {
    justify-content: center;
    gap: 5px;
  }
`;

const ExportModalCardButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 22px;
  button {
    font-size: 14px;
    color: #fff;
    background: #ed8154;
    border: 1px solid #ed8154;
    border-radius: 8px;
    overflow: hidden;
    padding: 8px 20px;
    min-width: 95px;
    margin-left: 12px;
    font-weight: 600;
    &:first-of-type {
      margin-left: 0;
      background: #fff;
      color: #ed8154;
    }
  }
`;

const PersonalSetting = (props) => {
  const { setPersonalSettingModal } = props;
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user"));
  const allUserData = useSelector((state) => state.Users.allUserData);
  const initialValues = {
    name: userData.name || "",
    email: userData.email || "",
    phone: userData.phone || "",
  };
  useEffect(() => {
    dispatch(fetchAllUsers());
  }, []);
  const activeUser = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  
  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid Email";
    }

    if (!values.name) {
      errors.name = "name is required";
    }
    if (!values.phone) {
      errors.phone = "phone is required";
    }

    return errors;
  };
  // const dispatch = useDispatch();
  const submitForm = (values) => {
    var data = {};

    allUserData.map((elem) => {
      if (elem._id === userData._id) {
        data = {
          ...elem,
          name: values.name,
          email: values.email,
          phone: values.phone,
        };
      }
    });
    dispatch(updateProfile(data)).then((res) => {
      if (res.payload.response.error) {
        alert(res.payload.response.error);
      } else {
        setPersonalSettingModal(false);
      }
    });
    // const data = {
    //   email: values.email,
    //   name: values.name,
    //   permissionGroup: permissionGroup,
    //   link: `http://localhost:5115/company-settings/password/${values.email}`,
    // };
    // console.log(data.link, "tsetoing");
  };
  return (
    <React.Fragment>
      <ExportModalCard>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={submitForm}
        >
          {(formik) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              isValid,
              dirty,
            } = formik;
            return (
              <form onSubmit={handleSubmit}>
                <h6 className="modelTitle">Personal Settings</h6>

                <ModalCardBody>
                  <ProfileData>
                    <ExportModalCardForm>
                      <InputBox>
                        <label> Your Name</label>
                        <Input
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="name"
                          id="name"
                          required={true}
                          type="text"
                          className={
                            errors.name && touched.name ? "input-error" : null
                          }
                          placeholder="Enter your name"
                        />
                        {errors.name && (
                          <span className="errorText">{errors.name}</span>
                        )}
                      </InputBox>
                      <InputBox>
                        <label> Your Email</label>
                        <Input
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                          id="email"
                          required={true}
                          type="text"
                          placeholder="Enter your email"
                          className={
                            errors.email && touched.email ? "input-error" : null
                          }
                        />
                        {errors.email && (
                          <span className="errorText">{errors.email}</span>
                        )}
                      </InputBox>
                      <InputBox>
                        <label>Your Mobile</label>
                        <Input
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="phone"
                          id="phone"
                          required={true}
                          type="number"
                          placeholder="Enter your phone"
                          className={
                            errors.phone && touched.phone ? "input-error" : null
                          }
                        />
                        {errors.phone && (
                          <span className="errorText">{errors.phone}</span>
                        )}
                      </InputBox>
                      <InputBox>
                        <label>Your Password</label>
                        <Button
                          className="orangeBtn"
                          type="button"
                          onClick={() => {
                            setPersonalSettingModal(false);
                            navigate(`/reset-password/${activeUser._id}`);
                          }}
                        >
                          Change Password
                        </Button>
                      </InputBox>
                    </ExportModalCardForm>
                    {/* <UploadProfile>
                      <img className="cs-logo" />
                      <div className="pphoto-buttons-container cs-s-buttons-container">
                        <label
                          htmlFor="c-logo"
                          className="pphoto-button pphoto-change"
                        >
                          Change
                        </label>
                        <input
                          type="file"
                          id="c-logo"
                          style={{ width: 0, height: 0 }}
                        />
                        <div className="pphoto-button pphoto-delete">
                          Delete
                        </div>
                      </div>
                    </UploadProfile> */}
                  </ProfileData>
                  <ExportModalCardButtons>
                    <Button onClick={() => setPersonalSettingModal(false)}>
                      Close
                    </Button>
                    <Button
                      type="submit"
                      // className={
                      //   !(dirty && isValid) ? "disabled-btn" : "sb-button"
                      // }
                      disabled={!(dirty && isValid)}
                      id="b-login"
                    >
                      Save
                    </Button>
                  </ExportModalCardButtons>
                </ModalCardBody>
              </form>
            );
          }}
        </Formik>
      </ExportModalCard>
    </React.Fragment>
  );
};

export default PersonalSetting;
