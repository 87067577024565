import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Card from "../../components/card";

const Wrapper = styled.section`
  min-height: calc(100vh - 58px);
  margin-left: 250px;
  padding: 30px;
  box-sizing: border-box;
`;

const CardWrapper = styled.div`
  display: Grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  .smallTitle {
    font-size: 16px !important;
    color: #666 !important;
    padding: 0;
    text-align: left;
    margin: 0 0 25px;
    font-weight: 700 !important;
  }
  & > div {
    min-height: 250px;
  }
`;

const CountingBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  li {
    text-align: center;
    h1 {
      margin: 5px 0;
      font-size: 22px;
    }
  }
`;

const CountingBlockTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  li {
    text-align: center;
    h1 {
      margin: 5px 0;
      font-size: 22px;
    }
  }
`;
const ProjectBlock = styled.div`
  .smallTitle {
    font-size: 16px !important;
    color: #666 !important;
    padding: 0;
    text-align: left;
    margin: 0 0 25px;
    font-weight: 700 !important;
  }
`;

const Overview = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Wrapper>
        <ProjectBlock>
          <h4 className="smallTitle">Account Settings</h4>
        </ProjectBlock>
        <CardWrapper>
          <Card
            style={{ minHeight: "260px" }}
            title="User Management"
            buttonName="Edit"
            onClick={() =>
              navigate(`/company-settings/user-management-dashboard`)
            }
            paragraph="User management allows administrators to easily create, manage, and revoke user accounts, ensuring secure access and streamlined control over the Q One systems usage."
          >
            {/* <CountingBlock>
              <li></li>
              <li>
                <h1>4</h1> Active User
              </li>
              <li>
                <h1>0</h1> Inactive Account
              </li>
              <li></li>
            </CountingBlock> */}
          </Card>
          <Card
            title="System Permission"
            buttonName="Edit"
            onClick={() => navigate(`/company-settings/permission-group`)}
            paragraph="Our permission management system provides granular control over user access to specific software features, ensuring authorized access and data security."
          >
            {/* <CountingBlockTwo>
              <li>
                <h1>4</h1> Permission Group
              </li>
            </CountingBlockTwo> */}
          </Card>
          <Card
            title="Company Details"
            buttonName="Company Details"
            paragraph="Add and edit your company and invoicing details in this section."
          >
            {/* <li>
              <span>Organization:</span>[COMPANY NAME]
            </li>
            <li>
              <span>Your Plan:</span>[PLAN NAME]
            </li>
            <li>
              <span>Invoice Name :</span>[INVOICE EMAIL]
            </li>
            <li>
              <span>Last Invoice :</span>[DD/MM/YYYY]
            </li>
            <li>
              <span>Next Invoice :</span>[DD/MM/YYYY]
            </li> */}
          </Card>
          {/* <Card
            title="Communication API Management"
            buttonName="Edit"
            // onClick={() => navigate(`/company-settings/permission-group`)}
            paragraph="The Communication API Management system allows users to easily manage and secure connections to external email and SMS relay vendors, ensuring efficient and reliable data transfer."
          >
            <CountingBlockTwo>
              <li>
                <h1>5</h1> Integration
              </li>
            </CountingBlockTwo>
          </Card> */}
          <Card
            title="Reward API Management"
            buttonName="Edit"
            // onClick={() => navigate(`/company-settings/permission-group`)}
            paragraph="The Reward API Management system allows users to connect to various Incentive vendors via their API systems."
          >
            {/* <CountingBlockTwo>
              <li>
                <h1>2</h1> Integration
              </li>
            </CountingBlockTwo> */}
          </Card>
          <Card
            title="Security Systems API Management"
            buttonName="Edit"
            // onClick={() => navigate(`/company-settings/permission-group`)}
            paragraph="In this module you can manage the API integrations of security system API services such as Research Defender."
          ></Card>
          <Card
            title="Procedure & Policy Center"
            buttonName="Visit"
            onClick={() => navigate(`/company-settings/legal-and-security`)}
            paragraph=" In this environment, you will find links to all of our systems policies and relevant security documentation, which outline our development and security practices and procedures. Our policies are designed to be transparent and provide you with the information you need to make informed decisions about how your information is used."
          >
            {/* <CountingBlockTwo>
              <li>
                <h1></h1> Data Processing Agreement
              </li>
            </CountingBlockTwo> */}
          </Card>
        </CardWrapper>
      </Wrapper>
    </React.Fragment>
  );
};

export default Overview;
