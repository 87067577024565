import React from 'react';
import '../style/defaults/topbar.css';
import { Hamburger } from './Hamburger';
import profileIcon from '../assets/images/Icon_Profile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

export const Topbar = () => {

    var me = useSelector(state => state.me)

    const toggleProfileMenu = () => {
        var pmenu = document.getElementById('profile-menu');
        if(pmenu.style.maxHeight !== '0px') {
            pmenu.style.maxHeight = 0;
            pmenu.style.border = 0
        } else {
            pmenu.style.maxHeight = '369px';
            pmenu.style.border = '1px solid #E0E0E0';
        }
    }

    return(
        <div className='defaults_topbar_container'>
            <div className='defaults_topbar_left_side'>
                <Hamburger />
                <a href=''>
                    <div className='defaults_topbar_logo'>
                        <div className='defaults_topbar_logo_orange'>Q ONE</div>
                        <div className='defaults_topbar_logo_dark'>TECH</div>
                    </div>
                </a>
            </div>
            <img onClick={() => toggleProfileMenu()} alt='' className='defaults_topbar_profile_icon' src={profileIcon} />
            <div id='profile-menu' style={{maxHeight: '0px'}} className='defaults_profile_menu'>

                <div className='defaults_profile_menu_title ac_mt_10'>{me.firstName + ' ' + me.lastName}</div>
                <a className='defaults_profile_menu_link' href=''>Personal settings</a>
                <div className='default_sidebar_border'></div>
                
                <div className='defaults_profile_menu_title'>Pages</div>
                <a className='defaults_profile_menu_link' href=''>Event Management</a>
                <a className='defaults_profile_menu_link' href=''>Survey Designer</a>
                <a className='defaults_profile_menu_link' href=''>Q One Platform</a>
                <a className='defaults_profile_menu_link' href=''>Settings & Defaults</a>
                <div className='default_sidebar_border'></div>

                <div className='defaults_profile_menu_title'>Help</div>
                <a className='defaults_profile_menu_link' href=''>Tutorials</a>
                <a className='defaults_profile_menu_link' href=''>FAQ</a>
                <a className='defaults_profile_menu_link' href=''>Contact Support</a>
                <div className='default_sidebar_border'></div>

                <div className='sidebar_menu_item logout_button ac_mb_12'>
                    <FontAwesomeIcon className='sidebar_menu_icon' icon={faPowerOff} />
                    <a href='/' onClick={() => localStorage.clear()} className='logout_link'>
                        Log Out
                    </a>
                </div>

            </div>
        </div>
    )
}