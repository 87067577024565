import Modal from "../../layouts/Modal";
import download from "../../assets/Path 77.png"

export const UploadModal = () => {
    return(
        <Modal id='upload-language-modal' title='Upload Translation Sheet'>
            <div className='upload-language-modal-description'>
                Download the current translations for this event in .xlxs format. Then translate the .xlxs file, select it from your computer and upload it.
            </div>
            <div className='language-modal-buttons-container upload-language-modal-buttons-container'>
                <button onClick={() => document.getElementById('upload-language-modal').style.display = 'none'} className='language-modal-button modal-upload-lng-button color-ed8154 bg-color-f2f2f2'>Cancel</button>
                <button className='language-modal-button modal-upload-lng-button color-ffffff bg-color-4f4f4f'><img alt='' src={download} /><div>Download Current</div></button>
                <button className='language-modal-button modal-upload-lng-button color-ffffff bg-color-4f4f4f'>+ Select File</button>
                <button className='language-modal-button modal-upload-lng-button color-ffffff bg-color-ed8154'>Upload Translation</button>
            </div>
        </Modal>
    )
}