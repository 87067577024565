import { Link } from "react-router-dom";
import { AddIf } from "./util";

export const BreadcrumbLink = (props) => {
  const { children, to } = props;
  return (
    <li>
      <Link className="ui-breadcrumb__item" to={to ?? ""}>
        {children}
      </Link>
    </li>
  );
};

export const BreadcrumbItem = (props) => {
  const { children, onClick } = props;
  return (
    <>
      <div className="ui-breadcrumb__item-link" onClick={onClick}>
        <span>{children}</span>
      </div>
    </>
  );
};

const Breadcrumb = (props) => {
  const { children, primary } = props;
  let classes = "ui-button";
  classes = AddIf(primary, classes, "ui-breadcrumb");
  return (
    <div className={classes}>
      <ul>{children}</ul>
    </div>
  );
};

export default Breadcrumb;
