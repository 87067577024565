import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import "../../style/user_management/general.css";
import "../../css/login.css";
import "../../";
import FormText from "../../components/formtext";
import { Formik } from "formik";
import {
    fetchAllInvitedUsers,
    registerUser,
} from "../../redux/features/UserManagementSlice";
import { useDispatch } from "react-redux";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLock, faShield } from '@fortawesome/free-solid-svg-icons';
// import { enterCompanies, enterCurCompany, enterId, enterMe } from '../rootSlice';

const Password = () => {
    const [searchParams] = useSearchParams();
    const utoken = searchParams.get("utoken");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(fetchAllInvitedUsers());
    }, [dispatch]);
    const initialValues = {
        password: "",
        confirm_password: "",
    };

    const validate = (values) => {
        let errors = {};
        // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 4) {
            errors.password = "Password too short";
        }
        if (!values.confirm_password) {
            errors.confirm_password = "Password is required";
        } else if (values.password !== values.confirm_password) {
            errors.confirm_password = "Confirm Password Does Not Match";
        }

        return errors;
    };
    const submitForm = (values) => {
        // const userValue = allUserData.find((elem) => elem.email === email);
        if (values.password === values.confirm_password) {
            const data = {
                utoken: utoken,
                password: values.password,
            };
            dispatch(registerUser(data)).then((res) => {
                if (res?.payload?.isSuccess) {
                    // localStorage.setItem(
                    //   "user",
                    //   JSON.stringify({
                    //     ...res?.payload?.data?.user,
                    //     password: null,
                    //   })
                    // );
                    // localStorage.setItem("userEmail", res?.payload?.data?.user.email);
                    window.location.href = "/login";
                } else {
                    return alert(res.payload.error);
                }
            });
        }
    };

    return (
        <div className="login__page">
            <div className="test_class">
                <FormText />
                <Formik
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={submitForm}
                >
                    {(formik) => {
                        const {
                            values,
                            handleChange,
                            handleSubmit,
                            errors,
                            touched,
                            handleBlur,
                            isValid,
                            dirty,
                        } = formik;
                        return (
                            <div className="login__page-form">
                                <h4>Create Password</h4>
                                <form onSubmit={handleSubmit}>
                                    <input
                                        name="password"
                                        id="password"
                                        placeholder="Create password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required={true}
                                        type="password"
                                        className={
                                            errors.password && touched.password
                                                ? "input-error"
                                                : null
                                        }
                                    />
                                    {errors.password && (
                                        <span className="errorText">
                                            {errors.password}
                                        </span>
                                    )}
                                    <input
                                        name="confirm_password"
                                        id="confirm_password"
                                        placeholder="Confirm password"
                                        value={values.confirm_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required={true}
                                        type="password"
                                        className={
                                            errors.confirm_password &&
                                            touched.confirm_password
                                                ? "input-error"
                                                : null
                                        }
                                    />
                                    {errors.confirm_password && (
                                        <span className="errorText">
                                            {errors.confirm_password}
                                        </span>
                                    )}
                                    <button
                                        type="submit"
                                        // className={
                                        //   !(dirty && isValid) ? "disabled-btn" : "sb-button"
                                        // }
                                        disabled={!(dirty && isValid)}
                                    >
                                        Confirm
                                    </button>
                                </form>
                            </div>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default Password;
