import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;

const BearerToken = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${BearerToken}`,
    // Permission: `Company Settings`,
  },
};
export async function fetchPermissionGroup() {
  try {
    const { data } = await axios.get(`${baseURL}/permission-groups`, config);
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function createPermissionGroup(addPermissionGroup) {
  try {
    const { data } = await axios.post(
      `${baseURL}/permission-groups`,
      { ...addPermissionGroup },
      config
    );
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function fetchAllPermission() {
  try {
    const { data } = await axios.get(`${baseURL}/permissions`, config);
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function editPermissionGroup(editData) {
  try {
    const { data } = await axios.put(
      `${baseURL}/permission-groups/${editData?._id}`,
      {
        ...editData,
      },
      config
    );
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function deletePermissionGroup(id) {
  try {
    const { data } = await axios.delete(
      `${baseURL}/permission-groups/${id}`,
      config
    );
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

const PermissionService = {
  fetchPermissionGroup,
  fetchAllPermission,
  createPermissionGroup,
  editPermissionGroup,
  deletePermissionGroup,
};

export default PermissionService;
