import { forwardRef } from "react";
import { AddIf } from "./util";

const Input = forwardRef((props, ref) => {
  const { className, onChange, value, placeholder,name } = props;

  let classes = "ui-button";
  classes = AddIf(className, classes, className);

  return (
    <>
      <div>
        <input
          value={value}
          onChange={true?onChange:undefined}
          type="text"
          className={classes.input}
          placeholder={placeholder}
          name={name}
        />
      </div>
    </>
  );
});

export default Input;
