import React from "react";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { useForm } from 'react-hook-form';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faShield } from '@fortawesome/free-solid-svg-icons';
// import { faLock } from '@fortawesome/free-solid-svg-icons';
// import logo from "../assets/panel_logo.svg";
// import axios from "axios";
import "../../css/login.css";
import FormText from "../../components/formtext";
/*import { useEffect } from 'react';
import { enterMe } from '../rootSlice';*/

const Register = () => {
  // const navigate = useNavigate();
  // const username = useSelector(state => state.username);
  // const password = useSelector(state => state.password);
  // const passrep = useSelector(state => state.passrep);
  // const { register, handleSubmit} = useForm({defaultValues:{username, password, passrep}})

  // const onSubmit = (data) => {

  //     if(data.passrep == data.password) {
  //         const body = {
  //             password: data.password,
  //             userName: data.username
  //         }

  //         document.getElementById('b-register').disabled = true;

  //         var url = window.location.href;
  //         var urlArray = url.split('/');

  //         axios.put(`${process.env.REACT_APP_URL}/users/register/${urlArray.slice(-1)}`, body, { headers: {
  //             'Content-type': 'application/json'
  //         }})
  //         .then(res => {
  //             document.getElementById('b-register').disabled = false;
  //             navigate('/');
  //         }).catch(() => {
  //             alert("Error");
  //             document.getElementById('b-register').disabled = false;
  //         })
  //     } else {
  //         alert('Passwords must match')
  //     }
  // }

  return (
    <div className="login__page">
      <div className="test_class">
        <FormText />
        <div className="login__page-form">
          <h4>Register account</h4>
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
          {/* <span className='fa-icon'><FontAwesomeIcon icon={faShield} /></span> */}
          <input
            // name='username' {...register('username')}
            required={true}
            type="text"
            placeholder="Create username"
          />
          {/* <span className='fa-icon'><FontAwesomeIcon icon={faLock} /></span> */}
          <input
            // name='password' {...register('password')}
            required={true}
            type="password"
            placeholder="Create password"
          />
          {/* <span className='fa-icon'><FontAwesomeIcon icon={faLock} /></span> */}
          <input
            // name='passrep' {...register('passrep')}
            required={true}
            type="password"
            placeholder="Confirm password"
          />
          <button id="b-register" className="sb-button" type="submit">
            Create
          </button>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default Register;
