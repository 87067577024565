import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as HomeIcon } from "../../components/ui/styles/home.svg";
import { ReactComponent as Plus } from "../../components/ui/styles/plus_icon.svg";
import Breadcrumb, {
    BreadcrumbLink,
    BreadcrumbItem,
} from "../../components/ui/Breadcrumb";
import Button from "../../components/ui/Button";
// import PermissionGroupDataTable from "../../components/permissiongroupdatatable";
import Modal from "../../components/ui/Modal";
import CreateNewPermissionGroupModal from "../../components/model/createNewPermissionGroupModal";
import EditPermissionGroupModal from "../../components/model/editPermissionGroupModal";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Edit } from "../../components/ui/styles/editIcon.svg";
import { ReactComponent as Delete } from "../../components/ui/styles/icon_Trash.svg";
import { ReactComponent as Setting } from "../../components/ui/styles/Icon_Settings.svg";
import {
    deletePermissionGroup,
    fetchAllPermission,
    fetchPermissionGroup,
} from "../../redux/features/PermissionSlice";

const Wrapper = styled.section`
    min-height: calc(100vh - 58px);
    margin-left: 250px;
    padding: 30px;
    box-sizing: border-box;
`;
const WrapperHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
`;
const WrapperHeadBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        background: #ed8154;
        border: 1px solid #ed8154;
        font-size: 14px !important;
        color: #fff;
        text-transform: capitalize;
        min-width: 225px;
        height: 42px;
        font-weight: 700;
        border-radius: 12px;

        // &:hover {
        //   background: #fff;
        //   color: #ed8154;
        // }
    }

    .iconButton {
        min-width: 42px;
        margin-right: 15px;

        svg {
            height: 18px;
            width: 18px;
        }
        // &:hover {
        //   svg {
        //     color: #ed8154;
        //     fill: #ed8154;
        //     path {
        //       fill: #ed8154;
        //     }
        //   }
        // }
    }
`;

const PermissionTableBody = styled.div`
  padding: 0;
  margin: 0;
  list-style-type: none;
  background: #fff;
  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid #ed8154;
    }
    tbody tr:last-child {
      border-bottom: none;
    }

    td,
    th {
      border-right: 1px solid #ed8154;
      padding: 8px 15px;
      font-size: 14px;
      color: #999;
      line-height: 22px;
      text-align: left;
      svg,
      img {
        height: 22px;
        width: 22px;
      }
      }
    }
  }
`;
const PermissionTable = styled.div`
    background: #fff;
    border-radius: 0;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
    overflow: hidden;
    border: 1px solid #ed8154;
    max-height: calc(100vh - 195px);
    overflow: auto;
`;

const ListActionBtn = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    button {
        height: 22px;
        width: 22px;
        background: #fff;
        border: 1px solid #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px;
        cursor: pointer !important;
        svg path {
            fill: #ed8154;
        }
        // &:hover {
        //   border-color: #ed8154;
        // }
        &:last-child {
            svg path {
                fill: #982727;
            }
            // &:hover {
            //   border-color: #982727;
            // }
        }
    }
`;
const PermissionGroup = () => {
    const [createNewPermission, setCreateNewPermission] = useState(false);
    const [editPermissionGroup, setEditPermissionGroup] = useState(false);
    const [editPermissionGroupData, setEditPermissionGroupData] = useState();
    const navigate = useNavigate();
    const [path] = useState([
        { id: 1, to: "/home", Icon: HomeIcon },
        { id: 2, to: "/company-settings/overview", label: "Setting" },
        { id: 3, to: `/permission-group`, label: "Permission Group" },
    ]);
    const permissionGroupData = useSelector(
        (state) => state.Permissions.permissionGroupData
    );
    const PermissionData = useSelector(
        (state) => state.Permissions.permissionData
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchPermissionGroup());
        dispatch(fetchAllPermission());
    }, [dispatch]);
    return (
        <Wrapper>
            <WrapperHead>
                <Breadcrumb primary>
                    {path?.map(({ to, label, Icon }, index) => (
                        <BreadcrumbLink key={index} to={to}>
                            <BreadcrumbItem
                                children={Icon ? <Icon /> : label}
                            />
                        </BreadcrumbLink>
                    ))}
                </Breadcrumb>
                <WrapperHeadBtn>
                    <Button
                        className="iconButton"
                        onClick={() => setCreateNewPermission(true)}
                    >
                        <Plus />
                    </Button>
                </WrapperHeadBtn>
            </WrapperHead>
            <PermissionTable>
                <PermissionTableBody>
                    <React.Fragment>
                        <table>
                            <thead>
                                <tr>
                                    <th>Group Name</th>
                                    <th>Member</th>
                                    <th>Description</th>
                                    <th>Access To</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {permissionGroupData?.map(
                                    (permission, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{permission.name}</td>
                                                <td>{permission.member}</td>
                                                <td>
                                                    {permission.description}
                                                </td>
                                                <td>
                                                    {PermissionData?.map(
                                                        (elem) => {
                                                            if (
                                                                permission?.permissions?.includes(
                                                                    elem?._id
                                                                )
                                                            ) {
                                                                return (
                                                                    <span
                                                                        style={{
                                                                            paddingRight:
                                                                                "5px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            elem.name
                                                                        }
                                                                        ,
                                                                    </span>
                                                                );
                                                            }
                                                            return "";
                                                        }
                                                    )}
                                                </td>
                                                <td>
                                                    <ListActionBtn>
                                                        <button>
                                                            <Edit onClick={() => {
                                                                const { _id, name, description } = permission;
                                                                setEditPermissionGroup(true);
                                                                setEditPermissionGroupData({ _id, name, description });
                                                                }} />
                                                        </button>
                                                        <button>
                                                            <Setting
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/company-settings/permission-group/permission/${permission._id}`,
                                                                        {
                                                                            state:{
                                                                                data:permission
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </button>
                                                        <button>
                                                            <Delete
                                                                onClick={() =>
                                                                    dispatch(
                                                                        deletePermissionGroup(
                                                                            permission._id
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        </button>
                                                    </ListActionBtn>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </React.Fragment>
                </PermissionTableBody>
            </PermissionTable>
            <Modal show={createNewPermission}>
                <CreateNewPermissionGroupModal
                    setCreateNewPermission={setCreateNewPermission}
                />
            </Modal>
            <Modal show={editPermissionGroup}>
                <EditPermissionGroupModal
                    setEditPermissionGroup={setEditPermissionGroup}
                    editPermissionGroupData={editPermissionGroupData}
                />
            </Modal>
        </Wrapper>
    );
};

export default PermissionGroup;
