import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enterSidebar } from "../rootSlice";

export const Hamburger = () => {
    
    var sidebar = useSelector(state => state.sidebar);
    var dispatch = useDispatch();

    useEffect(() => {

        if(sidebar == 0) {
            document.getElementById('sdb-title').style.display = 'none';
            document.getElementById('sdb-title-acc').style.display = 'none';
            document.getElementById('sdb-mob-title').style.display = 'block';
            document.getElementById('sdb-mob-title-acc').style.display = 'block';
            document.getElementById('defaults-sidebar').style.width = '100px';
            var els = document.getElementsByClassName('default_sidebar_menu_link');
            for(var i = 0; i < els.length; i++) {
                els[i].style.display = 'none';
            }
        } else {
            document.getElementById('sdb-mob-title').style.display = 'none';
            document.getElementById('sdb-title-acc').style.display = 'block';
            document.getElementById('sdb-title').style.display = 'block';
            document.getElementById('sdb-mob-title-acc').style.display = 'none';
            document.getElementById('defaults-sidebar').style.width = '240px';
            var els = document.getElementsByClassName('default_sidebar_menu_link');
            for(var i = 0; i < els.length; i++) {
                els[i].style.display = 'block';
            }
        }

    }, [sidebar])

    const toggleSidebar = () => {
        if(sidebar == 0) {
            dispatch(enterSidebar(1))
        } else {
            dispatch(enterSidebar(0))
        }
    }

    return(
        <div onClick={() => toggleSidebar()} className="hamburger_menu_icon">
            <div className="hamburger_menu_line"></div>
            <div className="hamburger_menu_line"></div>
            <div className="hamburger_menu_line"></div>
        </div>
    )
}