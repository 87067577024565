import Layout from "../../layouts/Defaults-Layout";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { Editor as TAE } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import lngcloud from '../../assets/images/lngcloud.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTabletScreenButton, faMobileScreenButton, faDesktop, faEye } from "@fortawesome/free-solid-svg-icons";
import Editor from '../../layouts/Editor';
import { smooth } from '../../assets/js/smooth'
import { useForm } from "react-hook-form";
import Select from "react-select";


export const TranslationsEmailAndPageEditor = () => {

    const navigate = useNavigate();
    var [device, setDevice] = useState('Desktop');
    var [status, setStatus] = useState('');
    var [html, setHtml] = useState('<h1>Test</h1>');
    var defHtml = html;
    var [tab, setTab] = useState('Design');
    const {handleSubmit} = useForm();
    const options = [
        { value: 'en', label: 'English' },
        { value: 'de', label: 'German' },
        { value: 'fr', label: 'Franch' }
      ]
    
    const oec = (content) => {
        setHtml(content)
    }

    function setStatusLive() {
        setStatus('live');
    }

    function setStatusDraft() {
        setStatus('draft');
    }

    function adjustResolution(device) {
        setDevice(device)
        smooth();
    }

    function updateDefault() {
        alert('Not implemented yet')
    }

    return(
        <Layout>
            <div className='navbar_plus_filters'>
                <div className='dashboard_navbar' style={{width: "calc(100% - 361px)"}}>
                    <div className='dashboard_navigation'>
                        <div className='navigation-arrow-outside'>
                            <div className='navigation-arrow navigation-arrow-start navigation-arrow-parents'>
                                <FontAwesomeIcon className='navigation-arrow-icon' icon={faHouse} />
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/settings-and-defaults')} className='navigation-icon-after'>
                                Settings
                            </div>
                        </div>
                        <div className='navigation-icon-after-outside'>
                            <div onClick={() => navigate('/languages-and-translations')} className='navigation-icon-after'>
                                Templates
                            </div>
                        </div>
                        <div className='navigation-icon-after third-arrow'>
                            <p className='nav-text'>Translations</p>
                        </div>
                    </div>
                    <button className="lng_cloud_btn">
                        <img src={lngcloud}/>
                    </button>
                </div>
            </div>
            <div className={tab === 'Design' && device === 'Mobile' ? 'de_preview_container depc_mobile' : tab === 'Design' && device === 'Tablet' ? 'de_preview_container depc_tablet' : tab === 'Design' && device === 'Desktop' ? 'de_preview_container depc_desktop' : 'de_preview_container'}>
                <div className={tab === 'Design' && device === 'Mobile' ? 'dt_tab_bar depc_mobile' : tab === 'Design' && device === 'Tablet' ? 'dt_tab_bar depc_tablet' : tab === 'Design' && device === 'Desktop' ? 'dt_tab_bar depc_desktop' : 'dt_tab_bar'}>
                    <div onClick={() => setTab('Design')} className={tab === 'Design' ? 'dt_tab dt_tab_active' : 'dt_tab'}>Design</div>
                    {/* <div className='dt_tab'>JSON</div> */}
                    <div onClick={() => setTab('Html')} className={tab === 'Html' ? 'dt_tab dt_tab_active' : 'dt_tab'}>HTML</div>
                    {/* <div className='dt_tab'>Plain Text</div> */}
                </div>
                {
                    tab === 'Design' ? 
                    <div className={tab === 'Design' && device === 'Mobile' ? 'html_container depc_mobile' : tab === 'Design' && device === 'Tablet' ? 'html_container depc_tablet' : tab === 'Design' && device === 'Desktop' ? 'html_container depc_desktop' : 'html_container'} dangerouslySetInnerHTML={{__html: html}}>

                    </div> : tab === 'Html' ?
                                        <form style={{textAlign: 'center'}}>
                                        <TAE apiKey="1271nwtz2rc95x7x8ufeo4sjcu7t47t656e8l2dmrmdcow56" initialValue={defHtml} onEditorChange={oec}
                                            init={{
                                                selector: 'textarea',
                                                plugins: ['code', 'save'],
                                                toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                                                directionality: 'ltr',
                                                valid_children : '+body[style]'
                                            }}
                                        />
                                        </form> : <></>
                }
            </div>
            <Editor>
            <form onSubmit={handleSubmit(updateDefault)} className="de_form">
                    <div className="de_design_tab">
                        <div className="de_form_group">
                            <label className="de_label" style={{fontWeight: 500, color: '#303030'}}>Source Language</label>
                            <Select options={options} styles={{
                                control: (baseStyles) => ({
                                ...baseStyles,
                                width: '280px',
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                color:'#4f4f4f',
                                fontWeight: '',
                                boxShadow: 'none',
                                position: 'relative',
                                zIndex: 9999,
                                borderColor: '#E9E9E9',
                                cursor: 'pointer'
                                })
                            }}
                            isSearchable={false}
                            placeholder={options[0].label}
                            />
                        </div>
                        <div className="de_form_group" style={{zIndex: 503}}>
                            <label className="de_label" style={{fontWeight: 500, color: '#303030'}}>Target Language</label>
                            <Select options={options} styles={{
                                control: (baseStyles) => ({
                                ...baseStyles,
                                width: '280px',
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                position: 'relative',
                                color:'#4f4f4f',
                                fontWeight: '',
                                zIndex: 9998,
                                boxShadow: 'none',
                                borderColor: '#E9E9E9',
                                cursor: 'pointer',
                                })
                            }}
                            isSearchable={false}
                            placeholder={options[0].label}
                            />
                        </div>
                        <button className="at_button">Auto Translate</button>
                    </div>
                    <div className='de_bd_container'>
                        <div className="de_devices_container dep_mt">
                            <div className={"defaults_device de_device"}
                                onClick={() => alert('Not implemented yet')}>
                                <FontAwesomeIcon icon={faEye} />
                            </div>
                            <div className={device === 'Desktop' ? "defaults_device de_device de_device_active" : "defaults_device de_device"}
                                onClick={() => adjustResolution('Desktop')}>
                                <FontAwesomeIcon icon={faDesktop} />
                            </div>
                            <div className={device === 'Mobile' ? "defaults_device de_device de_device_active" : "defaults_device de_device"}
                                onClick={() => adjustResolution('Mobile')}
                            >
                                <FontAwesomeIcon icon={faMobileScreenButton} />
                            </div>
                            <div className={device === 'Tablet' ? "defaults_device de_device de_device_active" : "defaults_device de_device"}
                                onClick={() => adjustResolution('Tablet')}>
                                <FontAwesomeIcon icon={faTabletScreenButton} />
                            </div>
                        </div>
                        <div className="de_form_button_container">
                            <button onClick={() => setStatusDraft()} type="submit" className="de_form_button de_form_button_left">Save as Draft</button>
                            <button onClick={() => setStatusLive()} type="submit" className="de_form_button de_form_button_right">Publish</button>
                        </div>
                    </div>
                </form>
            </Editor>
        </Layout>
    )
}