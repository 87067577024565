import {BrowserRouter} from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "./components/ui/styles/components.scss";
import "./App.css";

export default function App() {
  return (
    <BrowserRouter >
      <AppRoutes />
    </BrowserRouter>
  );
}
