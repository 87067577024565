import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;
const BearerToken = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${BearerToken}`,
    // Permission: `Company Settings`,
  },
};
export async function fetchUserRole(id) {
  try {
    const { data } = await axios.get(`${baseURL}/user-roles/${id}`, config);
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}
export async function fetchAllUserRole() {
  try {
    const { data } = await axios.get(`${baseURL}/user-roles`, config);
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function createUserRole(addUser) {
  try {
    const { data } = await axios.post(
      `${baseURL}/user-roles`,
      { ...addUser },
      config
    );
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function editUserRole(editData) {
  try {
    const { data } = await axios.put(
      `${baseURL}/user-roles/${editData?._id}`,
      {
        ...editData,
      },
      config
    );
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

export async function deleteUserRole(id) {
  try {
    const { data } = await axios.delete(`${baseURL}/user-roles/${id}`, config);
    return { isSuccess: true, data };
  } catch (e) {
    return {
      isSuccess: false,
      error:
        e.response.data.Error ||
        e.response.data.error ||
        e.response.data.err.message,
    };
  }
}

const UserRoleService = {
  fetchAllUserRole,
  createUserRole,
  fetchUserRole,
  editUserRole,
  deleteUserRole,
};

export default UserRoleService;
