import React from "react";
import styled from "styled-components";

const CardMain = styled.div`
  background: #fff;
  border: 1px solid #ed8154;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
`;

const CardHead = styled.div`
  border-bottom: 1px solid #ed8154;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    width:15%;
    text-align: right;
    font-size: 12px;
    
    &:first-child {
      width: 55%;
      text-align: left;
      display: -webkit-box;
      max-width: 300px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`;

const CardName = styled.h6`
  font-size: 16px !important;
  color: #666 !important;
  margin: 0;
`;

const CardBtn = styled.div`
  display: flex;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin-left: 5px;
  }
  svg {
    height: 16px;
    width: 16px;
    color: #ed8154;
  }
`;

const CardBody = styled.div`
  display: flex;
  height: calc(100% - 36px);
  ul {
    margin: 0;
  }
`;

const CardFooter = styled.div`
  button {
    background: #ed8154;
    width: 100%;
    height: 40px;
    box-shadow: none;
    border-radius: 0;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 1px;
    &:hover {
      background: #ed8154;
      color: #fff !important;
      box-shadow: none;
    }
  }
`;

const InvitedStatus = styled.h6`
  color: #417AC7;
`;

const VerifiedStatus = styled.h6`
  color: #549A5B;
`;

const DeletedStatus = styled.h6`
  color: #982727;
`;

const UserCardInfo = (props) => {
  const { title, cardIcon, children, onClick, buttonName, verified, invited, deleted } = props;
  return (
    <React.Fragment>
      <CardMain>
        {title !== undefined ? (
          <CardHead>
            <CardName>{title}</CardName>
            {/* <CardBtn>{cardIcon}</CardBtn> */}
            <DeletedStatus>{deleted}</DeletedStatus>
            <> {invited?.props?.children === 'Invited' ? <InvitedStatus>{invited}</InvitedStatus> : verified?.props?.children === 'Verified' ? <VerifiedStatus>{verified}</VerifiedStatus> : "" }</>            
          </CardHead>
        ) : null}
        <CardBody>{children}</CardBody>
        {buttonName !== undefined ? (
          <CardFooter>
            <button
              onClick={true ? onClick : undefined}
              className="settings__footer-button"
            >
              {buttonName}
            </button>
          </CardFooter>
        ) : null}
      </CardMain>
    </React.Fragment>
  );
};

export default UserCardInfo;
