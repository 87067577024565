import { smooth } from "./smooth";

export function openCloseModal(id) {

    var modal = document.getElementById(id)

    if(modal.style.display === 'none') {
        smooth();
        modal.style.display = 'flex';
    } else {
        smooth();
        modal.style.display = 'none';
    }
}

export function openModal(id) {
    document.getElementById(id).style.display = 'flex';
    smooth();
}

export function closeModal(id) {
    document.getElementById(id).style.display = 'none';
}