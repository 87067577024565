export const AllProjects = [
    {
        title: "Projects",
        permission: "projects",
        paragraph:
            "Projects enables you to organize, and manage quant and qual research projects. It enables you to define and automate project goals, track progress and process the project results.",
        path: "/panel-manager/projects",
    },
    {
        title: "Survey Designer",
        permission: "survey_designer",

        paragraph:
            "The Surveys system allows you to create and administer surveys. It provides you with tools to design advanced questionnaires, add logic and routing, customize the survey interface, and analyze responses.",
        path: "/survey-designer",
    },
    // {
    //     title: "Scheduler",
    //     permission: "scheduler",

    //     paragraph:
    //         "The Scheduler is a system that allows to create and follow up on appointments with interview respondents for qualitative interviews.",
    //     path: "/scheduler/events",
    // },
    {
        title: "Panels",
        permission: "panels",

        paragraph:
            " The panel system allow you to recruit and screen panelists, manage their profiles and support your panel members when needed.",
        path: "/panel-manager/overview",
    },
    // {
    //     title: "Contacts",
    //     permission: "contacts",

    //     paragraph:
    //         "Manage your companies clients, vendors and contacts through the contact manager. ",
    //     path: "/contact-manager",
    // },
    // {
    //   title: "Proposals",
    //   paragraph:
    //     " Lorem ipsum dolor sit amen consectetur adipisicing elit Laboriously magni architecto ipsa earum obcaecati repudiandae",
    //   path: "/",
    // },
    // {
    //   title: "Reports",
    //   paragraph:
    //     " Lorem ipsum dolor sit ament consectetur adipisicing elit Laboriosam magni architecto ipsa earum obcaecati repudiandae",
    //   path: "/",
    // },
    // {
    //   title: "Company Settings",
    //   paragraph:
    //     " Lorem ipsum dolor sit ament consectetur adipisicing elit Laboriosam magni architecto ipsa earum obcaecati repudiandae",
    //   path: "/company-settings/user-management-dashboard",
    // },
];

export const OverviewCard = [
    {
        title: "Projects",
        lastDate: "DD/MM/YYYY",
        paragraph:
            " Lorem ipsum dolor sit amen consectetur adipisicing elit Laboriously magni architecto ipsa earum obcaecati repudiandae",
        path: "/panel-manager/projects",
    },
    {
        title: "Survey",
        lastDate: "DD/MM/YYYY",
        paragraph:
            " Lorem ipsum dolor sit ament consectetur adipisicing elit Laboriosam magni architecto ipsa earum obcaecati repudiandae",
        path: "/survey-designer",
    },
    {
        title: "Scheduler",
        lastDate: "DD/MM/YYYY",
        paragraph:
            " Lorem ipsum dolor sit amen consectetur adipisicing elit Laboriously magni architecto ipsa earum obcaecati repudiandae",
        path: "/scheduler/events",
    },
    {
        title: "Company Settings",
        lastDate: "DD/MM/YYYY",
        paragraph:
            " Lorem ipsum dolor sit ament consectetur adipisicing elit Laboriosam magni architecto ipsa earum obcaecati repudiandae",
        path: "/company-settings/user-management-dashboard",
    },
    // {
    //   title: "Panels",
    //   lastDate: "DD/MM/YYYY",
    //   paragraph:
    //     " Lorem ipsum dolor sit ament consectetur adipisicing elit Laboriosam magni architecto ipsa earum obcaecati repudiandae",
    //   path: "/panel-manager/overview",
    // },
    // {
    //   title: "Proposals",
    //   lastDate: "DD/MM/YYYY",
    //   paragraph:
    //     " Lorem ipsum dolor sit amen consectetur adipisicing elit Laboriously magni architecto ipsa earum obcaecati repudiandae",
    //   path: "/",
    // },
    // {
    //   title: "Reports",
    //   lastDate: "DD/MM/YYYY",
    //   paragraph:
    //     " Lorem ipsum dolor sit ament consectetur adipisicing elit Laboriosam magni architecto ipsa earum obcaecati repudiandae",
    //   path: "/",
    // },
];
