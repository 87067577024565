import React, { useEffect } from 'react';
import { Link, useNavigate, useRoutes } from 'react-router-dom';
import '../css/dashboard.css'
import '../css/defaults-and-settings.css'
import { CompanySettingsAttribute } from './CompanySettingsAtribute';

export const CompanySettingsCard = (props) => {

    const navigate = useNavigate();

    return(
        
        <div className='settings-card-container'>
            <div className='settings-card-header'>
                {props.title}
            </div>
            <div className='settings-card-body cs-mp'>
                <CompanySettingsAttribute ky='Organization' value='[COMPANY NAME]' />
                <CompanySettingsAttribute ky='Your Plan' value='[PLAN NAME]' />
                <CompanySettingsAttribute ky='Invoice Email' value='[INVOICE EMAIL]' />
                <CompanySettingsAttribute ky='Last Invoice' value='[DD/MM/YYYY]' />
                <CompanySettingsAttribute ky='Next Invoice' value='[DD/MM/YYYY]' />
            </div>
            <div onClick={() => navigate(props.link)} className='settings-card-footer'>
                {props.destination}
            </div>
        </div>
    )
}