import React from 'react';
import '../style/defaults/sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSliders, faDownload, faTableColumns, faClock, faLanguage, faGear, faUsers, faShieldAlt, faAddressBook, faBug } from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { enterPage } from '../rootSlice';

export const Sidebar = () => {
    
    var page = useSelector(state => state.page);
    var company = useSelector(state => state.curCompany)
    var navigate = useNavigate();
    var dispatch = useDispatch();

    const nav = (page, link) => {
        dispatch(enterPage(page));
        navigate('/' + link)
    }

    return(
        <div id='defaults-sidebar' className='defaults_sidebar_container'>

            <div className='sidebar_menu_item_home'>
                <FontAwesomeIcon className='sidebar_menu_icon' icon={faHome} />
                <a href='' className='default_sidebar_menu_link'>
                    Home
                </a>
            </div>

            <div className='default_sidebar_border'></div>

            <div id='sdb-title-acc' className='defaults_sidebar_title' style={{display: 'block'}}>
                Account Settings
            </div>

            <div id='sdb-mob-title-acc' className='defaults_sidebar_title' style={{display: 'none'}}>
                Account
            </div>

            <div className='default_sidebar_menu_items_container'>

                <div onClick={() => nav('usr', 'user-management-dashboard')} className={page !== 'usr' ? 'sidebar_menu_item' : 'sidebar_menu_item sidebar_menu_item_active'}>
                    <FontAwesomeIcon className='sidebar_menu_icon' icon={faUsers} />
                    <a className='default_sidebar_menu_link'>
                        User Management
                    </a>
                </div>

                <div onClick={() => nav('cst', `company-settings/${company}`)} className={page !== 'cst' ? 'sidebar_menu_item' : 'sidebar_menu_item sidebar_menu_item_active'}>
                    <FontAwesomeIcon className='sidebar_menu_icon' icon={faGear} />
                    <a className='default_sidebar_menu_link'>
                        Company Settings
                    </a>
                </div>

                <div onClick={() => nav('las', 'legal-and-security')} className={page !== 'las' ? 'sidebar_menu_item' : 'sidebar_menu_item sidebar_menu_item_active'}>
                    <FontAwesomeIcon className='sidebar_menu_icon' icon={faShieldAlt} />
                    <a className='default_sidebar_menu_link'>
                        Legal & Security
                    </a>
                </div>

            </div>

            <div className='default_sidebar_border'></div>

            <div id='sdb-title' className='defaults_sidebar_title' style={{display: 'block'}}>
                System Defaults
            </div>

            <div id='sdb-mob-title' className='defaults_sidebar_title' style={{display: 'none'}}>
                Defaults
            </div>

            <div className='default_sidebar_menu_items_container'>

                <div onClick={() => nav('languages', 'languages-and-translations')} className={page !== 'languages' ? 'sidebar_menu_item' : 'sidebar_menu_item sidebar_menu_item_active'}>
                    <FontAwesomeIcon className='sidebar_menu_icon' icon={faLanguage} />
                    <a className='default_sidebar_menu_link'>
                        Languages
                    </a>
                </div>

                <div onClick={() => nav('timezones', 'tzn')} className={page !== 'timezones' ? 'sidebar_menu_item' : 'sidebar_menu_item sidebar_menu_item_active'}>
                    <FontAwesomeIcon className='sidebar_menu_icon' icon={faClock} />
                    <a className='default_sidebar_menu_link'>
                        Time Zones
                    </a>
                </div>

                <div onClick={() => nav('defaults', 'default-content')} className={page !== 'defaults' ? 'sidebar_menu_item' : 'sidebar_menu_item sidebar_menu_item_active'}>
                    <FontAwesomeIcon className='sidebar_menu_icon' icon={faSliders} />
                    <a className='default_sidebar_menu_link'>
                        Default Content
                    </a>
                </div>

                <div onClick={() => nav('contacts', 'contact-manager')} className={page !== 'contacts' ? 'sidebar_menu_item' : 'sidebar_menu_item sidebar_menu_item_active'}>
                    <FontAwesomeIcon className='sidebar_menu_icon' icon={faAddressBook} />
                    <a className='default_sidebar_menu_link'>
                        Contact Manager
                    </a>
                </div>

                <div onClick={() => nav('reports', 'reports')} className={page !== 'reports' ? 'sidebar_menu_item' : 'sidebar_menu_item sidebar_menu_item_active'}>
                    <FontAwesomeIcon className='sidebar_menu_icon' icon={faBug} />
                    <a className='default_sidebar_menu_link'>
                        Reports
                    </a>
                </div>

            </div>
        </div>
    )
}