export const PermissionGroupDataArray = [
  {
    id: "1",
    groupName: "CompanyAdmin",
    member: "1",
    description: "System permission, Can't be revised",
    accessTo: "All Access",
  },
  {
    id: "12",
    groupName: "CompanyAdmin",
    member: "1",
    description: "System permission, Can't be revised",
    accessTo: "All Access",
  },
  {
    id: "14",
    groupName: "CompanyAdmin",
    member: "1",
    description: "System permission, Can't be revised",
    accessTo: "All Access",
  },
  {
    id: "15",
    groupName: "CompanyAdmin",
    member: "1",
    description: "System permission, Can't be revised",
    accessTo: "All Access",
  },
  {
    id: "15",
    groupName: "CompanyAdmin",
    member: "1",
    description: "System permission, Can't be revised",
    accessTo: "All Access",
  },
  {
    id: "16",
    groupName: "CompanyAdmin",
    member: "1",
    description: "System permission, Can't be revised",
    accessTo: "All Access",
  },
  {
    id: "19",
    groupName: "CompanyAdmin",
    member: "1",
    description: "System permission, Can't be revised",
    accessTo: "All Access",
  },
  {
    id: "18",
    groupName: "CompanyAdmin",
    member: "1",
    description: "System permission, Can't be revised",
    accessTo: "All Access",
  },
];

export const GivePermissionArray = [
  {
    id: "1",
    system: "Access to project, survey & event",
  },
  {
    id: "11",
    system: "Access to project, survey & event",
  },
  {
    id: "12",
    system: "Access to project, survey & event",
  },
  {
    id: "13",
    system: "Access to project, survey & event",
  },
  {
    id: "14",
    system: "Access to project, survey & event",
  },
  {
    id: "15",
    system: "Access to project, survey & event",
  },
  {
    id: "16",
    system: "Access to project, survey & event",
  },
  {
    id: "168",
    system: "Access to project, survey & event",
  },
  {
    id: "166",
    system: "Access to project, survey & event",
  },
  {
    id: "146",
    system: "Access to project, survey & event",
  },
];

export const AccessPermissionArray = [
  {
    id: "1",
    name: "No Access",
    type: "Select",
  },
  {
    id: "2",
    name: "Full Access",
    type: "Select",
  },
];
