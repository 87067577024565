import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../components/ui/Modal";
import { ReactComponent as Trash } from "../../components/ui/styles/icon_Trash.svg";
import { ReactComponent as EditIcon } from "../../components/ui/styles/Icon_Edit.svg";
import { ReactComponent as HomeIcon } from "../../components/ui/styles/home.svg";
import { ReactComponent as PlusIcon } from "../../components/ui/styles/plus_icon.svg";
import BreadCrumb, {
  BreadcrumbItem,
  BreadcrumbLink,
} from "../../components/ui/Breadcrumb";
import Button from "../../components/ui/Button";
import AddNewRoles from "../../components/model/addNewRoles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  deleteUserRole,
  fetchAllUserRole,
} from "../../redux/features/UserRoleSlice";
const Wrapper = styled.section`
  min-height: calc(100vh - 58px);
  margin-left: 250px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #e4e4e4;
`;
const WrapperHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;
const WrapperHeadBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  button {
    background: #ed8154;
    border: 1px solid #ed8154;
    font-size: 14px !important;
    color: #fff;
    text-transform: capitalize;
    min-width: 170px;
    height: 42px;
    font-weight: 700;
    border-radius: 12px;
    &:hover {
      color: #ed8154;
    }
    &:first-child {
      color: #ed8154;
      &:hover {
        background: #ed8154;
        color: #fff;
      }
    }
  }

  .iconButton {
    min-width: 42px;
    margin-right: 15px;

    svg {
      color: #ed8154;
      fill: #ed8154;
      path {
        fill: #ed8154;
      }
    }
  }
`;

const DataTable = styled.div`
  overflow: auto;
  background: #fff;
  max-height: 76vh;
  margin-top: 25px;
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ed8154;

    tr {
      border-bottom: 1px solid #ed8154;
    }
    tbody {
      tr:last-child {
        border-bottom: none;
      }
      td:last-child {
        color: #333;
      }
    }
    td,
    th {
      border-right: 1px solid #ed8154;
      padding: 8px 15px;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      text-align: left;
      white-space: nowrap;
      svg,
      img {
        height: 22px;
        width: 22px;
      }
      &:last-child {
        border-right: none;
      }
      span {
        display: flex;
      }
      .dropDown-Link {
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        text-decoration: underline;
        color: #417ac7;
        font-weight: 500;
        cursor: pointer;
        svg {
          height: 15px;
          width: 15px;
          margin-right: 15px;
          cursor: pointer;
          path {
            color: #417ac7;
            fill: #417ac7;
          }
        }
      }
    }
  }
  .link {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    padding: 20px;
    margin: 0;
    text-decoration: underline;
    color: #417ac7;
  }
`;

const TableActionBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    background: #fff;
    height: 20px;
    width: 20px;
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      height: 16px !important;
      width: 16px !important;
      path {
        fill: #ed8154;
      }
    }
    &:last-child {
      svg path {
        fill: #982727;
      }
    }
    &.large-btn {
      height: 40px;
      width: 40px;
      svg {
        height: 20px !important;
        width: 20px !important;
      }
    }
    &:hover {
      border-color: #ed8154;
    }
  }
`;
export const PathUrl = [
  { id: 1, to: "/home", Icon: HomeIcon },
  { id: 2, to: "/company-settings/overview", label: "Settings" },
  {
    id: 3,
    to: "/company-settings/user-management-dashboard",
    label: "User Management",
  },
  {
    id: 4,
    to: "/company-settings/user-management-dashboard/roles",
    label: "Roles",
  },
];

const Roles = () => {
  const [roleDialog, setRoleDialog] = useState(false);
  const [isEditId, setIsEditId] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllUserRole());
  }, [setRoleDialog, isEditId, dispatch]);
  useEffect(() => {
    dispatch(fetchAllUserRole());
  }, [dispatch]);
  const userRoleData = useSelector((state) => state.UserRole.userRole);
  return (
    <Wrapper>
      <WrapperHead>
        <BreadCrumb primary>
          {PathUrl?.map(({ to, label, Icon, id }, index) => (
            <BreadcrumbLink key={index} to={to}>
              <BreadcrumbItem children={Icon ? <Icon /> : label} />
            </BreadcrumbLink>
          ))}
        </BreadCrumb>
        <WrapperHeadBtn>
          <Button className="iconButton" onClick={() => setRoleDialog(true)}>
            <PlusIcon />
          </Button>
        </WrapperHeadBtn>
      </WrapperHead>
      <DataTable>
        <table>
          <thead>
            <tr>
              <th>Role</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userRoleData?.map((data, index) => {
              return (
                <tr key={index}>
                  <td>{data.role}</td>
                  <td>{data.description}</td>
                  <td>
                    <TableActionBtn>
                      <span
                        onClick={() => {
                          setRoleDialog(true);
                          setIsEditId(data._id);
                        }}
                      >
                        <EditIcon />
                      </span>
                      <span
                        onClick={() => {
                          dispatch(deleteUserRole(data._id));
                          dispatch(fetchAllUserRole());
                        }}
                      >
                        <Trash />
                      </span>
                    </TableActionBtn>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </DataTable>
      <Modal show={roleDialog}>
        <AddNewRoles
          setRoleDialog={setRoleDialog}
          userRoleData={userRoleData}
          isEditId={isEditId}
          setIsEditId={setIsEditId}
        />
      </Modal>
    </Wrapper>
  );
};

export default Roles;
