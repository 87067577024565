export function makeItSmooth() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

export function success(message) {
    alert(message);
    makeItSmooth()
}