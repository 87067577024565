import Input from "../../ui/Input";
import styled from "styled-components";
import Button from "../../ui/Button";
import Dropdown from "../../ui/Dropdown";
import {
    communication_type,
    provider,
} from "./../../../constant/communication";
import { ReactComponent as Close } from "../../../components/ui/styles/Icon_Close.svg";

const AddNewModal = styled.div`
    text-align: left;
    padding: 20px;
`;

const ModalHeading = styled.div`
    background-color: #ed8154;
    display: flex;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    padding: 10px 25px;
    color: white;
    button {
        padding: 0;
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const ModalWrapper = styled.div`
    width: 900px;
`;

const ImportModalCardButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;

    button {
        font-size: 14px;
        color: #fff;
        background: #ed8154;
        border: 1px solid #ed8154;
        border-radius: 8px;
        overflow: hidden;
        padding: 10px 50px;
        min-width: 95px;
        margin-left: 12px;
        font-weight: 600;
        cursor: pointer;
        &:first-of-type {
            margin-left: 0;
            background: #fff;
            color: #ed8154;
        }
    }
`;

const ExportModalCardForm = styled.div`
    width: 100%;
    input {
        width: 100%;
        border: 1px solid #e0e0e0;
        min-height: 40px;
        box-sizing: border-box;
        padding: 8px 12px;
        border-radius: 8px;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    label {
        font-size: 14px !important;
        color: #4f4f4f !important;
        margin: 0;
        width: 100%;
        text-align: left;
        display: block;
        margin-bottom: 6px;
        font-weight: 700;
    }
`;

const InputBox = styled.div`
    margin-bottom: 20px;

    select {
        width: 100%;
        border: 1px solid #e0e0e0;
        min-height: 40px;
        box-sizing: border-box;
        padding: 8px 12px;
        border-radius: 8px;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
`;

const CustomDropdown = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    & > div {
        width: 100%;
    }
`;

const GrayDropdown = styled.div`
    border: 1px solid #e0e0e0;
    height: 50%;
    border-radius: 10px;
    & > div {
        width: 100%;
    }
`;

const AddNewIntegrationModal = ({ close }) => {
    return (
        <ModalWrapper>
            <ModalHeading>
                <span>Add New Integration</span>
                <Button onClick={() => close(false)}>
                    <Close />
                </Button>
            </ModalHeading>
            <AddNewModal>
                <ExportModalCardForm>
                    <InputBox>
                        <label>Name</label>
                        <Input />
                    </InputBox>
                    <InputBox>
                        <label>Description</label>
                        <Input />
                    </InputBox>
                    <CustomDropdown>
                        <InputBox>
                            <label>Select Type</label>
                            <select>
                                <option>Email</option>
                                <option>SMS</option>
                            </select>
                        </InputBox>
                        <InputBox>
                            <label>Select Provider</label>
                            <select>
                                <option>Socketlabs</option>
                                <option>Amazon AES</option>
                                <option>Exact Target</option>
                                <option>Sendgrid</option>
                            </select>
                        </InputBox>
                    </CustomDropdown>
                </ExportModalCardForm>
                <ImportModalCardButtons>
                    <Button onClick={() => close(false)}>Cancel</Button>
                    <Button>Save</Button>
                </ImportModalCardButtons>
            </AddNewModal>
        </ModalWrapper>
    );
};

export default AddNewIntegrationModal;
