import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import CommonHeader from "../components/commonheader";
import {
  fetchAllPermission,
  fetchPermissionGroup,
} from "../redux/features/PermissionSlice";

const PrivateRoute = ({ children, moduleName }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllPermission());
    dispatch(fetchPermissionGroup());
  }, [dispatch]);
  const isAuthenticated = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  const permissionGroupData = useSelector(
    (state) => state.Permissions.permissionGroupData
  );

  const PermissionData = useSelector(
    (state) => state.Permissions.permissionData
  );

  const permissionGroup = permissionGroupData?.find(
    (elem) => elem._id === user.permissionGroupId
  );

  let PermissionGroupNames = [];
  var permissionIds = permissionGroup?.permissions;
  PermissionData?.forEach((elem) => {
    if (permissionIds?.includes(elem._id)) {
      PermissionGroupNames.push(elem.name);
    }
  });
  PermissionGroupNames.push("Home");
  PermissionGroupNames.push("System Defaults");
  // this comes from user
  return isAuthenticated && isAuthenticated != undefined ? (
    PermissionGroupNames.includes(moduleName) ? (
      children
    ) : (
      <Navigate to="/home" />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
