import React, { useEffect, useState } from "react";
import '../../style/defaults/preview.css';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { enterEPagesHtml } from "../../rootSlice";
import axios from "axios";

export const PreviewWebPage = () => {

    var url = window.location.href;
    var urlArray = url.split('/');

    const dispatch = useDispatch();
    const navigate = useNavigate()

    var Html = useSelector(state => state.e_pages_html)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {

        if(localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            navigate('/')
        }
        
        axios.get(`${process.env.REACT_APP_URL}/defaults/slug/${urlArray.slice(-1)}`)
            .then((res) => {
                if(res.data.data) {
                    dispatch(enterEPagesHtml(res.data.data.html));
                    setIsLoaded(true)
                } else {
                    alert(res.data.message);
                    window.close();
                }
            })
    }, [])

    return(
        <>
            {
                isLoaded ? 
                    <div className="preview_web_page_container" dangerouslySetInnerHTML={{__html: Html}}>
                    </div>: ''
            }
        </>
    )
}