import Modal from "../../layouts/Modal";
import toggler_arrow from '../../assets/images/select_arrow.png';
import { useEffect, useState } from "react";
import { rotate } from "../../assets/js/rotateArrow";
import { returnArrows } from "../../assets/js/returnArrows";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { enterPageId, enterTrackingCodeId } from "../../rootSlice";
import { smooth } from "../../assets/js/smooth";

export const ModalAddTrackingCode = () => {

    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [location, setLocation] = useState('head');
    const code_id = useSelector(state => state.tc_id);
    const dispatch = useDispatch()
    const page_id = useSelector(state => state.page_id);
    const {register, handleSubmit, reset} = useForm({
        defaultValues: {
            name: name,
            code: code
        }
    })

    useEffect(() => {
        reset({
            name: null,
            code: null
        })
        if(code_id !== null) {
            axios.get(`${process.env.REACT_APP_URL}/defaults/default/${page_id}`)
            .then((res) => {
                if(res.data.data) {
                    res.data.data.tracking_codes.map((code) => {
                        if(code._id == code_id) {
                            reset({
                                name: code.name,
                                code: code.code,
                            });
                            setLocation(code.location)
                        }
                    })
                } else {
                    alert('An error occured while fetching');
                }
            })
        }
    }, [page_id, code_id])

    function chooseLoc(location) {
        document.getElementById('tc_add-item-s').style.display = 'none';
        rotate('tc_add-icn');
        setLocation(location);
    }

    function toggleSelect(id, aid) {

        if(document.getElementById(id).style.display === 'flex') {
            document.getElementById(id).style.display = 'none'
            returnArrows();
        } else {
            var elements = document.getElementsByClassName('options-container');
            for(var i = 0; i < elements.length; i++) {
                elements[i].style.display = 'none'
            }
            returnArrows();
            rotate(aid)
            document.getElementById(id).style.display = 'flex'
        }
    }

    const update = (data) => {
        var code = {
            id: code_id,
            name: data.name,
            code: data.code,
            location: location
        }

        if(page_id !== null) {
            axios.post(`${process.env.REACT_APP_URL}/defaults/default/${page_id}/code`, code, {
                headers: {
                    'Content-type': 'application/json'
                }
            }).then((res) => {
                dispatch(enterPageId(null));
                dispatch(enterTrackingCodeId(null));
                document.getElementById('add-tc-modal').style.display = 'none';
                alert(res.data.message);
                smooth();
                reset()
            })
        }

    }

    const close = () => {
        document.getElementById('add-tc-modal').style.display = 'none';
        dispatch(enterTrackingCodeId(null))
        reset({
            name: null,
            code: null
        })
    }

    return(
        <Modal id='add-tc-modal' title='Add Tracking Code'>
            <form onSubmit={handleSubmit(update)} className="modal_form">
                <div className="modal_input_group mt-16">
                    <label className="modal_label">Tracking Code Reference Name</label>
                    <input name='name' {...register('name')} className="modal_input br-8" placeholder="Enter a name for your reference" type='text' />
                </div>
                <div className="modal_input_group mt-16 h-80">
                    <label className="modal_label">Enter Code</label>
                    <textarea spellCheck={false} name='code' {...register('code')} className="modal_input br-8 h-80 code_input" placeholder="Enter Code" />
                </div>
                <div className='css-select-container p-x mt-16 br-8'>
                <label className="modal_label">Select Code Location</label>
                    <div onClick={() => toggleSelect('tc_add-item-s', 'tc_add-icn')} style={{marginTop: '10px'}} className='filter_select w-100 '>
                        {
                            location === 'head' ? 'Head of HTML Page' : 'Body of HTML Page'
                        }
                        <img className='transition' style={{transform: 'rotate(0)'}} id='tc_add-icn' alt='' src={toggler_arrow} />
                    </div>
                    <div style={{position: 'relative', height: 0, width: '100%'}}>
                        <div id='tc_add-item-s' style={{display: 'none', width: '100%', top: 0}} className='options-container t-265'>
                            <div onClick={() => chooseLoc('head')} className='option option-top w-100'>Head of HTML Page</div>
                            <div onClick={() => chooseLoc('body')} className='option option-bottom w-100'>Body of HTML Page</div>
                        </div>
                    </div>
                </div>
                <div className="modal_button_group">
                    <button type="button" onClick={() => close()} className="modal_button modal_button_strip">Close</button>
                    <button type="submit" className="modal_button modal_button_solid">Send</button>
                </div>
            </form>
        </Modal>
    )
}