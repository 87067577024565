export const BulkActions = [
    { value: "delete", name: "Delete" },
    { value: "resetpassword", name: "Reset Password" },
];

export const AssignPermissionGroup = [
    {
        id: "1",
        name: "Company Administrator",
        type: "Select",
    },
    {
        id: "2",
        name: "Project Managers (Senior)",
        type: "Select",
    },
    {
        id: "3",
        name: "Project Managers (Junior)",
        type: "Select",
    },
    {
        id: "4",
        name: "Panel Managers",
        type: "Select",
    },
    {
        id: "5",
        name: "Survey Scripters",
        type: "Select",
    },
    {
        id: "6",
        name: "Sales Managers",
        type: "Select",
    },
    {
        id: "7",
        name: "Sales Support",
        type: "Select",
    },
    {
        id: "8",
        name: "Client Self-Serve Project Managers",
        type: "Select",
    },
];

export const UserCardActions = [
    {
        id: "1",
        name: "Permission Group",
        type: "modal",
    },
    {
        id: "2",
        name: "Reset User Password",
    },
    {
        id: "3",
        name: "Delete User",
    },
];

export const InvitedUserCardActions = [
    {
        id: "1",
        name: "Permission Group",
        type: "modal",
    },
    {
        id: "2",
        name: "Delete Invited User",
        type: "modal",
    },
];

export const SoftDeletedUserCardActions = [
    {
        id: "1",
        name: "Permission Group",
        type: "modal",
    },
    {
        id: "2",
        name: "Reinvite User",
    },
];
