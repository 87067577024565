import axios from "axios";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { closeModal } from "../../assets/js/modal";
import Modal from "../../layouts/Modal";
import '../../style/layout/modal.css';

export const ModalSendPreviewEmail = () => {

    var [email, setEmail] = useState('');
    var preview_id = useSelector(state => state.preview_id);
    const { register, handleSubmit} = useForm({
        defaultValues: {
            email: email
        }
    })

    const sendEmail = (data) => {

        const submit = {
            email: data.email
        }

        axios.put(`${process.env.REACT_APP_URL}/defaults/email/${preview_id}`, submit, {headers: {
            'Content-Type': 'application/json'
        }})
            .then((res) => {
                alert(res.data.message);
            })
    }

    return(
        <Modal id='sp-email' title='Send Preview Email'>
            {
                <form onSubmit={handleSubmit(sendEmail)} className="modal_form">
                    <div className="modal_input_group">
                        <label className="modal_label">Enter address</label>
                        <input name='email' {...register('email')} className="modal_input" placeholder="Enter address" type='email' />
                    </div>
                    <div className="modal_button_group">
                        <button type="button" onClick={() => closeModal('sp-email')} className="modal_button modal_button_strip">Close</button>
                        <button type="submit" className="modal_button modal_button_solid">Send</button>
                    </div>
                </form>
            }
        </Modal>
    )
}