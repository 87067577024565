/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import { ReactComponent as Close } from "../../ui/styles/Icon_Close.svg";
import { SearchDropDown } from "../../ui/Dropdown";
import styled from "styled-components";
import { AssignPermissionGroup } from "../../../constant/usermanagement";
import { useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
    createUser,
    fetchAllCompanies,
} from "../../../redux/features/UserManagementSlice";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { createUser } from "../../../redux/features/UserManagementSlice";

const ExportModalCard = styled.div`
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
    // overflow: hidden;
    width: 750px;
`;
const ModalCardBody = styled.div`
    padding: 22px;
    margin: 0;
    list-style-type: none;
    background: #fff;
    ul {
        padding: 0;
        list-style-type: none;
        text-align: left;
        margin: 10px 0;
        li {
            font-size: 14px;
            font-weight: 400;
            color: #999;
            line-height: 20px;
            span {
                font-weight: 700;
            }
        }
    }
`;

const ModalCardHead = styled.div`
    border-bottom: 1px solid #ed8154;
    background: #ed8154;
    padding: 8px 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
        font-size: 16px !important;
        color: #fff !important;
        margin: 0;
        font-weight: 600;
    }
    button {
        padding: 0;
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const ExportModalCardForm = styled.div`
    width: 100%;
    input {
        width: 100%;
        border: 1px solid #ddd;
        min-height: 40px;
        box-sizing: border-box;
        padding: 8px 12px;
        border-radius: 8px;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    label {
        font-size: 14px !important;
        color: #666 !important;
        margin: 0;
        width: 100%;
        text-align: left;
        display: block;
        margin-bottom: 6px;
        font-weight: 700;
    }
`;

const InputBox = styled.div`
    margin-bottom: 20px;
    & > div > div {
        border-color: #ddd;
    }
`;

const ExportModalCardButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 22px;

    button {
        font-size: 14px;
        color: #fff;
        background: #ed8154;
        border: 1px solid #ed8154;
        border-radius: 8px;
        overflow: hidden;
        padding: 8px 20px;
        min-width: 95px;
        margin-left: 12px;
        font-weight: 600;
        &:first-of-type {
            margin-left: 0;
            background: #fff;
            color: #ed8154;
        }
    }
`;

const InviteNewUser = (props) => {
    const { setInviteUser } = props;
    const dispatch = useDispatch();
    const [permissionGroup, setPermissionGroup] = useState("");
    const permissionGroupData = useSelector(
        (state) => state.Permissions.permissionGroupData
    );
    const initialValues = {
        name: "",
        email: "",
    };
    const userData = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        dispatch(fetchAllCompanies());
    }, []);
    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.email) {
            errors.email = "Email is required";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid Email";
        }

        if (!values.name) {
            errors.name = "name is required";
        }

        return errors;
    };
    // const dispatch = useDispatch();
    const submitForm = (values) => {
        const data = {
            ...values,
            permissionGroupId: permissionGroup,
            companyId: userData?.companyId,
        };
        dispatch(createUser(data));
        setPermissionGroup("");
        setInviteUser(false);
    };

    return (
        <React.Fragment>
            <ExportModalCard>
                <Formik
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={submitForm}
                >
                    {(formik) => {
                        const {
                            values,
                            handleChange,
                            handleSubmit,
                            errors,
                            touched,
                            handleBlur,
                            isValid,
                            dirty,
                        } = formik;
                        return (
                            <form onSubmit={handleSubmit}>
                                <ModalCardHead>
                                    <h6>Create New</h6>
                                    <Button>
                                        <Close
                                            onClick={() => setInviteUser(false)}
                                        />
                                    </Button>
                                </ModalCardHead>
                                <ModalCardBody>
                                    <ExportModalCardForm>
                                        <InputBox>
                                            <label>Name</label>
                                            <Input
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="name"
                                                id="name"
                                                required={true}
                                                type="name"
                                                className={
                                                    errors.name && touched.name
                                                        ? "input-error"
                                                        : null
                                                }
                                                placeholder="Enter your name"
                                            />
                                        </InputBox>
                                        <InputBox>
                                            <label>Email</label>
                                            <Input
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="email"
                                                id="email"
                                                required={true}
                                                type="text"
                                                placeholder="Enter your email"
                                                className={
                                                    errors.email &&
                                                    touched.email
                                                        ? "input-error"
                                                        : null
                                                }
                                            />
                                        </InputBox>
                                        <InputBox>
                                            <label>
                                                Assign Permission Group
                                            </label>
                                            <SearchDropDown
                                                name={permissionGroupData?.map(
                                                    (elem) => {
                                                        return {
                                                            ...elem,
                                                            type: "Select",
                                                        };
                                                    }
                                                )}
                                                setSelectedDataId={
                                                    setPermissionGroup
                                                }
                                            />
                                        </InputBox>
                                        {/* <InputBox>
                                            <label>Company</label>
                                            <SearchDropDown
                                                setSelectedDataId={(id) => {
                                                    setSelectedCompany(id);
                                                    setSelectedCompanyError("");
                                                }}
                                                defaultValue="Select Company"
                                                name={companiesData.map(
                                                    (elem) => {
                                                        return {
                                                            ...elem,
                                                            name: elem.companyName,
                                                            type: "Select",
                                                        };
                                                    }
                                                )}
                                            />
                                            {selectedCompanyError && (
                                                <span className="errorText">
                                                    {selectedCompanyError}
                                                </span>
                                            )}
                                        </InputBox> */}
                                    </ExportModalCardForm>
                                    <ExportModalCardButtons>
                                        <Button
                                            onClick={() => setInviteUser(false)}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            type="submit"
                                            // className={
                                            //   !(dirty && isValid) ? "disabled-btn" : "sb-button"
                                            // }
                                            disabled={!(dirty && isValid)}
                                            id="b-login"
                                        >
                                            Create
                                        </Button>
                                    </ExportModalCardButtons>
                                </ModalCardBody>
                            </form>
                        );
                    }}
                </Formik>
            </ExportModalCard>
        </React.Fragment>
    );
};

export default InviteNewUser;
