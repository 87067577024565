import { ReactComponent as HomeIcon } from "../../components/ui/styles/home.svg";

export const items = [
  { id: 1, to: "/home", Icon: HomeIcon },
  { id: 2, to: "/setting", label: "Settings" },
  { id: 3, to: "/legalsecurity", label: "Legal & Security" },
];

export const legalSecurityStaticData = [
  {
    title: "Acceptable Use Policy",
    lastDate: "20-04-2023",
    paragraph:
      "Our Acceptable Use Policy (AUP) is a set of rules and guidelines that outline the proper and ethical use of our system by your  authorized users.",
    path: "/legalsecurity/privacy-policy",
    description:
      <div>
        <h1>Acceptable Use Policy</h1>
	<h2>INTRODUCTION</h2>
	<p>This Acceptable Use Policy (“AUP”) is an integral part of the Q One Tech Terms of Service Agreement (“TOS”) and is incorporated by reference therein. Q One Tech may modify this AUP at any time. The modified AUP will become effective immediately upon sharing with their customers. <br/>
	Q One Tech reserves the sole and absolute right to interpret, apply, define and implement this AUP. Your services may be suspended or terminated for violation of this AUP in accordance with the TOS. Inquiries regarding this policy should be directed to support@qone-tech.com</p>
	<h2>NO MISSION CRITICAL OR HIGH RISK USE</h2>
	<p>The service is not fault-tolerant and is not designed, intended, or authorized for use in any mission critical, emergency, medical, life saving or life sustaining systems, or for any other application in which the failure of the service could lead to personal injury or death, or to physical or environmental damage.</p>
	<h2>ABUSE</h2>
	<p>You may not use Q One Techs systems to engage in, foster, or promote illegal, abusive, or irresponsible behavior, including but not limited to:</p>
	<ul><li>Interference with service to any user of the Q One Tech (or any other) network including, without limitation, mail bombing, flooding, deliberate attempts to overload a system, abnormal or improper use of Q One Tech API or websites (or use that puts excessive strain on our systems) and transmitting computer viruses and Trojan horses;</li>
	<li>Collecting or using email addresses, screen names or other identifiers without the consent of the person identified (including, without limitation, phishing, Internet scamming, password robbery, spidering, and harvesting);</li>
	<li>Collecting or using information without the consent of the owner of the information or distributing software that does the same;</li>
	<li>Probing, scanning, penetrating, reverse-engineering or testing the vulnerabilities of a Q One Tech network, service, system or device to breach, attempt to breach or collect information about security or authentication measures, or any similar or related activity, without Q One Tech’ express written consent.</li>
	<li>Any conduct that causes or is likely to result in retaliation against the Q One Tech network or website, or Q One Tech’ employees, officers or other agents, including but not limited to engaging in behavior that results in any server being the target of a denial of service attack.</li>
	<li>Any conduct that is deemed abusive or malicious as determined by the sole discretion of Q One Tech.</li></ul>
	<h2>PROHIBITED CONTENT</h2>
	<p>You may not publish, transmit or store on or via Q One Tech’ network and equipment any content or links to any content that Q One Tech reasonably believes:</p>
	<ul><li>constitutes, depicts, fosters, promotes or relates in any manner to adult oriented material or activity including but not limited to pornography;</li>
	<li>is excessively violent, incites violence, threatens violence, or contains harassing content or hate speech;</li>
	<li>creates a risk to a person’s safety or health, creates a risk to public safety or health, compromises national security, or interferes with an investigation by law enforcement;</li>
	<li>exposes trade secrets or other confidential or proprietary information of another person;</li>
	<li>infringes on another person’s copyright, trade or service mark, patent, or other property right;</li>
	<li>promotes illegal drugs, violates export control laws, or illegal arms trafficking;</li>
	<li>is otherwise illegal or solicits conduct that is illegal under laws applicable to you or to Q One Tech;</li>
	<li>is otherwise malicious or fraudulent, as determined by the sole discretion of Q One Tech.</li></ul>
	<p>In addition, Q One Tech has determined that certain types of mail generate higher than normal abuse and feedback loop complaints, and in order to protect the reputation of our network and to achieve the highest rates of deliverability for all of our customers, we are unable to provide service to organizations in the following industries:</p>
	<ul><li>Payday loans</li>
	<li>Debt collection</li>
	<li>Gambling</li>
	<li>Affiliate Marketing</li>
	<li>Get rich quick, Ponzi, or pyramid schemes</li>
	<li>Work from home or make money online opportunities</li>
	<li>Day trading or penny stocks</li></ul>
	<h2>ALL EMAIL</h2>
	<p>You must comply with all applicable laws and regulations including but not limited to CAN-SPAM (Controlling the Assault of Non-Solicited Pornography And Marketing) and CASL (Canadian Anti-Spam Law). In addition, your email must meet the following requirements:</p>
	<ul><li>Your email must claim to originate from an address at a domain that is currently registered to you, under your control, or you have the permission to transmit on behalf-of. The domain must not conceal the identity of the registrant;</li>
	<li>You may not send bulk text messages via email;</li>
	<li>Your email may not contain any false, misleading or deceptive information in its content or header, and you may not attempt to obscure or hide the source of the email.</li>
	<li>Your email must not be characteristic of spam as determined by the sole discretion of Q One Tech.</li></ul>
	<h2>TRANSACTIONAL EMAIL</h2>
	<p>An email message is considered transactional if its primary purpose meets one or more of the following requirements:</p>
	<ul><li>facilitates or confirms a commercial transaction that the recipient already has initiated and/or agreed to;</li>
	<li>gives warranty, recall, safety, or security information about a product or service;</li>
	<li>gives information about a change in terms or features or account balance information regarding a membership, subscription, account, loan or other ongoing commercial relationship;</li>
	<li>provides information about an employment relationship or employee benefits; or</li>
	<li>delivers goods or services as part of a transaction that the recipient already has agreed to.</li></ul>
	<p>For all recipients that you have a transactional relationship with, you must retain:</p>
	<ul><li>Evidence of the details each recipient’s transactional relationship in a form that can be promptly produced on request, and you honor the recipient’s and Q One Tech’ requests to produce this evidence within 48 hours of receipt of the request;</li></ul>
	<li>Details of the transactional relationship including the names of the parties in the relationship, a description of the relationship, the date the relationship started, how the email address was obtained, and if possible, the dates of the first and most recent transaction.</li>
	<h2>BULK OR COMMERCIAL EMAIL</h2>
	<p>Commercial email is defined as any email message for which the primary purpose is the commercial advertisement or promotion of a commercial product, website, or service. Our requirements for commercial email include:</p>
	<ul><li>Your intended recipients have given their consent to receive email specifically from you via some affirmative means, such as an opt-in procedure;</li>
	<li>Your procedures for seeking consent include reasonable means to ensure that the person giving consent is the owner of the email address for which consent is given;</li>
	<li>You retain evidence of each recipient’s consent in a form that can be promptly produced on request, and you honor recipient’s and Q One Tech’ requests to produce consent evidence within 48 hours of receipt of the request. Evidence should contain at a minimum the date, time and place of the consent and if the consent was given online, the recipients IP address used to give the consent. All evidence must be satisfactory as determined by the sole discretion of Q One Tech.</li>
	<li>You have procedures in place that allow a recipient to revoke their consent – such as an unsubscribe link in the body of the email and you must honor revocations of consent and notify the recipient within 48 hours;</li>
	<li>You must have a Privacy Policy posted for each domain associated with the mailing;</li>
	<li>You must post an email address for complaints (such as abuse@yourdoman.com) in a conspicuous place on any website associated with the email and you must promptly respond to messages sent to that address;</li>
	<li>You must include the recipient’s e-mail address in the body of the message or in the “TO” line of the e-mail.</li>
	<li>Your email must not generate excessive blacklistings or any critical blacklisting as determined by the sole discretion of Q One Tech.</li>
	<li>Your email must not generate excessive complaints as determined by the sole discretion of Q One Tech.</li>
	<li>Your email list must be valid and not generate excessive failures as determined by the sole discretion of Q One Tech.</li></ul>

      </div>,
    path: "/legalsecurity/acceptable-use-policy",
    options: [],
  },
  {
    title: "Data Processing Agreement",
    lastDate: "20-04-2023",
    paragraph:
      "The Data Processing Agreement (DPA) outlines the terms and conditions for processing personal data in compliance with data protection laws.",
    path: "/legalsecurity/terms-conditions",
    description: (
      <div>
        <h1>Data Processing Agreement</h1>
        <p>
          This Data Processing Agreement ("Agreement") forms part of the
          Contract for Services ("Principal Agreement"), Terms of Service, Terms
          of Use, or any other agreement about the delivery of services (the
          "Agreement”) between and Q One Tech Limited, King Street House,
          15Upper King Street, Norwich, NR3 1RB, United Kingdom (the “Data
          Processor”) (together as the “Parties”) and the Customer named in such
          Agreement or identified below to reflect the parties' agreement about
          the Processing of Personal Data (as those terms are defined below)
        </p>
        <p>WHEREAS</p>
        <ul>
          <li>The Company acts as a Data Controller.</li>
          <li>
            The Company wishes to subcontract certain Services, which imply the
            processing of personal data, to the Data Processor.
          </li>
          <li>
            The Parties seek to implement a data processing agreement that
            complies with the requirements of the current legal framework in
            relation to data processing and with the Regulation (EU) 2016/679 of
            the European Parliament and of the Council of 27 April 2016 on the
            protection of natural persons with regard to the processing of
            personal data and on the free movement of such data, and repealing
            Directive 95/46/EC (General Data Protection Regulation).
          </li>
          <li>The Parties wish to lay down their rights and obligations.</li>
        </ul>
        <h2>IT IS AGREED AS FOLLOWS:</h2>
        <h3>1. Definitions and Interpretation</h3>
        <ul class="no-bullets-lvl1">
          <li>
            1.1 Unless otherwise defined herein, capitalized terms and
            expressions used in this Agreement shall have the following meaning:
            <ul class="no-bullets-lvl2">
              <li>
                1.1.1 "Agreement" means this Data Processing Agreement and all
                Schedules;
              </li>
              <li>
                1.1.2 "Company Personal Data" means any Personal Data Processed
                by a Contracted Processor on behalf of Company pursuant to or in
                connection with the Principal Agreement;
              </li>
              <li>1.1.3 "Contracted Processor" means a Subprocessor;</li>
              <li>
                1.1.4 "Data Protection Laws" means EU Data Protection Laws and,
                to the extent applicable, the data protection or privacy laws of
                any other country;
              </li>
              <li>1.1.5 "EEA" means the European Economic Area;</li>
              <li>
                1.1.6 "EU Data Protection Laws" means EU Directive 95/46/EC, as
                transposed into domestic legislation of each Member State and as
                amended, replaced or superseded from time to time, including by
                the GDPR and laws implementing or supplementing the GDPR;
              </li>
              <li>
                1.1.7 "GDPR" means EU General Data Protection Regulation
                2016/679;
              </li>
              <li>
                1.1.8 "Data Transfer" means:
                <ul class="no-bullets-lvl3">
                  <li>
                    1.1.8.1 a transfer of Company Personal Data from the Company
                    to a Contracted Processor; or
                  </li>
                  <li>
                    1.1.8.2 an onward transfer of Company Personal Data from a
                    Contracted Processor to a subcontracted Processor, or
                    between two establishments of a Contracted Processor, in
                    each case, where such transfer would be prohibited by Data
                    Protection Laws (or by the terms of data transfer agreements
                    put in place to address the data transfer restrictions of
                    Data Protection Laws);
                  </li>
                </ul>
              </li>
              <li>
                1.1.9 “Services" means the Q One Platform support, Panel
                Management, Project Management, Survey Scripting services
                Company provides.
              </li>
              <li>
                1.1.10 "Subprocessor" means any person appointed by or on behalf
                of Processor to process Personal Data on behalf of the Company
                in connection with the Agreement.
              </li>
            </ul>
          </li>
          <li>
            1.2 The terms, "Commission", "Controller", "Data Subject", "Member
            State", "Personal Data", "Personal Data Breach", "Processing" and
            "Supervisory Authority" shall have the same meaning as in the GDPR,
            and their cognate terms shall be construed accordingly.
          </li>
        </ul>
        <h3>2. Processing of Company Personal Data</h3>
        <ul class="no-bullets-lvl1">
          <li>
            2.1 Processor shall:
            <ul class="no-bullets-lvl2">
              <li>
                2.1.1 comply with all applicable Data Protection Laws in the
                Processing of Company Personal Data; and
              </li>
              <li>
                2.1.2 not Process Company Personal Data other than on the
                relevant Company’s documented instructions.
              </li>
            </ul>
          </li>
          <li>
            2.2 The Company instructs Processor to process Company Personal
            Data.
          </li>
        </ul>
        <h3>3. Processor Personnel</h3>
        <p>
          Processor shall take reasonable steps to ensure the reliability of any
          employee, agent or contractor of any Contracted Processor who may have
          access to the Company Personal Data, ensuring in each case that access
          is strictly limited to those individuals who need to know / access the
          relevant Company Personal Data, as strictly necessary for the purposes
          of the Principal Agreement, and to comply with Applicable Laws in the
          context of that individual's duties to the Contracted Processor,
          ensuring that all such individuals are subject to confidentiality
          undertakings or professional or statutory obligations of
          confidentiality.
        </p>
        <h3>4. Security</h3>
        <ul class="no-bullets-lvl1">
          <li>
            4.1 Taking into account the state of the art, the costs of
            implementation and the nature, scope, context and purposes of
            Processing as well as the risk of varying likelihood and severity
            for the rights and freedoms of natural persons, Processor shall in
            relation to the Company Personal Data implement appropriate
            technical and organizational measures to ensure a level of security
            appropriate to that risk, including, as appropriate, the measures
            referred to in Article 32(1) of the GDPR.
          </li>
          <li>
            4.2 In assessing the appropriate level of security, Processor shall
            take account in particular of the risks that are presented by
            Processing, in particular from a Personal Data Breach.
          </li>
        </ul>
        <h3>5. Subprocessing</h3>
        <p>
          Processor shall not appoint (or disclose any Company Personal Data to)
          any Subprocessor unless required or authorized by the Company.
        </p>
        <h3>6. Data Subject Rights</h3>
        <ul class="no-bullets-lvl1">
          <li>
            6.1 Taking into account the nature of the Processing, Processor
            shall assist the Company by implementing appropriate technical and
            organizational measures, insofar as this is possible, for the
            fulfilment of the Company obligations, as reasonably understood by
            Company, to respond to requests to exercise Data Subject rights
            under the Data Protection Laws.
          </li>
          <li>
            6.2 Processor shall:
            <ul class="no-bullets-lvl2">
              <li>
                6.2.1 promptly notify Company if it receives a request from a
                Data Subject under any Data Protection Law in respect of Company
                Personal Data; and
              </li>
              <li>
                6.2.2 ensure that it does not respond to that request except on
                the documented instructions of Company or as required by
                Applicable Laws to which the Processor is subject, in which case
                Processor shall to the extent permitted by Applicable Laws
              </li>
              <li>
                6.2.3 inform Company of that legal requirement before the
                Contracted Processor responds to the request.
              </li>
            </ul>
          </li>
        </ul>
        <h3>7. Personal Data Breach</h3>
        <ul class="no-bullets-lvl1">
          <li>
            7.1 Processor shall notify Company without undue delay upon
            Processor becoming aware of a Personal Data Breach affecting Company
            Personal Data, providing Company with sufficient information to
            allow the Company to meet any obligations to report or inform Data
            Subjects of the Personal Data Breach under the Data Protection Laws.
          </li>
          <li>
            7.2 Processor shall co-operate with the Company and take reasonable
            commercial steps as are directed by Company to assist in the
            investigation, mitigation and remediation of each such Personal Data
            Breach.
          </li>
        </ul>
        <h3>8. Data Protection Impact Assessment and Prior Consultation</h3>
        <p>
          Processor shall provide reasonable assistance to the Company with any
          data protection impact assessments, and prior consultations with
          Supervising Authorities or other competent data privacy authorities,
          which Company reasonably considers to be required by article 35 or 36
          of the GDPR or equivalent provisions of any other Data Protection Law,
          in each case solely in relation to Processing of Company Personal Data
          by, and taking into account the nature of the Processing and
          information available to, the Contracted Processors.
        </p>
        <h3>9. Deletion or return of Company Personal Data,</h3>
        <ul class="no-bullets-lvl1">
          <li>
            9.1 Subject to this section 9 Processor shall promptly and in any
            event within 10 business days of the date of cessation of any
            Services involving the Processing of Company Personal Data (the
            "Cessation Date"), delete and procure the deletion of all copies of
            those Company Personal Data.
          </li>
          <li>
            9.2 Processor shall provide written certification to Company that it
            has fully complied with this section 9 within 10 business days of
            the Cessation Date.
          </li>
        </ul>
        <h3>10. Audit rights</h3>
        <ul class="no-bullets-lvl1">
          <li>
            1.1 Subject to this section 10, Processor shall make available to
            the Company on request all information necessary to demonstrate
            compliance with this Agreement, and shall allow for and contribute
            to audits, including inspections, by the Company or an auditor
            mandated by the Company in relation to the Processing of the Company
            Personal Data by the Contracted Processors.
          </li>
          <li>
            10.2 Information and audit rights of the Company only arise under
            section 10.1 to the extent that the Agreement does not otherwise
            give them information and audit rights meeting the relevant
            requirements of Data Protection Law.
          </li>
        </ul>
        <h3>11. Data Transfer</h3>
        <p>
          The Processor may not transfer or authorize the transfer of Data to
          countries outside the EU and/or the European Economic Area (EEA)
          without the prior written consent of the Company. If personal data
          processed under this Agreement is transferred from a country within
          the European Economic Area to a country outside the European Economic
          Area, the Parties shall ensure that the personal data are adequately
          protected. To achieve this, the Parties shall, unless agreed
          otherwise, rely on EU approved standard contractual clauses for the
          transfer of personal data.
        </p>
        <h3>12. General Terms</h3>
        <ul class="no-bullets-lvl1">
          <li>
            12.1 Confidentiality. Each Party must keep this Agreement and
            information it receives about the other Party and its business in
            connection with this Agreement (“Confidential Information”)
            confidential and must not use or disclose that Confidential
            Information without the prior written consent of the other Party
            except to the extent that:
          </li>
          <li>
            disclosure is required by law;
            <ul class="no-bullets-lvl2">
              <li>
                12.1.2. the relevant information is already in the public
                domain.
              </li>
            </ul>
          </li>
          <li>
            12.2. Notices. All notices and communications given under this
            Agreement must be in writing and will be delivered personally, sent
            by post or sent by email to the address or email address set out in
            the heading of this Agreement at such other address as notified from
            time to time by the Parties changing address.
          </li>
        </ul>
        <h3>13. Governing Law and Jurisdiction</h3>
        <ul class="no-bullets-lvl1">
          <li>
            13.1. This Agreement is governed by the laws of the United Kingdom.
          </li>
          <li>
            13.2. Any dispute arising in connection with this Agreement, which
            the Parties will not be able to resolve amicably, will be submitted
            to the exclusive jurisdiction of the courts of Norwich, subject to
            possible appeal to Norwich.
          </li>
        </ul>
      </div>
    ),
    options: [],
  },
  {
    title: "Data Breach Notification Procedure",
    lastDate: "20-04-2023",
    paragraph:
      "Our data breach procedure explains the steps to be taken in the event of a suspected or confirmed data breach, with the aim of minimizing the impact and mitigating potential harm to affected individuals, your organization and Q One Tech.",
    path: "/legalsecurity/data-breach-notification-procedure",
    description:
    <div>
      <h1>Personal Data Breach Notification Procedure</h1>
	<p>This is a checklist of what should be done when there is a suspected security breach involving personal data.  This checklist should be read in conjunction with our Personal Data Breach Notification Policy.</p>
  <h2>1.)	 Report Data Security Breach to the Data Protection Officer</h2>
	<p>If an employee suspects that there has been an event that results in a data security breach, this must be reported to our Data Protection Officer, who is Roman Schönsee, without any delay.  The employee must not delay in contacting the Data Protection Officer as time runs from the moment the employee knows about the possible breach.  Telling the Data Protection Officer quickly allows further investigations to be carried out by the Data Protection Officer without time running out. Since the maximum time allowed is 72 hours, time is of the essence.<br/><br/>
	Our Data Protection Officer can be contacted at roman.schoensee@qone-tech.com and +31 638430251.  The person notifying them needs to be sure the message has reached them within a couple of hours at most.  Sending an email or leaving a voicemail is not, alone, sufficient.  If there is no response, then they must immediately escalate the problem to their manager or a member of the senior management team.  There is no-one who is too important to be disturbed with this information.</p>
  <h2>2.)	Data Protection Officer Priorities</h2>
	<h3>Notify Data Security Breach Team</h3>
	<p>We have nominated appropriate individuals (and in some cases deputies) from different departments who have the necessary skills to identify data security breaches and make decisions quickly on notification.  Each team includes at least one senior manager.<br/>
	The Data Protection Officer must notify the Data Security Breach Team within an hour of being informed and should organise a team meeting as soon as possible and in any event no later than 4 working hours after the Data Protection Officer is notified.<br/><br/></p>
	<h3>Notify the data controller (where appropriate)</h3>
	<p>If the breach involved personal data that the organisation is processing on behalf of any other organisation, the Data Protection Officer must inform the controller of the relevant personal data without undue delay.</p>
	<h3>Information Collection</h3>
<p>  The Data Protection Officer should start collecting information about the potential security breach straightaway.  The Data Protection Officer needs to obtain sufficient information to establish the nature and extent of the breach and the possible implications.</p>
  <h3>Stopping or Mitigating the Effects of the Breach</h3>
<p>  As well as collecting information, the Data Protection Officer should be liaising immediately with appropriate members of staff to mitigate the risk of the data breach to the individuals (e.g. retrieving backups in the event of data loss, preventing further unauthorised access by third parties etc).  
  Any action taken now could be the difference between the breach having a major affect on the individuals concerned (and the organisation as a whole) or it being contained with no major, long-term, effects.
  If the breach has been caused by a processor, or where a processor is needed to assist with the investigations, then the Data Protection Officer should be liaising with the processor to obtain the necessary information. </p>
  <h2>3.)	Data Security Breach Team Meeting</h2>
  <h3>Assessing the Breach</h3>
	<p>The Data Security Breach Team should meet and consider all the information available to it at that moment in time. This assessment should go ahead without undue delay because, if it is necessary to notify the ICO, this must be done within 72 hours of knowledge of the breach.  Accordingly, the Data Security Breach Team should not delay the decision in order to wait for further information, unless it does not have enough information to make a decision.<br/>
  The Data Security Breach Team should also involve representatives from the data processor if the breach was due to the actions of a data processor.  However, the decision in relation to risk, remains with the Data Security Breach Team.<br/>
  The risk assessment should be done in accordance with the guidance in the Data Security Breach Procedure. The risk to individuals because of a breach should then be assessed as follows:</p>
	<ul>
  <li>No risk to the rights and freedoms of individuals;</li>
  <li>Likely risk to the rights and freedoms of individuals; or</li>
  <li>Likely high risk to the rights and freedoms of individuals.</li>
	</ul>
 <h2>4.)	Who to Notify about the Breach</h2>
  <h3>No Risk to the Rights and Freedoms of Individuals</h3>
  <p>If the Data Security Breach Team has assessed the risk and believes that there is no risk to the individuals concerned, then it needs to document the breach and then look at what further action needs to be taken in order to ensure that it does not recur.  The Data Security Breach Team should make detailed records, just in case the ICO is notified later and decides that the breach should be investigated further.</p>
  <h3>Likely Risk to the Rights and Freedoms of Individuals</h3>
  <p>If the Security Breach Team has assessed the risk and believes that there is a risk to the rights and freedoms of individuals, then they must notify the ICO within 72 hours of becoming aware of the risk. They must provide detailed information about the breach and, as a minimum, the information detailed in the Data Security Breach Policy.</p>
  <h3>Likely High Risk to the Rights and Freedoms of Individuals</h3>
  <p>If the Data Security Breach Team has assessed the risk and believes that there is a high risk to the rights and freedoms of individuals, then they must notify the ICO (again within the 72 hours) and the individuals concerned in accordance with the Data Security Breach Policy.</p>
  <h2>5.)	What else needs to be done?</h2>
  <h3>Audit and Necessary Improvements</h3>
	<p>Once the initial investigation has taken place and the Data Security Breach Team has made a decision on notification, then a full audit should take place regardless of whether the breach was notified.  This audit needs to include a review of whether the appropriate security policies and procedures were in place and if so, whether they were followed. <br/>
	Where security was found to be inadequate then the Data Security Breach Team (in conjunction with the appropriate staff members) need to consider what action needs to be taken to raise standards to ensure compliance with the GDPR.</p>
  <h3>Was a Processor involved?</h3>
  <p>If the data security breach was caused by an external processor, then the Data Security Breach Team should (with appropriate advice) consider the data protection and security provisions in the contract with that processor, whether there is a potential claim against the data processor and, if there are grounds for termination, whether this is appropriate.<br/>
  If the contract with the processor continues then the processor must audit their procedures and work out why the breach occurred (either with or without the assistance of the Data Security Breach Team). The results of the audit need to be provided back to the Data Security Breach Team.  The data processor must amend Its policies and procedures to comply with the GDPR and must provide evidence of doing so.</p>
  <h3>Documenting the Breach</h3>
  <p>All data breaches should be documented whether they are reported to the ICO and the individual or not.  The GPDR makes this a requirement.  Accordingly, a register of data breaches needs to be kept.</p>
    </div>,
    options: [
      { id: 1, name: "Active" },
      { id: 2, name: "Do Not Active" },
    ],
  },
  {
    title: "Information Security Policy",
    lastDate: "20-04-2023",
    path: "/legalsecurity/information-security-policy",
    paragraph:
      " Our Information Security Policy (ISP) is a set of guidelines and procedures designed to ensure the confidentiality, integrity, and availability of the sensitive information and assets hosted on our servers, in order to minimize the risk of unauthorized access, use, disclosure, disruption, modification, or destruction.",
    description: (
      <div>
        <h1>Information Security Policy</h1>
        <h2>Introduction</h2>
        <p>
          Information security is a holistic discipline, meaning that its
          application, or lack thereof, affects all facets of an organization or
          enterprise. The goal of the Q One Tech Ltd.Information Security
          Program is to protect the Confidentiality, Integrity, and Availability
          of the data employed within the organization while providing value to
          the way we conduct business. Protection of the Confidentiality,
          Integrity, and Availability are basic principles of information
          security, and can be defined as:
        </p>
        <ul>
          <li>
            Confidentiality – Ensuring that information is accessible only to
            those entities that are authorized to have access, many times
            enforced by the classic “need-to-know” principle.
          </li>
          <li>
            Integrity – Protecting the accuracy and completeness of information
            and the methods that are used to process and manage it.
          </li>
          <li>
            Availability – Ensuring that information assets (information,
            systems, facilities, networks, and computers) are accessible and
            usable when needed by an authorized entity.
          </li>
        </ul>
        <p>
          Q One Tech Ltd. has recognized that our business information is a
          critical asset and as such our ability to manage, control, and protect
          this asset will have a direct and significant impact on our future
          success. This document establishes the framework from which other
          information security policies may be developed to ensure that the
          enterprise can efficiently and effectively manage, control and protect
          its business information assets and those information assets entrusted
          to Q One Tech Ltd. by its stakeholders, partners, customers and other
          third-parties. The Q One Tech Ltd. Information Security Program is
          built around the information contained within this policy and its
          supporting policies.
        </p>
        <h2>Purpose</h2>
        <p>
          The purpose of the Q One Tech Ltd. Information Security Policy is to
          describe the actions and behaviors required to ensure that due care is
          taken to avoid inappropriate risks to Q One Tech Ltd., its business
          partners, and its stakeholders.
        </p>
        <h2>Audience</h2>
        <p>
          The Q One Tech Ltd. Information Security Policy applies equally to any
          individual, entity, or process that interacts with any Q One Tech Ltd.
          Information Resource.
        </p>
        <h2>Responsibilities</h2>
        <h3>Executive Management </h3>
        <ul>
          <li>
            Ensure that an appropriate risk-based Information Security Program
            is implemented to protect the confidentiality, integrity, and
            availability of all Information Resources collected or maintained by
            or on behalf of Q One Tech Ltd.
          </li>
          <li>
            Ensure that information security processes are integrated with
            strategic and operational planning processes to secure the
            organization’s mission.
          </li>
          <li>
            Ensure adequate information security financial and personnel
            resources are included in the budgeting and/or financial planning
            process.
          </li>
          <li>
            Ensure that the Security Team is given the necessary authority to
            secure the Information Resources under their control within the
            scope of the Q One Tech Ltd. Information Security Program.
          </li>
          <li>
            Designate an Information Security Officer and delegate authority to
            that individual to ensure compliance with applicable information
            security requirements.
          </li>
          <li>
            Ensure that the Information Security Officer, in coordination with
            the Security Team, reports annually to Executive Management on the
            effectiveness of the Q One Tech Ltd. Information Security Program.
          </li>
        </ul>
        <h3>Information Security Officer</h3>
        <ul>
          <li>
            Chair the Security Team and provide updates on the status of the
            Information Security Program to Executive Management.
          </li>
          <li>
            Manage compliance with all relevant statutory, regulatory, and
            contractual requirements.
          </li>
          <li>
            Participate in security related forums, associations and special
            interest groups.
          </li>
          <li>
            Assess risks to the confidentiality, integrity, and availability of
            all Information Resources collected or maintained by or on behalf of
            Q One Tech Ltd.
          </li>
          <li>
            Facilitate development and adoption of supporting policies,
            procedures, standards, and guidelines for providing adequate
            information security and continuity of operations.
          </li>
          <li>
            Ensure that Q One Tech Ltd. has trained all personnel to support
            compliance with information security policies, processes, standards,
            and guidelines. Train and oversee personnel with significant
            responsibilities for information security with respect to such
            responsibilities.
          </li>
          <li>
            Ensure that appropriate information security awareness training is
            provided to District personnel, including contractors.
          </li>
          <li>
            Implement and maintain a process for planning, implementing,
            evaluating, and documenting remedial action to address any
            deficiencies in the information security policies, procedures, and
            practices of Q One Tech Ltd..
          </li>
          <li>
            Develop and implement procedures for testing and evaluating the
            effectiveness of the Q One Tech Ltd. Information Security Program in
            accordance with stated objectives.
          </li>
          <li>
            Develop and implement a process for evaluating risks related to
            vendors and managing vendor relationships.
          </li>
          <li>
            Report annually, in coordination with the Security Team, to
            Executive Management on the effectiveness of the Q One Tech Ltd.
            Information Security Program, including progress of remedial
            actions.
          </li>
        </ul>
        <h3>Information Security Team</h3>
        <ul>
          <li>
            Ensure compliance with applicable information security requirements.
          </li>
          <li>
            Formulate, review and recommend information security policies.
          </li>
          <li>
            Approve supporting procedures, standards, and guidelines related to
            information security.
          </li>
          Provide clear direction and visible management support for information
          security initiatives.
          <li>
            Assess the adequacy and effectiveness of the information security
            policies and coordinate the implementation of information security
            controls.
          </li>
          <li>
            Ensure that ongoing security activities are executed in compliance
            with policy.
          </li>
          <li>
            Review and manage the information security policy waiver request
            process.
          </li>
          <li>
            Review information security incident information and recommend
            follow-up actions.
          </li>
          <li>
            Promote information security education, training, and awareness
            throughout Q One Tech Ltd., and initiate plans and programs to
            maintain information security awareness.
          </li>
          <li>
            Report annually, in coordination with the Security Officer, to
            Executive Management on the effectiveness of the Q One Tech Ltd.
            Information Security Program, including progress of remedial
            actions.
          </li>
        </ul>
        <h3>All Employees, Contractors, and Other Third-Party Personnel</h3>
        <ul>
          <li>
            Understand their responsibilities for complying with the Q One Tech
            Ltd. Information Security Program.
          </li>
          <li>
            Use Q One Tech Ltd. Information Resources in compliance with all Q
            One Tech Ltd. Information Security Policies.
          </li>
          <li>
            Seek guidance from the Information Security Team for questions or
            issues related to information security.
          </li>
        </ul>
        <h2>Policy</h2>
        <ul>
          <li>
            Q One Tech Ltd. maintains and communicates an Information Security
            Program consisting of topic-specific policies, standards, procedures
            and guidelines that:
            <ul>
              <li>
                Serve to protect the Confidentiality, Integrity, and
                Availability of the Information Resources maintained within the
                organization using administrative, physical and technical
                controls.
              </li>
              <li>
                Provide value to the way we conduct business and support
                institutional objectives.
              </li>
              <li>
                Comply with all regulatory and legal requirements, including:
                <ul>
                  <li>GDPR</li>
                  <li>
                    Information Security best practices, including ISO 27002 and
                    NIST CSF,
                  </li>
                  <li>Contractual agreements,</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            The information security program is reviewed no less than annually
            or upon significant changes to the information security environment.
          </li>
        </ul>
        <h2>References</h2>
        <ul>
          <li>https://gdpr-info.eu/art-32-gdpr/</li>
          <li>ISO 27002: 5, 6, 7, 18</li>
          <li>NIST CSF: ID.AM, ID.BE, ID.GV, PR.AT, PR.IP</li>
        </ul>
        <h2>Enforcement</h2>
        <p>
          Personnel found to have violated this policy may be subject to
          disciplinary action, up to and including termination of employment,
          and related civil or criminal penalties. Any vendor, consultant, or
          contractor found to have violated this policy may be subject to
          sanctions up to and including removal of access rights, termination of
          contract(s), and related civil or criminal penalties.
        </p>
      </div>
    ),
    options: [],
  },
  {
    title: "Back Up and Data Retention Policy",
    lastDate: "20-04-2023",
    paragraph:
      "The Back Up and Data Retention Policy is a plan that defines how Q One Tech creates and stores data backups, and for how long it retains those backups for data protection, recovery, and compliance purposes.",
    path: "/legalsecurity/back-up-and-data-retention-policy",
    description: (
      <div>
        <h1>Data Retention Policy</h1>
        <p>
          Q One Tech holds many different types of electronic records containing
          a variety of data, including our clients panel member and clients
          client details, employee information as well as confidential
          information about the organisation and how it operates. These records
          are a vital part of our and our clients business and it is important
          that we ensure that we protect the records and information contained
          in them in order to ensure the smooth running of the business and also
          to comply with the requirements laid down by law.
          <br />
          We have outlined the procedures laid down for retention, review and
          destruction of records held by us. The purpose of this policy is to
          ensure that we only hold records for as long as is necessary and that
          once they are no longer required that they are destroyed in accordance
          with the procedures laid down in this policy.
          <br />
          This policy supplements our data security and data protection policies
          and was last reviewed 29 September 2020.
        </p>
        <h2>Who does this Policy apply to?</h2>
        <p>
          This policy applies to all employees of the organisation who are
          required to be familiar with and comply with its requirements. <br />
          The person within the organisation responsible for data Protection is
          Roman Schoensee (the Responsible Person) and they are responsible for
          maintaining this policy and ensuring compliance its terms. They can
          advise on any related issues.
        </p>
        <h2>What is a Document?</h2>
        <p>
          This policy covers electronic records and those (if any) held in a
          manual filing system. It includes emails, databases and records
          contained in any other system operated by the organisation. The
          records can be created by the organisation or created elsewhere and
          sent to us.
        </p>
        <h2>Retention of Records</h2>
        <p>
          The retention schedule below lists the types of records that our
          organisation holds. We are required by law not to hold records for any
          longer than is necessary and therefore we have set retention periods
          below. <br />
          The retention periods are based on time limits set by law for some
          records and where there is no statutory period we have based the time
          limit on how long we think the record will be required. Once the
          statutory period has been reached then the records should be destroyed
          unless there is a good reason to keep them (e.g. ongoing legal
          proceedings). In such events, the Responsible Person should make the
          final decision on whether records are retained beyond the minimum
          retention period.
        </p>
        <h2>Destruction of Records</h2>
        <p>
          Once the records have reached their minimum retention period, then
          they must be permanently destroyed unless there is a good reason to
          keep them and the Responsible Person has agreed on a further retention
          period.
        </p>
        <h2>Manual records</h2>
        <p>Manual records should be shredded.</p>
        <h2>Electronic records</h2>
        <ul>
          <li>
            Emails – emails not forming part of a transaction record are deleted
            after 2 years
          </li>
          <li>
            Databases - All out of date records are automatically purged by the
            system using the SQL DELETE command. This deletes the row(s) which
            purges the data immediately from the database itself. A back-up of
            the record will be removed 30 days later, automatically.
          </li>
        </ul>
        <h2>Records containing Personal Information</h2>
        <p>
          The organisation is required to only hold personal information for as
          long as is necessary. This is a requirement of the data protection
          legislation. There are exceptions to this, for example the records
          listed above that have statutory retention periods and if there are
          ongoing court proceedings. We have considered the personal information
          that we hold and have listed below the organisational retention
          periods for the different types of personal information. If you wish
          to exceed the periods you must speak to the Responsible Person who
          will advise you further.
        </p>
        <table width="100%" border="1">
          <tbody>
            <tr>
              <th scope="col">Type of record</th>
              <th scope="col">Description of document</th>
              <th scope="col">Retention period</th>
            </tr>
            <tr>
              <td>Prospective Clients</td>
              <td>
                Details of those contacting us for information – but who did not
                order{" "}
              </td>
              <td>Delete 6 months after last contact from prospect</td>
            </tr>
            <tr>
              <td>Clients</td>
              <td>Details client contact and the services ordered</td>
              <td>7 years after last order</td>
            </tr>
            <tr>
              <td>Emails</td>
              <td>Correspondence with clients and panel members.</td>
              <td>
                Emails not forming part of a transaction record are deleted
                after 2 years
              </td>
            </tr>
          </tbody>
        </table>
        <h2>Records created by our clients</h2>
        <p>
          Q One Tech is required to only hold personal information for as long
          as is necessary for their clients to maintain their business. We have
          considered the personal information that we hold and have listed below
          the organisational retention periods for the different types of
          personal information. If you wish to exceed the periods you must speak
          to the Responsible Person who will advise you further.{" "}
        </p>
        <table width="100%" border="1">
          <tbody>
            <tr>
              <th scope="col">Type of record</th>
              <th scope="col">Description of document</th>
              <th scope="col">Retention period</th>
            </tr>
            <tr>
              <td>Non-personal system items</td>
              <td>
                Items created in the Q One platform which don’t contain personal
                information:
                <ul>
                  <li>Panels</li>
                  <li>Projects</li>
                  <li>Proposals</li>
                  <li>Tasks</li>
                  <li>Survey versions</li>
                  <li>Invites</li>
                  <li>Other </li>
                </ul>
              </td>
              <td>
                Stored in the system until client deletes them. <br />
                After client deletion the records are ‘marked’ as deleted and
                not removed from the database.
              </td>
            </tr>
            <tr>
              <td>Contacts</td>
              <td>
                Personal information about clients’ customers and suppliers
                added to Contacts
              </td>
              <td>
                Stored in the system until client deletes them. <br />
                After client deletion the records are ‘marked’ as deleted and
                not removed from the database.
              </td>
            </tr>
            <tr>
              <td>Panel Members</td>
              <td>
                Panel members and their personal information added to the system
                by clients.
              </td>
              <td>
                Stored in the system until client deletes them. <br />
                After client deletion the records are ‘marked’ as deleted and
                not removed from the database.
                <br />
                Destruction of record as set by client under panels.
              </td>
            </tr>
            <tr>
              <td>Project Emails</td>
              <td>Emails recorded in projects</td>
              <td>
                Stored in the system until client deletes them. <br />
                After client deletion the records are ‘marked’ as deleted and
                not removed from the database.
              </td>
            </tr>
          </tbody>
        </table>
        <h1>Backup Policy</h1>
        <p>
          The purpose of this policy is to define the need for performing
          periodic computer system backups to ensure that mission critical
          administrative applications, data and archives and applications,
          users' data and archives are adequately preserved and protected
          against data loss and destruction.{" "}
        </p>
        <h2>Applicability</h2>
        <p>
          This backup policy is defined to protect against the following
          situations:
        </p>
        <ul>
          <li></li>
          <li>
            Destruction of data media by force majeure, e.g. fire or water
          </li>
          <li>
            Deliberate and/or accidental deletion of files with computer-viruses
            etc.
          </li>
          <li>Inadvertent deletion or overwriting of files</li>
          <li>Technical failure of storage device (head crash)</li>
          <li>Faulty data media</li>
          <li>
            Demagnetization of magnetic data media due to ageing or unsuitable
            environmental conditions (temperature, air moisture)
          </li>
          <li>
            Interference of magnetic data media by extraneous magnetic fields
          </li>
          <li>Uncontrolled changes in stored data (loss of integrity)</li>
        </ul>
        <h2>AWS Back Up and Restore</h2>
        <p>
          Q One Tech hosts it’s software and data on Amazon S3 and uses the
          integrated RDS Backup system to back up their data. By default, Amazon
          RDS creates and saves automated backups of the DB instance securely in
          Amazon S3 for a user-specified retention period. In addition, daily
          snapshots are created, which are user-initiated backups of your
          instance that are kept until three weeks of the back up creation.
          After 3 weeks snapshots are transferred and stored to an encrypted
          back up server (“Long Term Back Up”) and removed from Amazon S3. A
          full backup restore on Amazon S3 is done within 2 hours.
        </p>
        <h2>Q One Tech Backup Policy</h2>
        <table width="100%" border="1">
          <tbody>
            <tr>
              <th scope="col">Type of data</th>
              <th scope="col">Backup policy</th>
            </tr>
            <tr>
              <td>System software</td>
              <td>Daily snapshots</td>
            </tr>
            <tr>
              <td>Application software</td>
              <td>Daily snapshots</td>
            </tr>
            <tr>
              <td>System data</td>
              <td>Daily snapshots</td>
            </tr>
            <tr>
              <td>Application data and Protocol data</td>
              <td>Daily snapshots</td>
            </tr>
          </tbody>
        </table>
        <ul>
          <li>
            System and application software - All software, whether purchased or
            developed for the Q One Platform, is protected by at least one full
            backup which includes all updates. All application data are
            protected by means of daily snapshots and a full back-up after 3
            weeks.
          </li>
          <li>
            System data - System data are backed up with at least one generation
            per month.
          </li>
          <li>
            Storage - All backup media is stored in a safe and secure location
            extraneous to the location of the backed up systems. All software
            full backup and monthly backup media is stored in an off-site backup
            archive storage location.
          </li>
        </ul>
      </div>
    ),
    options: [],
  },
  {
    title: "Development Privacy Procedure",
    lastDate: "20-04-2023",
    paragraph:
      " The Development Privacy Procedure is a set of guidelines and measures that ensure the protection of personal data during the development of our software applications, systems, and services.",
    path: "/legalsecurity/development-rivacy-rocedure",
    description: (
      <div>
        <h1>Development Privacy Procedure</h1>
        <h2>Purpose of this document</h2>
        <p>
          This document is addressed to Q One Techs customers and will be handed
          out on request. It explains the development process of the Q One Tech
          development team and describes how Privacy by Design is integrated in
          the Q One Tech development and the system architecture of products
          developed and maintained by Q One Tech.
        </p>
        <h2>Development Approach</h2>
        <p>
          The Q One Tech team develops and updates their products with privacy
          by design. The development process is Agile/Scrum in cycles of two
          weeks where new features, improvements and updates are designed,
          developed and tested first on a local server and then on a staging
          server. All updates are pen tested before they get released to the
          production server. The system code and the database of the production
          server are strictly separated from the development environment. Only
          after the pen test allows a release, an update will launched. If a
          potential security risk is detected during the pen test on the
          development server, the detected problem is evaluated and dealt with
          until a release can be done securely.
        </p>
        <p>
          Important role within the agile development methodology is the
          ‘Product Owner’, who is responsible for the product to be developed
          and determine the development priorities. In order to ensure that the
          Privacy by Design principles are followed, the Product Owner for Q One
          Tech developments and system maintenance work is the Q One Tech Data
          Protection Officer and CTO Roman Schönsee.{" "}
        </p>
        <h2>Privacy by Design principles</h2>
        <p>
          During the design phase of new features and system improvements, the
          development phase and the test period, the Q One Tech development team
          works by the privacy by design principles:{" "}
        </p>
        <h3>Preventive, not remedial</h3>
        <p>
          During the development process all (privacy and security related)
          scenarios are discussed with the team before designing/developing a
          solution. System (bug and penetration) test protocols initially focus
          on this principle.
        </p>
        <h3>Privacy as the default setting</h3>
        <p>
          The system is designed (as are updates) that personal and sensitive
          information is automatically protected. We strictly separate personal
          data from data collected in surveys and projects.{" "}
        </p>
        <h3>Avoid unnecessary trade offs (Full functionality)</h3>
        <p>
          We understand that the development of features according to the
          privacy by design principles can require longer development time.
          Trade-offs and short cuts at the expense of lack of privacy or
          security will not be made.
        </p>
        <h3>Privacy embedded into the software architecture</h3>
        <p>
          Privacy is not ‘bolted on’ to the Q One Platform but the system is
          architecture around it.
        </p>
        <h3>Strong security measures</h3>
        <p>
          The Q One development team has strict and strong security protocols in
          place. All data collected by the system is securely retained
          throughout its entire lifecycle, and then securely destroyed at the
          end of the process, in a timely fashion.
        </p>
        <h3>Visibility and transparency</h3>
        <p>
          The Q One Development team openly informs their clients about all
          system updates prior to each release and during the release. We
          maintain a public development backlog and a version history with all
          updates, changes and fixes to the system. The Q One Tech team involves
          their clients proactively in the design phase of new feature
          developments.
        </p>
        <h3>Respect for user privacy</h3>
        <p>
          The system is concepted in a way that our clients panel members have
          multiple, easy to access ways to leave our clients panels. Once opted
          out, clients can’t add panel members back into the system.
        </p>
        <h2>Integration of the Privacy by Design principles</h2>
        <p>
          The Q One Platform is Q One Techs main product. This paragraph
          explains how the privacy by design principles have been technically
          implemented and how we help our customers to integrate this principles
          into their day to day operations.
        </p>
        <h3>Right to be Informed</h3>
        <p>
          Panel members have access to their own personal dashboard where they
          can see their survey history, profile information and payment history.{" "}
          <br />
          In addition customers have the possibility to export personal data and
          historic information via the sample counter feature. Exports can be
          customized by our client (or the Q One Tech support team) to meet the
          needs of a potential GPDR query of a panel member.
          <br />
          In case of a detailed GDPR request to have complete and detailed
          insight in all personal data collected, the Q One Tech development
          support team can run an automated script directly on the database,
          which outputs all collected data throughout a panel members data
          lifecycle. This said, it is to our customers as data controllers to
          have GDPR protocols and procedures in place to do give the first line
          support to their panel members.{" "}
        </p>
        <h3>Right of Access</h3>
        <p>
          Panel members have access to their own personal dashboard where they
          can see their survey history, profile information and payment history.
          The dashboard also has a support form, which sends a support request
          (e.g. right of access the data history in case the information on the
          dashboard isn’t satisfactory) to the clients support address.
        </p>
        <h3>Right to Reification (Respondent answer History)</h3>
        <p>
          The member dashboard allows panel members to change answers to their
          profile survey. Important to know is, that the profile survey and
          information visible to panel members is controlled by the client. The
          profile survey on the dashboard needs to be set up from the panel
          management system. <br />
          Examples: Client wishes to track additional data in the background not
          relevant for a panel member, but important to the client. The panel
          member therefore won’t see the information and data in the profile and
          can’t manipulate it. But in the event of a GDPR query the client can
          export the information and inform the panel member accordingly. Some
          data doesn’t change (often): In the Q One Platform it is possible to
          lock answers.{" "}
        </p>
        <h3>Right to Erasure (Forgotten)</h3>
        <p>
          When panel members delete their profiles either from a survey invite
          or the dashboard or by request (and therefore a manager deletes a
          member through ‘Edit Members’, the member is deleted from the system
          but stays ‘hashed’ in the database. We don’t allow an instant hard
          delete from the database since financial information is captured and
          tax laws precedes GDPR. Deleted data however will be entirely removed
          from the database after 12 months.{" "}
        </p>
        <h3>Right to Restrict Processing</h3>
        <p>
          This is a procedure our clients as data controllers need to put in
          place. We recommend to add a question to the profile survey to cover
          this, which then can be accessed and changed by our customers panel
          members.
        </p>
        <h3>Right Data Portability</h3>
        <p>
          The right to data portability only applies in some specific situations
          and does not apply for systems like the Q One Platform. <br />
          According to Article 20 of the GDPR, data subjects have the right to
          request and receive an organized and machine-readable copy of the
          personal data a business holds about them when the data is processed
          based on either consent or a contract, and when that processing is
          carried out by automated means. If it's feasible, the data subject can
          request that you transfer this data to another company on their
          behalf. In our platform it would mean that panel members can easily
          migrate their data to your competitors.
        </p>
      </div>
    ),
    options: [],
  },
];
