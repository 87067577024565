import React, { useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';
import '../css/dashboard.css'
import '../css/defaults-and-settings.css'
import { CompanySettingsCard } from './CompanySettingsCard';
import { SettingsCard } from './SettingsCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import Layout from '../layouts/Defaults-Layout';
import { useDispatch, useSelector } from 'react-redux';
import { enterPage } from '../rootSlice';

export const SettingsAndDefaults = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const company = useSelector(state => state.curCompany)
    dispatch(enterPage(''))

    useEffect(() => {
        if(localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            navigate('/')
        }
    })

    return(
        <Layout>
            <div className='dashboard_navigation'>
                <div className='navigation-arrow-outside'>
                    <div className='navigation-arrow navigation-arrow-start navigation-arrow-parents'>
                        <FontAwesomeIcon className='navigation-arrow-icon' icon={faHouse} />
                    </div>
                </div>
                <div className='navigation-icon-after-outside'>
                    <div onClick={() => navigate('/company-settings/settings-and-defaults')} className='navigation-icon-after' style={{backgroundColor: '#ED8154', color: '#fff'}}>
                        Settings
                    </div>
                </div>
            </div>
            <div className='das-title'>
                Company Settings
            </div>
            <div className='settings-card-spaner'>
                <SettingsCard big1='4' big2='0' small1='Active Users' small2='Inactice Accounts' title='User management' destination='Edit' link='/user-management-dashboard' />
                <CompanySettingsCard title='Company Settings' destination='Edit Company Settings' link={'/company-settings/'+company} />
                <SettingsCard big1='Not signed' big2='' small1='Data Processing Agreement' small2='' title='Privacy Center' destination='Visit' link='/legal-and-security' spec={{order: 2, color: '#982727'}}/>
            </div>
            <div className='das-title mp-0'>
                System Settings
            </div>
            <div className='settings-card-spaner'>
                <SettingsCard big1='8' big2='English' small1='Languages Enabled' small2='Your Default Language' title='Languages' destination='Edit Languages & Translations' link='/languages-and-translations'/>
                <SettingsCard big1='8' big2='EST' small1='Timezones Enabled' small2='Your Default Timezone' title='Time Zones' destination='Edit Timezones' link='/tzn'/>
                <SettingsCard big1='' big2='English' small1='' small2='Your Default Language' title='Default Content' destination='Edit Default Content' link='default-content/' />
            </div>
        </Layout>
    )
}